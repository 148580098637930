import gsap from "../../../components/Animations";

function createAnimations(Checkout) {
  const { context } = Checkout;
  const target = Checkout.checkout;

  if (!target) return;

  const checkoutContainer = target.querySelector(".checkout-container");
  const navigationContainer = target.querySelector(".checkout-navigation");
  const videos = target.querySelectorAll(".video");

  const svgs = target.querySelectorAll("rect, path, line, circle");
  const images = target.querySelectorAll(".fade-image");

  gsap.set(svgs, { drawSVG: 0 });
  gsap.set(images, { opacity: 0 });

  audioPlayer(Checkout, true);

  const tl = gsap
    .timeline({
      delay: 1,
    })
    .add(() => {
      gsap.scrambleAnimation(navigationContainer);
      gsap.scrambleAnimation(checkoutContainer);
    });

  videos.forEach((video) => {
    tl.to(video, {
      onStart: () => {
        video.currentTime = 0;
        video.muted = true;
        if (!context.isDesktop) video.loop = true;
        video.play();
      },
    });
  });

  return tl;
}

function audioPlayer(Checkout, enable) {
  const target = Checkout.checkout;
  const audio = target?.querySelector(".sense-audio");

  if (!audio) return;

  if (enable) {
    audio.volume = 0;
    audio.play();

    gsap.to(audio, {
      duration: 1,
      volume: 1,
    });
  } else {
    gsap.to(audio, {
      duration: 1,
      volume: 0,
      onComplete: () => audio.pause(),
    });
  }
}

export { createAnimations, audioPlayer };

import React, { memo } from "react";
import { Wrapper } from "../styles/components/PDP/Global";
import PDPLanding from "./PDP-Landing";

const Pdp = memo(({ current, items, global, audioEnabled, toggleAudio }) => {
  const selected = items.find((item) => item.id === current);

  return (
    <Wrapper>
      {selected && (
        <PDPLanding
          {...selected}
          global={global}
          audioEnabled={audioEnabled}
          toggleAudio={toggleAudio}
        />
      )}
    </Wrapper>
  );
});

export default Pdp;

import gsap from "../../components/Animations";
import { BREAKPOINTS } from "../../styles/variables/Breakpoints";

export function mm() {
  const mm = gsap.matchMedia();

  mm.add(
    {
      // Desktop styles applied from md breakpoint upwards
      isDesktop: `(min-width: ${BREAKPOINTS.md}px)`,

      // Desktop and iPad styles applied from sm breakpoint upwards
      isDesktopAndIpad: `(min-width: ${BREAKPOINTS.sm}px)`,

      // iPad only, between sm and md breakpoints
      isIpad: `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${
        BREAKPOINTS.md - 1
      }px)`,

      // Mobile, but not the smallest size, between sm and xs breakpoints
      isMobileNotXS: `(min-width: ${BREAKPOINTS.xs + 1}px) and (max-width: ${
        BREAKPOINTS.sm - 1
      }px)`,

      // Mobile, anything below sm breakpoint
      isMobile: `(max-width: ${BREAKPOINTS.sm - 1}px)`,

      // Extra small mobile devices, below xs breakpoint
      isMobileXS: `(max-width: ${BREAKPOINTS.xs}px)`,
    },
    (context) => {
      let {
        isDesktop,
        isDesktopAndIpad,
        isIpad,
        isMobileNotXS,
        isMobile,
        isMobileXS,
      } = context.conditions;
    }
  );

  return mm;
}

import React from "react";
import { Hidden, TitleBorder } from "../styles/components/PDP/Global";
import {
  Container,
  ContainerOverlay,
  TitleContainer,
  Title,
  TitleHeader,
  SecondaryTitle,
  Paragraph,
  FooterContainer,
  Footer,
  ThirdContainer,
  HeadingContainer,
  SymbolContainer,
  SVGContainer,
  Column,
  ColorBlock,
  HeadingContain,
  TitleFooter,
  SmallHeading,
  SecondaryParagraph,
  PosterContainer,
  PosterImage,
  MobileBanner,
} from "../styles/components/PDP/Benefits";
import { COLOURS } from "../styles/variables/Colors";
import { PlusSvg, BenefitsBorder, BenefitsSeparator } from "./SVGs";

const PDPBenefits = (sense) => {
  const {
    title,
    banner,
    benefits,
    cart,
    secondaryCode,
    tertiaryCode,
    media,
  } = sense;
  const { symbol, code } = sense.global;

  return (
    <Container id="sense-benefits" $height="300vh" $blend>
      <PosterContainer>
        <PosterImage src={media.benefits.source} alt={title} />
      </PosterContainer>
      <ContainerOverlay>
        <TitleContainer className="pdp-benefits">
          <TitleBorder $hiddenxs>
            <TitleHeader>
              <HeadingContain
                $justify="flex-start"
                $align="flex-end"
                $direction="column"
                $hiddensm
              >
                <HeadingContainer>
                  <SVGContainer>
                    <PlusSvg />
                  </SVGContainer>
                  <HeadingContain>
                    <ColorBlock className="block" width="350" />
                    <SecondaryParagraph className="scramble">
                      <Hidden className="text">{banner}</Hidden>
                    </SecondaryParagraph>
                  </HeadingContain>
                </HeadingContainer>
              </HeadingContain>
            </TitleHeader>
            <HeadingContain $justify="flex-start">
              <Title className="scramble" $margin="right">
                <Hidden className="text">Reap the</Hidden>
              </Title>
              <HeadingContain $align="center" $direction="row">
                <SymbolContainer $align="center" $hiddensm>
                  <symbol.medium />
                </SymbolContainer>
                <SymbolContainer $align="center" $hiddensm>
                  <media.symbol size="medium" />
                </SymbolContainer>
              </HeadingContain>
            </HeadingContain>
            <HeadingContain $direction="column">
              <Title
                className="scramble"
                $justify="flex-start"
                $margin="left"
                $flex
              >
                <Hidden className="text">Benefits of our</Hidden>
              </Title>
              <Title className="scramble">
                <Hidden className="text">Empathic {title} chip</Hidden>
              </Title>
              <TitleFooter>
                <HeadingContain
                  $justify="flex-start"
                  $align="flex-end"
                  $direction="column"
                >
                  <SmallHeading className="scramble-delay" $hiddensm>
                    <Hidden className="text">{tertiaryCode}</Hidden>
                  </SmallHeading>
                  <SVGContainer $justify="flex-end" $align="flex-end" $hiddensm>
                    <BenefitsBorder />
                  </SVGContainer>
                </HeadingContain>
              </TitleFooter>
            </HeadingContain>
          </TitleBorder>
        </TitleContainer>
        <FooterContainer className="footer">
          <SecondaryParagraph className="scramble-delay" $absolute $mobile>
            <Hidden className="text">
              {tertiaryCode}
              <br></br>
              {secondaryCode}
              <br></br>
              {code}
            </Hidden>
          </SecondaryParagraph>
          <TitleBorder $align="center" $flex $full>
            <ColorBlock className="block" width="600" />
            <Footer>
              <ThirdContainer $justify="center" $direction="column">
                <HeadingContainer $mobileDirection>
                  <Column $align="flex-end" $flex>
                    <SecondaryTitle className="scramble" $hiddensm>
                      <Hidden className="text">01.</Hidden>
                    </SecondaryTitle>
                  </Column>
                  <Column>
                    <HeadingContain $direction="column">
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-end"
                        $flex
                      >
                        <Hidden className="text">{benefits[0].title}</Hidden>
                      </SecondaryTitle>
                      <SVGContainer>
                        <BenefitsSeparator />
                      </SVGContainer>
                    </HeadingContain>
                  </Column>
                </HeadingContainer>
                <Paragraph className="scramble" $hiddenxs>
                  <Hidden className="text">{benefits[0].text}</Hidden>
                </Paragraph>
              </ThirdContainer>
              <ThirdContainer $direction="column">
                <HeadingContainer>
                  <SecondaryTitle
                    className="scramble"
                    $justify="flex-end"
                    $flex
                    $hiddensm
                  >
                    <Hidden className="text">02.</Hidden>
                  </SecondaryTitle>
                  <SVGContainer $hiddenxs>
                    <BenefitsSeparator />
                  </SVGContainer>
                </HeadingContainer>
                <HeadingContainer>
                  <SecondaryTitle
                    className="scramble"
                    $justify="flex-end"
                    $margin="left"
                    $flex
                  >
                    <Hidden className="text">{benefits[1].title}</Hidden>
                  </SecondaryTitle>
                </HeadingContainer>
                <Paragraph className="scramble" $hiddenxs>
                  <Hidden className="text">{benefits[1].text}</Hidden>
                </Paragraph>
              </ThirdContainer>
              <ThirdContainer $justify="center" $direction="column">
                <HeadingContainer>
                  <Column $align="flex-end" $flex>
                    <SecondaryTitle className="scramble" $hiddensm>
                      <Hidden className="text">03.</Hidden>
                    </SecondaryTitle>
                  </Column>
                  <Column>
                    <HeadingContain $direction="column">
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-end"
                        $flex
                      >
                        <Hidden className="text">{benefits[2].title}</Hidden>
                      </SecondaryTitle>
                      <SVGContainer>
                        <BenefitsSeparator />
                      </SVGContainer>
                    </HeadingContain>
                  </Column>
                </HeadingContainer>
                <HeadingContain $hiddenxs>
                  <ColorBlock className="block" width="350" />
                  <Paragraph className="scramble">
                    <Hidden className="text">{benefits[2].text}</Hidden>
                  </Paragraph>
                </HeadingContain>
              </ThirdContainer>
              <MobileBanner>
                <SecondaryParagraph $setcolour={COLOURS.primary.aqua} $absolute>
                  <Hidden className="text">
                    {tertiaryCode}
                    <br></br>
                    {secondaryCode}
                    {secondaryCode}
                    <br></br>
                    {secondaryCode}
                  </Hidden>
                </SecondaryParagraph>
                <HeadingContain $mobileColumn>
                  <SecondaryTitle $setcolour={COLOURS.primary.white}>
                    <Hidden className="text">{cart}</Hidden>
                  </SecondaryTitle>
                </HeadingContain>
              </MobileBanner>
            </Footer>
          </TitleBorder>
        </FooterContainer>
      </ContainerOverlay>
    </Container>
  );
};

export default PDPBenefits;

import THREE from "../../Three";
import gsap from "../../../components/Animations";
import {
  updateChipProperties,
  chipGlobal,
  disposeTexturesAndGeometries,
  audioChecker,
  createAnimations,
  handleScroll,
} from "./Modules";

function createEnvironment(PLP) {
  window.scrollTo(0, 0);
  handleScroll(false);

  PLP.activeLoaderPlayed(false);
  PLP.wrapper.classList.toggle("active", true);

  const manager = new THREE.LoadingManager();
  const bar = document.getElementById("loading-bar");

  manager.onProgress = (url, loaded, total) => {
    let progress = (loaded / total) * 100;
    gsap.to(bar, {
      width: `${Math.trunc(progress)}%`,
    });
  };

  manager.onLoad = () => {
    setTimeout(audioChecker(PLP, false), 2);
  };

  const params = {};
  let dracoLoader;

  const loadTextures = (url) => {
    return new Promise((resolve) => {
      new THREE.TextureLoader(manager).load(url, resolve);
    });
  };

  const loadModel = (url, dracoDecoderPath) => {
    return new Promise((resolve) => {
      const loader = new THREE.GLTFLoader(manager);

      if (dracoDecoderPath) {
        dracoLoader = new THREE.DRACOLoader();
        dracoLoader.setDecoderPath(dracoDecoderPath);
        loader.setDRACOLoader(dracoLoader);
      }

      loader.load(url, resolve);
    });
  };

  const modelsToLoad = [
    {
      key: "sight",
      url: "models/SENSE_SIGHT.gltf",
      dracoDecoderPath: "models/draco/SENSE_SIGHT.drc",
    },
    {
      key: "smell",
      url: "models/SENSE_SMELL.gltf",
      dracoDecoderPath: "models/draco/SENSE_SMELL.drc",
    },
    {
      key: "taste",
      url: "models/SENSE_TASTE.gltf",
      dracoDecoderPath: "models/draco/SENSE_TASTE.drc",
    },
    {
      key: "sound",
      url: "models/SENSE_SOUND.gltf",
      dracoDecoderPath: "models/draco/SENSE_SOUND.drc",
    },
    {
      key: "feel",
      url: "models/SENSE_FEEL.gltf",
      dracoDecoderPath: "models/draco/SENSE_FEEL.drc",
    },
    {
      key: "global",
      url: "models/SENSE_GLOBAL.gltf",
      dracoDecoderPath: "models/draco/SENSE_GLOBAL.drc",
    },
    {
      key: "environment",
      url: "models/SENSE_plp_model.gltf",
      dracoDecoderPath: "models/draco/SENSE_plp_model.drc",
    },
  ];

  const loadModelsAndTextures = () => {
    const textures = {
      microchips: "materials/microchip-texture.png",
      floor: "materials/environment-floor.png",
      wallsMap: "materials/walls-map.png",
      wallsBumpMap: "materials/walls-bump.png",
    };

    const textureLoaderPromises = Object.keys(textures).map(async (key) => {
      return loadTextures(textures[key]).then((texture) => {
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;

        if (key === "floor") {
          texture.repeat.set(1, 2);
        } else if (key === "microchips") {
          texture.repeat.set(2, 2);
        } else {
          texture.repeat.set(6, 2);
        }
        texture.needsUpdate = true;
        params[key] = texture;
      });
    });

    const modelPromises = modelsToLoad.map(({ key, url, dracoDecoderPath }) => {
      return loadModel(url, dracoDecoderPath).then((result) => {
        sceneMap[key] = result.scene;
      });
    });

    return Promise.all([...textureLoaderPromises, ...modelPromises]);
  };

  const sceneMap = {};

  loadModelsAndTextures()
    .then(() => {
      PLP.group.add(
        sceneMap.sight.children[0],
        sceneMap.smell.children[0],
        sceneMap.taste.children[0],
        sceneMap.sound.children[0],
        sceneMap.feel.children[0]
      );

      updateChipProperties(PLP, params);
      finaliseEnvironment(PLP, sceneMap.environment.children[0], params);
      chipGlobal(sceneMap.global.children[0], PLP, params);

      if (dracoLoader) {
        dracoLoader.dispose();
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

function environmentMaterials(environment) {
  environment.traverse((child) => {
    if (child.isMesh) {
      switch (child.material.name) {
        case "Ceiling":
          child.material.color.setHex(0xffffff);
          break;
        default:
          child.material && disposeTexturesAndGeometries(child.material);
          break;
      }
    }
  });

  environment.scale.x = 1.5;
  environment.position.set(0, -100, 200);
}

function environmentWalls(textures) {
  const wallGroup = new THREE.Group();
  const geometry = new THREE.PlaneGeometry(1000, 400);
  const material = new THREE.MeshStandardMaterial({
    map: textures.wallsMap,
    bumpMap: textures.wallsBumpMap,
    bumpScale: 10,
    opacity: 0.35,
    transparent: true,
    side: THREE.DoubleSide,
    depthWrite: false,
  });

  const left = new THREE.Mesh(geometry, material);
  const right = new THREE.Mesh(geometry, material);

  left.rotateY(Math.PI / 2);
  right.rotateY(Math.PI / 2);

  left.position.set(-225, 100, -500);
  right.position.set(222, 100, -500);

  wallGroup.add(left, right);

  const disposeObjects = [geometry, material, left, right, wallGroup];
  disposeTexturesAndGeometries(disposeObjects);

  return wallGroup;
}

function environmentFloors(environment, textures) {
  const floorGroup = new THREE.Group();
  const geometry = new THREE.PlaneGeometry(500, 1000);
  const material = new THREE.MeshStandardMaterial({
    map: textures.floor,
    opacity: 0.6,
    transparent: true,
    side: THREE.DoubleSide,
    depthWrite: false,
  });

  const floor = new THREE.Mesh(geometry, material);
  const reflector = new THREE.Reflector(geometry, {
    clipBias: 0.003,
    textureWidth: window.innerWidth * window.devicePixelRatio,
    textureHeight: window.innerHeight * window.devicePixelRatio,
    color: 0xb5b5b5,
  });

  floor.rotateX(-Math.PI / 2);
  reflector.rotateX(-Math.PI / 2);

  reflector.position.set(0, environment.position.y, -500);
  floor.position.set(0, reflector.position.y + 1, -500);

  floorGroup.add(reflector, floor);

  const disposeObjects = [geometry, material, floor, reflector, floorGroup];
  disposeTexturesAndGeometries(disposeObjects);

  return floorGroup;
}

function finaliseEnvironment(PLP, environment, textures) {
  environmentMaterials(environment, textures);
  const walls = environmentWalls(textures);
  const floors = environmentFloors(environment, textures);

  const environmentGroup = new THREE.Group();
  environmentGroup.add(environment, walls, floors);
  environmentGroup.name = "Environment";

  PLP.scene.add(environmentGroup);
  PLP.onResize();

  createAnimations(PLP, false);

  // Added for testing
  // gsap.scrambleAnimation(PLP.plp);
  // PLP.loadAvaToScene(true);
  // PLP.loaded = true;
  // PLP.sectionActive = true;
  // const cart = document.querySelector(".cart-container");
  // gsap.set(cart, {
  //   xPercent: 100,
  // });
  // PLP.createPLPEventListeners();
  // PLP.Pages.sectionActive = true;
  // PLP.Pages.updatePageScene();
  // PLP.Checkout.sectionActive = true;
  // PLP.Checkout.updateCheckoutScene();
}

export {
  createEnvironment,
  environmentMaterials,
  environmentWalls,
  environmentFloors,
  finaliseEnvironment,
};

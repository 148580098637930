import gsap from "../../../components/Animations";
import {
  detectGlitchItem,
  addToCart,
  createAvaGlitch,
  triggerAvaMessage,
  handleScroll,
} from "./Modules";
import { Senses } from "../../../components/Sense-Objects";

function detectIfGlitch(PDP) {
  let existingProductIds = JSON.parse(localStorage.getItem("cartItems")) || [];
  let itemCount = existingProductIds.length;
  let productIdToAdd, number;
  let message = {};

  if (!existingProductIds.includes(6) || !existingProductIds.includes(7)) {
    PDP.activateGlitch = itemCount === 0 || itemCount === 1;
    productIdToAdd = detectGlitchItem(existingProductIds);
  } else {
    PDP.activateGlitch = false;
  }

  if (PDP.activateGlitch) {
    if (productIdToAdd === 6) {
      number = 1;
    } else if (productIdToAdd === 7) {
      number = 3;
    }

    message = {
      1: number,
      2: number + 1,
    };
  }

  return [PDP.activateGlitch, message, productIdToAdd];
}

function activateGlitchVideo(PDP, message) {
  const navigationContainer = document.querySelector(".pdp-navigation");
  const container = document.querySelector(".sense-overlay-video");
  const cart = document.querySelector(".cart-container");
  const video = container?.querySelector(".video");

  gsap.to(cart, {
    xPercent: 100,
  });

  video?.load();

  gsap.to({}, { duration: 1 }).then(() => {
    PDP.glitchPass.goWild = true;

    setTimeout(() => {
      PDP.renderer.domElement.classList.remove("glitch");
      gsap.fadeOutImages(navigationContainer, true);
      container.classList.add("active");
      PDP.element.classList.add("back");
      video && video.play();
      PDP.glitchPass.enabled = false;
      PDP.glitchPass.goWild = false;
      PDP.activateGlitch = false;
      PDP.experience = false;
      PDP.backgroundCreated = false;
      PDP.sectionActive = false;
    }, 2000);

    if (!video) return;
    video.onended = () => {
      glitchEnding(PDP, message);
    };
  });
}

function glitchEnding(PDP, message) {
  const { PLP, context } = PDP;
  const navigationContainer = document.querySelector(".pdp-navigation");
  const posterContainer = document.getElementById("sense-poster");
  const posterVideo = document.querySelector(".experience-video");
  const container = document.querySelector(".sense-overlay-video");
  const posterSvgs = posterContainer?.querySelectorAll(
    "rect, path, line, circle"
  );

  PDP.sectionActive = true;
  PDP.ctx.add(() => {
    gsap.drawSVG(posterSvgs);
    gsap.timeline({}).to(posterVideo, { opacity: 1, duration: 5 }, 0);
  });
  container.classList.remove("active");
  PDP.element.classList.remove("back");
  message.classList.toggle("glitch", false);
  createAvaGlitch(PLP, PDP, false);
  PDP.ctx.add(() => {
    gsap.fadeOutImages(navigationContainer, false);
  });
  PDP.createColourTransition(
    PDP.color.set(PDP.objects.color.x, PDP.objects.color.y, PDP.objects.color.z)
  );

  triggerAvaMessage(PLP, false, 5, false, true);
  if (!context.isMobile) handleScroll(true);

  PDP.ctx.add(() => {
    gsap.to({}, { duration: 7 }).then(() => {
      triggerAvaMessage(PLP, false, 6, true, true);
      addToCart(true, PDP.chip, PDP, PLP);
    });
  });
}

function senseExperience(PDP, PLP) {
  const posterContainer = document.getElementById("sense-poster");
  const posterVideo = posterContainer?.querySelector(".experience-video");
  const sensePosterContainer = posterContainer?.querySelector(
    "#sense-poster-container"
  );
  const posterSvgs = posterContainer?.querySelectorAll(
    "rect, path, line, circle"
  );

  const [activateGlitch, glitchMessage, productId] = detectIfGlitch(PDP);

  if (activateGlitch) {
    if (!PDP.context.isMobile) handleScroll(false);

    gsap.to(window, { duration: 0.5, scrollTo: sensePosterContainer });
    let chatTween = triggerAvaMessage(PLP, true, glitchMessage[1], false, true);

    chatTween.to({}, { duration: 5 }).add(() => {
      gsap.undrawSVG(posterSvgs);

      const current = Senses.items.find((item) => item.id === productId);

      triggerAvaMessage(PLP, false, glitchMessage[2], false, true, 2);

      PDP.createColourTransition(
        PDP.color.set(current.color.x, current.color.y, current.color.z)
      );

      createAvaGlitch(PLP, PDP, true, productId);

      gsap.to(posterVideo, {
        opacity: 0.4,
        duration: 2,
      });
    });
  } else {
    PDP.triggerExperience();
  }
}

export { detectIfGlitch, activateGlitchVideo, glitchEnding, senseExperience };

import gsap from "../../../components/Animations";
import { disposeTexturesAndGeometries } from "./Modules";

function triggerAvaMessage(
  PLP,
  animate,
  state = null,
  addToCart = false,
  glitch = false,
  delay = 1
) {
  PLP.activateAva = true;

  const { context } = PLP;
  let chatElement, message;
  let currSection = document.querySelector(".section.active");

  chatElement = PLP.context.isDesktop
    ? PLP.chatElement
    : currSection?.querySelector(".chat");

  message = chatElement?.querySelector(".message");

  message?.classList.toggle("glitch", glitch && state < 5);

  PLP.chatAnimationTl.data = {
    state: state,
  };

  gsap.chatAnimation(
    PLP.chatAnimationTl,
    chatElement,
    addToCart,
    PLP,
    state,
    glitch,
    delay
  );

  let avaScale = gsap.utils.wrapYoyo(6.5, 7);

  let tl = gsap.timeline({
    onUpdate: () => {
      !animate && tl.pause();
    },
  });

  if (animate && !context.isMobile) {
    tl.to(
      PLP.plane.scale,
      {
        x: "+=0.2",
        y: "+=0.2",
        duration: 0.45,
        modifiers: {
          x: (x) => {
            return avaScale(parseFloat(x));
          },
          y: (y) => {
            return avaScale(parseFloat(y));
          },
          z: (z) => {
            return avaScale(parseFloat(z));
          },
        },
      },
      0
    );
  }

  PLP.avaTl.add(tl);

  return PLP.avaChatTl;
}

function endAvaGlitch(PLP, PDP) {
  let tl;
  let speed = 1;
  let duration = 2;

  const { x, y, z } = PLP.plane.userData.position;

  PDP.ctx.add(() => {
    tl = gsap.timeline({
      defaults: {
        delay: 1,
        onUpdate: () => (PLP.speed.value = speed),
      },
    });

    tl.to(PLP.plane.position, {
      x: x,
      y: y,
      z: z,
      duration: duration,
    })
      .to(
        PLP.avaMaterial.uniforms.u_amplitude,
        {
          value: PLP.amplitude,
          duration: duration,
        },
        0
      )
      .to(
        PLP.avaMaterial.uniforms.u_frequency,
        {
          value: PLP.frequency,
          duration: duration,
        },
        0
      )
      .to(
        PLP.avaMaterial.uniforms.u_maxDistance,
        {
          value: PLP.maxDistance,
          duration: duration,
        },
        0
      );
  });
}

function sufferAvaGlitch(PLP, PDP) {
  let tl;
  let speed = 0.005;

  PDP.ctx.add(() => {
    tl = gsap.timeline({
      defaults: {
        delay: 0,
        onUpdate: () => (PLP.speed.value += speed),
      },
    });

    tl.to(PLP.plane.position, {
      x: 21,
      y: 0,
      z: 0,
      duration: 5,
    })
      .add(() => {
        PDP.renderer.domElement.classList.add("glitch");
        PDP.experience = true;
        PDP.glitchPass.enabled = true;
      })
      .to(
        PLP.avaMaterial.uniforms.u_frequency,
        {
          value: 2,
          duration: 5,
        },
        0
      )
      .to(
        PLP.avaMaterial.uniforms.u_amplitude,
        {
          value: 2.5,
          duration: 5,
        },
        0
      )
      .to(
        PLP.avaMaterial.uniforms.u_maxDistance,
        {
          value: 2,
          duration: 3,
        },
        0
      )
      .to(
        PLP.plane.position,
        {
          x: 0,
          z: 35,
          duration: 2,
        },
        5
      )
      .add(() => PDP.triggerExperience(), 8)
      .then(() => tl.kill());
  });
}

function disassociateAvaGlitch(PLP, PDP) {
  let tl;
  let speed = 0.008;

  PDP.ctx.add(() => {
    tl = gsap.timeline({
      defaults: {
        delay: 0,
        onUpdate: () => (PLP.speed.value += speed),
      },
    });

    tl.to(
      PLP.plane.position,
      {
        x: 0,
        y: 0,
        z: 0,
        duration: 2,
      },
      0
    )
      .to(
        PLP.avaMaterial.uniforms.u_maxDistance,
        {
          value: 2,
          duration: 0.5,
        },
        0
      )
      .to(
        PLP.avaMaterial.uniforms.u_amplitude,
        {
          value: 2.4,
          duration: 2,
        },
        0
      )
      .add(() => {
        PDP.renderer.domElement.classList.add("glitch");
        PDP.experience = true;
        PDP.glitchPass.enabled = true;
      })
      .to(
        PLP.avaMaterial.uniforms.u_frequency,
        {
          value: 2.6,
          duration: 2,
        },
        2
      )
      .to(
        PLP.plane.position,
        {
          z: 50,
          duration: 2,
        },
        3
      )
      .add(() => PDP.triggerExperience(), 6)
      .then(() => tl.kill());
  });
}

function createAvaGlitch(PLP, PDP, active, productId) {
  if (active && productId === 6) {
    sufferAvaGlitch(PLP, PDP);
  } else if (active) {
    disassociateAvaGlitch(PLP, PDP);
  } else {
    endAvaGlitch(PLP, PDP);
  }
}

function revertAvaTimelines(PLP) {
  const resetElements = document.querySelectorAll(".reset");
  const messageElements = document.querySelectorAll(".message");

  PLP.chatAnimationTl.isActive() && PLP.chatAnimationTl.clear();
  PLP.avaCtx.revert();

  PLP.avaTl.revert();
  PLP.avaTl.clear();

  PLP.chatAnimationTl.revert();
  PLP.chatAnimationTl.clear();

  messageElements.forEach((element) => {
    gsap.set(element, { clearProps: true, opacity: 0 });
  });

  resetElements.forEach((element) => {
    element.classList.toggle("active", false);
    gsap.set(element, { clearProps: true, opacity: 0 });
  });

  disposeTexturesAndGeometries(PLP.avaMaterial);
}

export { triggerAvaMessage, createAvaGlitch, revertAvaTimelines };

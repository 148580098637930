import gsap from "../../../components/Animations";
import { mm } from "./Modules";
import { BREAKPOINTS } from "../../../styles/variables/Breakpoints";

function applyAnimation({
  tl,
  chip,
  xPosition,
  yPosition,
  zPosition,
  positionPosition,
  xRotation,
  yRotation,
  zRotation,
  rotationPosition,
  xScale,
  yScale,
  zScale,
  scalePosition,
  duration = 2,
}) {
  const filterProps = (props) =>
    Object.fromEntries(
      Object.entries(props).filter(([key, value]) => value !== undefined)
    );

  if (
    xPosition !== undefined ||
    yPosition !== undefined ||
    zPosition !== undefined
  ) {
    tl.to(
      chip.position,
      filterProps({ x: xPosition, y: yPosition, z: zPosition, duration }),
      positionPosition
    );
  }
  if (
    xRotation !== undefined ||
    yRotation !== undefined ||
    zRotation !== undefined
  ) {
    tl.to(
      chip.rotation,
      filterProps({ x: xRotation, y: yRotation, z: zRotation, duration }),
      rotationPosition
    );
  }
  if (xScale !== undefined || yScale !== undefined || zScale !== undefined) {
    tl.to(
      chip.scale,
      filterProps({ x: xScale, y: yScale, z: zScale, duration }),
      scalePosition
    );
  }
}

function senseIpadSettings(benefitsFooter, techFooter, posterVideo) {
  mm().add(
    `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
    () => {
      gsap.set(benefitsFooter, {
        xPercent: 100,
      });
      gsap.set(techFooter, {
        xPercent: 100,
      });
      gsap.set(posterVideo, { opacity: 0 });
    }
  );
}

function senseDesktopSettings(videoTrigger, posterVideo) {
  mm().add(`(min-width: ${BREAKPOINTS.sm}px)`, () => {
    gsap.set(videoTrigger, { scale: 0, opacity: 0 });
    gsap.set(posterVideo, { opacity: 0 });
  });
}

function senseLandingAnimation(tl, chip) {
  const mediaMatcher = mm();

  mediaMatcher.add(`(min-width: ${BREAKPOINTS.md}px)`, () => {
    applyAnimation({
      tl,
      chip,
      xPosition: -10,
      yPosition: 2,
      xRotation: 0.3,
      yRotation: -0.9,
      zRotation: -Math.PI / 3,
    });
  });

  mediaMatcher.add(
    `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
    () => {
      applyAnimation({
        tl,
        chip,
        xPosition: -5.5,
        yPosition: 2,
        xRotation: 0.5,
        yRotation: -Math.PI / 3,
        zRotation: -1,
      });
    }
  );

  mediaMatcher.add(
    `(min-width: ${BREAKPOINTS.xs + 1}px) and (max-width: ${
      BREAKPOINTS.sm - 1
    }px)`,
    () => {
      applyAnimation({
        tl,
        chip,
        xPosition: 0,
        yPosition: -10,
        zPosition: 15,
        xRotation: 0.7,
        yRotation: -Math.PI / 2,
        zRotation: -1,
      });
    }
  );

  mediaMatcher.add(`(max-width: ${BREAKPOINTS.xs}px)`, () => {
    applyAnimation({
      tl,
      chip,
      xPosition: 0,
      yPosition: -10,
      zPosition: 25,
      xRotation: 0.7,
      yRotation: -Math.PI / 2,
      zRotation: -1,
    });
  });
}

function senseVideoAnimation(tl, chip) {
  const mediaMatcher = mm();

  mediaMatcher.add(
    `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
    () => {
      applyAnimation({
        tl,
        chip,
        xPosition: -5.5,
        yPosition: 2,
        positionPosition: 0,
        xRotation: 0.5,
        yRotation: -Math.PI / 3,
        zRotation: -1,
        rotationPosition: 0,
      });
    }
  );

  mediaMatcher.add(
    `(min-width: ${BREAKPOINTS.xs + 1}px) and (max-width: ${
      BREAKPOINTS.sm - 1
    }px)`,
    () => {
      applyAnimation({
        tl,
        chip,
        yPosition: -10,
      });
    }
  );
  mediaMatcher.add(`(max-width: ${BREAKPOINTS.xs}px)`, () => {
    applyAnimation({
      tl,
      chip,
      yPosition: 15,
    });
  });
}

function senseDescriptionAnimation(tl, chip) {
  const mediaMatcher = mm();

  mediaMatcher.add(
    `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
    () => {
      applyAnimation({
        tl,
        chip,
        xPosition: 50,
        positionPosition: 0,
        xRotation: Math.PI / 2,
        yRotation: -Math.PI,
        rotationPosition: 0,
        duration: 2.5,
      });
    }
  );

  mediaMatcher.add(`(max-width: ${BREAKPOINTS.sm - 1}px)`, () => {
    applyAnimation({
      tl,
      chip,
      xPosition: 50,
      positionPosition: 0,
      xRotation: Math.PI / 2,
      yRotation: -Math.PI,
      rotationPosition: 0,
      duration: 2.5,
    });
  });
}

function senseBenefitAnimation(tl, chip) {
  mm().add(`(min-width: ${BREAKPOINTS.md}px)`, () => {
    applyAnimation({
      tl,
      chip,
      xPosition: 12,
      yPosition: 1.5,
      positionPosition: 0,
      xRotation: 5,
      yRotation: 10,
      zRotation: -Math.PI,
      rotationPosition: 0,
    });
  });
}

function senseTechAnimation(tl, chip) {
  mm().add(`(min-width: ${BREAKPOINTS.md}px)`, () => {
    applyAnimation({
      tl,
      chip,
      yPosition: -1,
      zPosition: 30,
      positionPosition: 1,
      xRotation: Math.PI * 2,
      yRotation: 4.5,
      zRotation: -Math.PI / 2.1,
      rotationPosition: 0,
      xScale: 40,
      yScale: 40,
      zScale: 40,
      scalePosition: 0,
    });
  });
}

function senseBenefitsFooterMobile(benefitsFooter) {
  mm().add(
    `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
    () => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: benefitsFooter,
            start: "top center",
            end: "bottom center",
            scrub: 3,
            invalidateOnRefresh: true,
          },
        })
        .add(() => gsap.scrambleAnimation(benefitsFooter))
        .to(benefitsFooter, {
          delay: 2,
          xPercent: 0,
          duration: 3,
          ease: "power1.out",
        });
    }
  );
}

function senseTechFooterMobile(techFooter) {
  mm().add(
    `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
    () => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: techFooter,
            start: "top center",
            end: "bottom center",
            scrub: 3,
            invalidateOnRefresh: true,
          },
        })
        .add(() => gsap.scrambleAnimation(techFooter))
        .to(techFooter, {
          delay: 2,
          xPercent: 0,
          duration: 3,
          ease: "power1.out",
        });
    }
  );
}

function sensePosterPosition(tl, posterTrigger, posterWrapper) {
  let imageWidth = posterTrigger.clientWidth;
  let containerWidth = posterWrapper.clientWidth;
  let posterX = (containerWidth - imageWidth) / 2;
  posterX = posterX - 35;

  const mediaMatcher = mm();

  mediaMatcher.add(`(min-width: ${BREAKPOINTS.md}px)`, () => {
    tl.to(posterTrigger, {
      delay: 0.5,
      x: -posterX,
    });
  });
}

function sensePosterVideo(tl, posterVideo, experiencePoster) {
  const mediaMatcher = mm();

  mediaMatcher.add(`(min-width: ${BREAKPOINTS.md}px)`, () => {
    tl.to(
      posterVideo,
      {
        delay: 0.5,
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      },
      1
    ).to(
      experiencePoster,
      {
        delay: 0.5,
        opacity: 0,
        duration: 0.5,
        ease: "power2.in",
      },
      1
    );
  });

  mediaMatcher.add(
    `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
    () => {
      tl.to(
        posterVideo,
        {
          delay: 0.5,
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        1
      ).to(
        experiencePoster,
        {
          delay: 0.5,
          opacity: 0,
          duration: 0.5,
          ease: "power2.in",
        },
        1
      );
    }
  );
}

export {
  applyAnimation,
  senseIpadSettings,
  senseDesktopSettings,
  senseLandingAnimation,
  senseVideoAnimation,
  senseDescriptionAnimation,
  senseBenefitAnimation,
  senseTechAnimation,
  senseBenefitsFooterMobile,
  senseTechFooterMobile,
  sensePosterPosition,
  sensePosterVideo,
};

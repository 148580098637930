import THREE from "./Three";
import PLP from "./scenes/PLP/PLP";
import PDP from "./scenes/PDP/PDP";
import Pages from "./scenes/Pages/Pages";
import Checkout from "./scenes/Checkout/Checkout";
import { onClick } from "./scenes/ActiveScene";
import WebGL from "three/examples/jsm/capabilities/WebGL";

const Init = (
  activeSense,
  activeAva,
  activeCart,
  activeSection,
  activeLoaderPlayed
) => {
  const canvas = document.getElementById("app");
  const loader = document.getElementById("loader");
  const progress = document.getElementById("progress");
  const videoWrapper = document.querySelector(".loader-video");
  const scenes = [];

  const [plpSection, pdpSection, pagesSection, checkoutSection] = [
    document.getElementById("section-plp"),
    document.getElementById("section-pdp"),
    document.getElementById("section-pages"),
    document.getElementById("section-checkout"),
  ];

  const [plpScene, pdpScene, pagesScene, checkoutScene] = [
    new THREE.Scene(),
    new THREE.Scene(),
    new THREE.Scene(),
    new THREE.Scene(),
  ];

  const setScenes = (currScene, currSection) => {
    const sceneElement = currSection.querySelector(".scene");
    currScene.userData.element = sceneElement;

    const camera = new THREE.PerspectiveCamera(
      75,
      canvas.clientWidth / canvas.clientHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 50);
    currScene.userData.camera = camera;

    currScene.position.set(0, 0, 0);
    scenes.push(currScene);
  };

  const setRenderer = () => {
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      transparent: true,
    });

    renderer.setClearColor(0xffffff, 1);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
    renderer.shadowMap.enabled = false;

    return renderer;
  };

  const setSubjects = () => {
    const plpClass = new PLP(
      plpScene,
      activeSense,
      activeAva,
      activeCart,
      activeSection,
      activeLoaderPlayed,
      renderer
    );
    const pdpClass = new PDP(
      pdpScene,
      activeSense,
      activeAva,
      activeCart,
      renderer
    );

    const pagesClass = new Pages(pagesScene, activeSense, renderer);
    const checkoutClass = new Checkout(checkoutScene, activeSense, renderer);

    plpClass.setClasses(pdpClass, pagesClass, checkoutClass);
    pdpClass.setClasses(plpClass, pagesClass, checkoutClass);
    pagesClass.setClasses(pdpClass, plpClass, checkoutClass);
    checkoutClass.setClasses(plpClass, pdpClass, pagesClass);

    const subjects = [plpClass, pdpClass, pagesClass, checkoutClass];
    return subjects;
  };

  setScenes(plpScene, plpSection);
  setScenes(pdpScene, pdpSection);
  setScenes(pagesScene, pagesSection);
  setScenes(checkoutScene, checkoutSection);

  const renderer = setRenderer();
  const subjects = setSubjects();

  const animate = () => {
    requestAnimationFrame(animate);
    render();
  };

  const render = () => {
    renderer.setClearColor(0xffffff);
    renderer.setScissorTest(false);
    renderer.clear();

    renderer.setClearColor(0xe0e0e0);
    renderer.setScissorTest(true);

    scenes.forEach((scene) => {
      const { camera, constructor } = scene.userData;

      if (constructor.sectionActive === false) return;

      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();

      renderer.setViewport(0, 0, canvas.clientWidth, canvas.clientHeight);
      renderer.setScissor(0, 0, canvas.clientWidth, canvas.clientHeight);

      constructor.render();

      if (constructor.triggerComposer) {
        constructor.triggerComposer();
      } else {
        renderer.render(scene, camera);
      }
    });
  };

  if (WebGL.isWebGLAvailable()) {
    animate();
  } else {
    const warning = WebGL.getWebGLErrorMessage();
    progress.parentNode.removeChild(progress);
    videoWrapper.parentNode.removeChild(videoWrapper);
    loader.appendChild(warning);
  }

  window.addEventListener("resize", () => {
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
  });

  window.addEventListener("click", (event) => {
    onClick(event, subjects, activeSection);
  });
};

export default Init;

import THREE from "../../Three";

export function updateRatios(scene) {
  const { context, camera, fov, ratio } = scene;
  const calcRatio = context.isMobile ? ratio : 16 / 9;
  const currHeight = Math.tan(THREE.MathUtils.degToRad(fov / 2));

  if (context.isMobile || camera.aspect > calcRatio) {
    const newRatio = camera.aspect / calcRatio;
    const newHeight = currHeight / newRatio;
    camera.fov = THREE.MathUtils.radToDeg(Math.atan(newHeight)) * 2;
  } else {
    camera.fov = fov;
  }
}

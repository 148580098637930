import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import {
  BREAKPOINTS,
  IPADBREAKPOINTS,
  generateMediaQueries,
  hiddenxl,
  hiddensm,
  hiddenxs,
  hiddenxxs,
  desktop,
  ipad,
  mobile,
} from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Wrapper = styled.div`
  ${setPosition({ position: "relative" })};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify || "center",
      align: props.$align || "center",
    })};
  ${setPosition({ position: "relative" })};
  min-height: ${(props) => props.$minHeight || "200vh"};
  ${(props) =>
    !props.$minHeight &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      min-height: unset;
    }
  `};
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    min-height: ${(props) => props.$minHeight || "125vh"};
  }
  height: auto;
  width: 100%;
  max-width: 100vw;
  ${(props) => props.$blend && "mix-blend-mode: difference"};
  ${(props) => props.$index && `z-index: ${props.$index}`};
  ${(props) =>
    props.$mobileUnblend &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      mix-blend-mode: unset;
    }
  `};
`;

export const IntroContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ justify: "flex-end", align: "center" })};
  min-height: 100vh;
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ align: "flex-start" })};
    margin-top: 2rem;
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

export const TitleContainer = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  text-align: ${(props) => props.$text || "left"};
  max-width: 100vw;
  ${(props) =>
    props.$padding &&
    `
    padding: 1rem;
    margin-top: 3rem;
    @media (max-width: ${BREAKPOINTS.sm}px) {
        margin-top: 0;
        padding: 0 1rem;
      }
  `};
  ${(props) => props.$absolute && setPosition({ position: "absolute" })};
`;

export const LandingWrapper = styled.div`
  ${setFlex({})};
  ${setPosition({ position: "absolute" })};
  width: 50%;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 60%;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${setFlex({ justify: "flex-end", align: "flex-end" })};
    width: 70%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    justify-content: unset;
    width: 100%;
    max-width: 100vw;
    margin-top: 5rem;
  }
`;

export const TitleBorder = styled.div`
  max-width: 100vw;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 1rem 1rem 0 1rem;
  }
  ${(props) =>
    props.$align &&
    `
      width: 50%;
      @media (max-width: ${BREAKPOINTS.sm}px) {
        width: 100%;
    }
  `};
  ${(props) => props.$margin && `margin: 0 1.5rem`};
  ${(props) =>
    props.$splitWidth &&
    `
    min-width: 500px;
    max-width: 50vw;
    margin: 1rem;
    @media (max-width: ${BREAKPOINTS.md}px) {
      max-width: unset;
      margin:  4rem 1rem 0 0;
    }
    @media (max-width: ${BREAKPOINTS.xs}px) {
      margin: 0 1rem 0 0;
    }
  `};
`;

export const VisionFooter = styled.div`
  ${setFlex({ justify: "flex-end" })};
  text-align: left;
  max-width: 90vw;
  margin-top: 1rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "center" })};
    padding: 0;
    margin-top: 1rem;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ justify: "center" })};
  }
`;

export const DisplayTitle = styled.div`
  ${setFlex({ direction: "row" })};
  h1 {
    margin: 0 0.6rem 0 2.5rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "row-reverse" })};
    margin: 0;
    h1 {
      ${generateFont("3.5rem", false, "xs")};
      ${generateLineHeight("3.5rem", false, "xs")};
      margin-left: 1.5rem;
    }
  }
`;

export const Title = styled.h1`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  text-transform: ${(props) => (props.$noTransform ? "none" : "uppercase")};
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  max-width: 100vw;
  ${generateFont("3.3rem", false)};
  ${generateLineHeight("3rem", false)};
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${generateFont("1.8rem", false, "xs")};
    ${generateLineHeight("1.6rem", false, "xs")};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${generateFont("3.5rem", false, "sm")};
    ${generateLineHeight("3rem", false, "sm")};
  }
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    ${generateFont("3.4rem", false, "xxxl")};
    ${generateLineHeight("3.1rem", false, "xxxl")};
  }
  margin: 0;
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) => props.$margin && `margin-${props.$margin}: 2.5rem`};
  ${(props) =>
    props.$noWrap &&
    `
    white-space: nowrap;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      white-space: unset;
    }
  `};
`;

export const SecondaryTitle = styled(Title)`
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  ${generateFont("2.6rem")};
  ${generateLineHeight("2.3rem")};
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) =>
    props.$margin &&
    `
      margin-${props.$margin}: 1.8rem;
      @media (max-width: ${BREAKPOINTS.sm}px) {
        margin-${props.$margin}: 0.8rem;
    }
  `};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) =>
    props.$alignCenter &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      text-align: center;
    }
  `};
  ${(props) =>
    props.$noWrap &&
    `
    white-space: nowrap;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      white-space: unset;
    }
  `};
`;

export const SmallHeading = styled(Headline)`
  color: ${COLOURS.primary.white};
  ${(props) => props.$width && `max-width: ${props.$width}px`};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) =>
    props.$absolute &&
    setPosition({
      position: "absolute",
      left: props.$left,
      right: props.$right,
    })};
  ${(props) => props.$blend && "mix-blend-mode: difference"};
  ${(props) => props.$break && "word-break: break-all"};
  word-wrap: break-word;
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxs && hiddenxs};
  ${(props) => props.$mobile && mobile};
`;

export const HeadingContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${(props) => props.$height && "height: fit-content"};
  ${(props) =>
    props.$maxHeight &&
    `
    max-height: 60px;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      max-height: unset;
    }
    @media (min-width: ${BREAKPOINTS.xxl}px) {
      max-height: unset;
    }
  `};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
  ${(props) =>
    props.$mobileMaxWidth &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      max-width: 90vw;
    }
  `};
  ${(props) => props.$hiddenxs && hiddenxs};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxl && hiddenxl};
`;

export const SenseHeadingContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ justify: "space-between", direction: "row" })}
  margin-top: 0.6rem;
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 1rem;
  }
`;

export const MaskContainer = styled.div`
  ${setFlex({})};
  ${setPosition({ position: "relative" })};
`;

export const Mask = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: -1 })};
  background-color: ${COLOURS.primary.white};
  height: 100%;
  width: 0%;
`;

export const HeadingContain = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  height: ${(props) => props.$height || "auto"};
  ${(props) => props.$grow && "flex-grow: 1"};
  ${(props) => props.$absolute && setPosition({ position: "absolute" })};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
  ${(props) =>
    props.$growSvg &&
    `
  > svg {
    height: 100%;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  `};
  ${setPosition({ position: "relative" })};
  > svg {
    margin-left: 0.6rem;
  }
  max-width: 100vw;
  ${(props) =>
    props.$mobileMargin &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      margin: 0;
    }
  `};
  ${(props) =>
    props.$mobilePadding &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      padding: 0 1rem;
    }
  `};
  ${(props) => props.$padding && "padding: 1rem"};
  ${(props) =>
    props.$fullWidth &&
    `
    width: 100%;
    > div, svg {
      width: 90%;
    }
  `};
  ${(props) =>
    props.$click &&
    `
    cursor: pointer;
  `};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxl && hiddenxl};
  ${(props) => props.$mobile && mobile};
`;

export const FooterWrapper = styled.div`
  ${setFlex({ align: "flex-end", direction: "column" })};
  height: auto;
  max-width: 100vw;

  @media (max-width: ${BREAKPOINTS.sm}px) {
    align-items: flex-start;
  }
`;

export const SVGContainer = styled.div`
  ${(props) => setFlex({ justify: props.$justify, align: props.$align })};
  ${(props) =>
    props.$absolute &&
    `
  ${setPosition({
    position: "absolute",
    right: props.$right,
    bottom: props.$bottom,
    top: props.$top,
    zIndex: -1,
  })};
  height: 100%;
  width: 100%;
  > svg {
      height: 95%;
      width: 85%;
  }
  `};
  ${(props) => props.$padding && `padding-${props.$padding}: 0.6rem`};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxl && hiddenxl};
`;

export const DescriptionTitle = styled.div`
  ${setPosition({ position: "relative" })};
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  max-width: 100vw;
  ${(props) =>
    props.$padding &&
    `
      padding: 0 1.25rem 1.25rem 1.25rem;
      @media (max-width: ${BREAKPOINTS.sm}px) {
      padding: 0;
    }
  `};
  ${(props) =>
    props.$margin &&
    `
    margin-${props.$margin}: 0.9rem
    @media (max-width: ${BREAKPOINTS.sm}px) {
      margin-right: 0;
    }
  `};
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    margin: 1rem;
  }
  ${(props) => props.$ipad && ipad};
`;

export const PosterContainer = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${setPosition({ position: "relative" })};
  height: auto;
  max-height: 100vh;
  margin: 0 2.5rem;
  color: ${COLOURS.primary.white};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0;
  }
`;

export const ParallaxContainer = styled.div`
  ${setFlex({ justify: "center", direction: "row" })}
  ${setPosition({ position: "relative" })};
  height: auto;
  max-height: 100vh;
  margin: 2.5rem;
  color: ${COLOURS.primary.white};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
    max-height: unset;
    margin-bottom: 3.5rem;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin: 1rem 1rem 1.5rem 1rem;
  }
  @media (max-width: ${BREAKPOINTS.xxxs}px) {
    margin: 0 1rem;
  }
`;

export const Poster = styled.div`
  ${setFlex({ justify: "center" })};
  ${(props) =>
    props.$padding &&
    `
    padding: 2rem;
    @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0;
  }
  `};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: 1rem;
  }
  width: fit-content;
  height: fit-content;
`;

export const PosterImage = styled.img`
  height: 100%;
  max-height: 75vh;
  ${(props) =>
    !props.$fullHeight &&
    `
    @media (max-width: ${BREAKPOINTS.xl}px) {
      max-height: 65vh;
    }
  `};
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    max-height: 65vh;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: auto;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    height: 60vh;
    width: auto;
    object-fit: cover;
  }
  ${(props) => props.$desktop && desktop};
  ${(props) => props.$mobile && mobile};
`;

export const Column = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${setPosition({ position: "relative" })};
  ${(props) => props.$grow && "flex-grow: 1"};
  ${(props) =>
    props.$margin &&
    `
    margin: 0 0.9rem;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      margin: 0;
    }
  `};
  ${(props) =>
    props.$absolute &&
    `
  ${setPosition({
    position: "absolute",
    right: props.$right,
    top: props.$top,
  })}
  `};
  ${(props) =>
    props.$extra &&
    `
  &:nth-child(2) {
    margin-top: 2rem;
    @media (max-width: ${BREAKPOINTS.xs}px) {
      margin-top: 1.5rem;
    }
  }
  `};
  ${(props) =>
    props.$mobileColumn &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      ${setFlex({ direction: "column" })};
    }
  `};
`;

export const FloatingColumn = styled(Column)`
  ${setPosition({ position: "absolute", top: 1, left: 100 })};
  width: 100%;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    left: 0;
    ${setPosition({ position: "relative" })};
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    align-items: flex-start;
    margin-left: 1.5rem;
  }
`;

export const FloatingWrapper = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "relative" })};
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
  }
`;

export const Link = styled.a`
  ${setFlex({})};
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const QRCode = styled.img`
  width: 60px;
  height: 60px;
  max-width: unset;
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    width: -webkit-fill-available;
    width: -moz-available;
    max-height: 80px;
    height: 100%;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const TitleFooter = styled.div`
  ${(props) => props.$flex && setFlex({})};
  ${(props) => props.$padding && "padding: 0.9rem"};
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: auto;
  }
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxxs && hiddenxxs};
`;

export const VerticalText = styled.div`
  ${setFlex({ align: "center" })};
  ${setPosition({ position: "relative" })};
  padding-right: 0.9rem;
  writing-mode: vertical-lr;
  text-orientation: mixed;
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  background-color: ${COLOURS.primary.blue};
  min-height: 25px;
  margin-left: -0.9rem;
`;

export const Logo = styled.img`
  width: auto;
  max-width: 100%;
  margin-bottom: 1rem;
`;

const setFlex = ({ justify, align, direction }) => `
  display: flex;
  ${justify ? `justify-content: ${justify}` : ""};
  ${align ? `align-items: ${align}` : ""};
  ${direction ? `flex-direction: ${direction}` : ""};
`;

const setPosition = ({ position, top, bottom, left, right, zIndex }) => `
  ${position ? `position: ${position}` : ""};
  ${top ? `top: ${top}px` : ""};
  ${bottom ? `bottom: ${bottom}px` : ""};
  ${left ? `left: ${left}px` : ""};
  ${right ? `right: ${right}px` : ""};
  ${zIndex ? `z-index: ${zIndex}` : ""};
`;

export { setFlex, setPosition };

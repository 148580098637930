import React, { useRef, useEffect } from "react";
import gsap from "../components/Animations";
import {
  SmallHeading,
  ChatContainer,
  Content,
  Hidden,
} from "../styles/components/PDP/Poster";

const PDPExperience = () => {
  const loadingRef = useRef(null);
  useEffect(() => {
    const loadingElement = loadingRef.current;
    const duration = 0.25;

    if (!loadingElement || !loadingElement.classList.contains("active")) return;

    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 1,
      onUpdate: () => {
        if (!loadingElement.classList.contains("active")) tl.kill();
      },
    });

    tl.to(loadingElement, { duration: duration, text: "LOADING", ease: "none" })
      .to(loadingElement, {
        duration: duration,
        text: "LOADING.",
        ease: "none",
      })
      .to(loadingElement, {
        duration: duration,
        text: "LOADING..",
        ease: "none",
      })
      .to(loadingElement, {
        duration: duration,
        text: "LOADING...",
        ease: "none",
      });
  }, []);
  return (
    <Content>
      <ChatContainer>
        <SmallHeading
          ref={loadingRef}
          className="poster-loading active"
          $loading
        >
          LOADING...
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">[ava chat bot 2.3]</Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">
            Thank you for choosing the sense emapthic chip! lets program your
            chip for you
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">[ava chat bot 2.3]</Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">
            Please wait as we programme your empathic chip with the sensory
            experience, ready for pre-order
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">[ava chat bot 2.3]</Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">
            programming...programming...programming...programming...
            programming...programming...programming...programming...
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">\DooMLORD-rooting runme-UNROOT.bat</Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">
            Easy rooting toolkit v1.8 UNROOTING SCRIPT created by DooMLORD based
            heavily on FlashTool scripts by Bin4ry and Androxyde Credits go to
            all those involved in making this possible!
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">[*] This script will:</Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type">
          <Hidden className="text">
            (1) unroot ur device using special script
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type">
          <Hidden className="text">
            (2) renove Busybox and assocaited symlinks
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">
            (3) renove SU files and assocaiated data
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="bottom">
          <Hidden className="text">[*] Before u begin:</Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type">
          <Hidden className="text">
            (1) Make sure u have installed adb drivers for ur device <br></br>
            (2) enable "USB DEBUGGING"from Menu
            Settings\Applications\Development <br></br>
            (3) enable "UNKNOWN SOURCES from Menu Settings\Applications{" "}
            <br></br>
            (4) [OPTIONAL] increase screen tine out to 10 minutes
            <br></br>
            (5) connect USB cable to PHONE and then connect to PC
            <br></br>
            (6) skip "PC Companion Software" prompt on device <br></br>
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="top">
          <Hidden className="text">CONFIRM ALL THE ABOVE THEN</Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="top">
          <Hidden className="text">
            MAKE SURE THAT THE SCREEN IS UNLOCKED and if you get Superuser
            prompts ACCEPT/ALLOW THEM ELSE THIS WILL NOT WORK
          </Hidden>
        </SmallHeading>
        <SmallHeading className="scramble-type" $margin="top">
          <Hidden className="text">Success!!</Hidden>
        </SmallHeading>
      </ChatContainer>
    </Content>
  );
};

export default PDPExperience;

import THREE from "../../Three";
import {
  bgVertexShader,
  bgFragmentShader,
} from "../../../../static/materials/shaders/bg-shaders";
import gsap from "../../../components/Animations";
import { Global, Senses } from "../../../components/Sense-Objects";
import {
  disposeTexturesAndGeometries,
  createAnimations,
  addToCart,
  setCart,
  activateGlitchVideo,
  updateRatios,
  mm,
} from "./Modules";

export default class PDP {
  constructor(scene, activeSense, activeAva, activeCart, renderer) {
    this.scene = scene;
    this.renderer = renderer;
    this.camera = scene.userData.camera;
    this.element = scene.userData.element;
    this.canvas = renderer.domElement;
    this.fov = this.camera.fov;
    this.ratio = 0.85;

    this.composer = new THREE.EffectComposer(this.renderer);
    this.labelRenderer = new THREE.CSS2DRenderer();
    this.clock = new THREE.Clock();

    this.activeSense = activeSense;
    this.activeAva = activeAva;
    this.activeCart = activeCart;

    this.id = 1;
    this.sectionActive = false;

    this.ctx = gsap.context(() => {});

    this.activateGlitch = false;
    this.experience = false;
    this.transitioning = false;
    this.animating = false;
    this.current = 1;
    this.color = new THREE.Vector3();

    this.objects = null;
    this.chip = null;
    this.ava = null;
    this.colorTo = null;
    this.backgroundCreated = false;

    this.pdp = document.getElementById("section-pdp");

    this.onResize = this.onResize.bind(this);

    this.scene.userData.constructor = this;

    this.initScene();
    this.createBackground();
    this.onResize();
  }

  initScene() {
    const mediaMatcher = mm();
    this.context = mediaMatcher.contexts[0].conditions;

    this.light = new THREE.DirectionalLight();
    this.light.intensity = 3;
    this.light.position.set(15, 0, 20);

    this.light.castShadow = true;

    this.scene.add(this.light);

    this.composer.addPass(new THREE.RenderPass(this.scene, this.camera));

    this.glitchPass = new THREE.GlitchPass();
    this.composer.addPass(this.glitchPass);

    const outputPass = new THREE.OutputPass();
    this.composer.addPass(outputPass);

    this.glitchPass.enabled = false;
  }

  setClasses(plpInstance, pagesInstances, checkoutInstance) {
    this.PLP = plpInstance;
    this.Pages = pagesInstances;
    this.Checkout = checkoutInstance;
  }

  createBackground() {
    if (this.bgMaterial) {
      this.bgMaterial.dispose();
    }

    this.bgMaterial = new THREE.ShaderMaterial({
      uniforms: {
        u_time: { type: "f", value: 1.0 },
        u_mouse: { value: new THREE.Vector2() },
        u_resolution: { value: { x: null, y: null } },
        u_color: {
          value: new THREE.Vector3(
            Global.color.x,
            Global.color.y,
            Global.color.z
          ),
        },
      },
      vertexShader: bgVertexShader(),
      fragmentShader: bgFragmentShader(),
      wireframe: false,
    });

    this.bgGeometry = new THREE.PlaneGeometry(6, 6);

    if (this.mesh) {
      this.scene.remove(this.mesh);
    }

    this.mesh = new THREE.Mesh(this.bgGeometry, this.bgMaterial);
    this.mesh.position.z = -10;
    this.mesh.name = "Background";

    this.scene.add(this.mesh);
    this.scaleGeometry(this.mesh, this.camera);
    this.updateResolution(this.bgMaterial);
    updateRatios(this);
  }

  createColourTransition(color) {
    this.transitioning = true;
    this.colorTo = color;
  }

  updateChipContainer() {
    let svgs = this.pdp.querySelectorAll("rect, path, line, circle");
    let images = this.pdp.querySelectorAll(".fade-image");

    setCart();

    if (!this.context.isMobile) {
      gsap.set(svgs, {
        drawSVG: 0,
      });

      gsap.set(images, {
        opacity: 0,
      });
    }

    this.objects = Senses.items.find(
      (item) => item.id === this.chip.userData.id
    );

    this.createColourTransition(
      this.color.set(
        this.objects.color.x,
        this.objects.color.y,
        this.objects.color.z
      )
    );

    this.onResize();

    createAnimations(this, this.objects.animations);
  }

  updateCartQuanities() {
    this.activeCart();
  }

  triggerExperience() {
    const message = this.pdp.querySelector(".message");

    if (this.activateGlitch) {
      activateGlitchVideo(this, message);
    } else {
      addToCart(false, this.chip, this, this.PLP);
    }
  }

  chipAddedToScene() {
    this.chip = null;
    this.chip = this.scene.children.find((child) => child.userData.chip);

    this.ava = this.scene.children.find((child) => child.name === "Ava");
    this.ava.visible = this.context.isDesktop;

    this.active = true;

    this.avaAddedToScene();
    this.activeSense(this.chip.userData.id);

    this.chip.userData.active = true;

    this.chip.position.set(-12, 40, 25);
    this.chip.scale.set(35, 35, 35);
    this.chip.rotation.set(0, 0, 0);

    this.chip.updateMatrix();

    this.updateChipContainer();
  }

  avaAddedToScene() {
    this.chatElement = document.querySelector(".chat");
    this.chatLabel = this.ava.children.find((child) => child.isCSS2DObject);

    this.updateAvaPosition();

    this.chatLabel.position.set(0, 0, 0);
    this.chatLabel.center.set(1.35, 0.35);

    this.labelRenderer.setSize(
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = "0px";
    this.element.appendChild(this.labelRenderer.domElement);
  }

  updateAvaPosition() {
    const { clientWidth, clientHeight } = this.canvas;

    const depth = 55;

    const multiplier = clientWidth > 1800 ? 0.445 : 0.465;
    const frustumHeight =
      2.0 * depth * Math.tan((this.fov * multiplier * Math.PI) / 180.0);
    const frustumWidth = frustumHeight * (clientWidth / clientHeight);

    const margin = 10;

    const right = frustumWidth / 2 - margin;
    const bottom = -frustumHeight / 2 + margin;

    this.ava.position.copy(this.camera.position);
    this.ava.rotation.copy(this.camera.rotation);
    this.ava.updateMatrix();

    this.ava.translateX(right);
    this.ava.translateY(bottom);
    this.ava.translateZ(-depth);

    this.ava.userData.position = this.ava.position.clone();
  }

  disposePDPScene() {
    this.ctx.revert();
    const disposeObjects = [this.bgGeometry, this.bgMaterial];
    disposeTexturesAndGeometries(disposeObjects);

    this.glitchPass.dispose();
    this.removePDPEventListeners();

    if (this.ava !== null || this.chip !== null) {
      this.PLP.moveItemsBetweenScenes(
        this.scene,
        this.PLP.scene,
        this.chip,
        this.ava
      );
    }
  }

  updatePDPScene() {
    this.ctx.revert();
    this.chipAddedToScene();
    this.PLP.disposePLPScene();
    this.createPDPEventListeners();
  }

  updateResolution(target) {
    target.uniforms.u_resolution.value.x = this.canvas.clientWidth;
    target.uniforms.u_resolution.value.y = this.canvas.clientHeight;
  }

  scaleGeometry(geometry, camera) {
    const distance = camera.position.z - geometry.position.z;
    const fov = (camera.fov * Math.PI) / 180;
    const scaleY = 2 * Math.tan(fov / 2) * distance;
    const scaleX = scaleY * camera.aspect;
    geometry.scale.set(scaleX, scaleY, 1);
  }

  triggerComposer() {
    if (this.activateGlitch && this.experience) {
      this.composer.render();
    } else {
      this.renderer.autoClear = true;
      this.renderer.render(this.scene, this.camera);
      this.renderer.autoClear = false;

      if (!this.backgroundCreated) {
        this.createBackground();
        this.backgroundCreated = true;
      }
    }
  }

  createPDPEventListeners() {
    window.addEventListener("resize", this.onResize);
  }

  removePDPEventListeners() {
    window.removeEventListener("resize", this.onResize);
  }

  render() {
    if (!this.sectionActive) return;

    this.bgMaterial.uniforms.u_time.value += this.clock.getDelta();

    if (this.transitioning) {
      this.bgMaterial.uniforms.u_color.value.lerp(this.colorTo, 0.01);
    }

    if (this.chip?.userData.active) {
      this.chip.position.y += Math.sin(Date.now() * 0.001) * Math.PI * 0.003;
    }

    if (this.ava !== null && this.context.isDesktop) {
      this.ava.visible = true;
      this.PLP.updateAvaUniforms();
      this.labelRenderer.render(this.scene, this.camera);
    }
    this.bgMaterial.dispose();
  }

  onResize() {
    if (this.context.isDesktop) {
      this.labelRenderer.setSize(
        this.canvas.clientWidth,
        this.canvas.clientHeight
      );

      if (this.ava) {
        this.ava.visible = true;
        this.updateAvaPosition();
      }

      if (this.experience) {
        this.composer.setSize(
          this.canvas.clientWidth,
          this.canvas.clientHeight
        );
      }
    } else if (this.ava) {
      this.ava.visible = false;
    }

    updateRatios(this);
    this.updateResolution(this.bgMaterial);
    this.scaleGeometry(this.mesh, this.camera);
  }
}

import gsap from "../../../components/Animations";
import { disposeTexturesAndGeometries } from "./Modules";

function chipGlobal(chip, PLP, textures) {
  chip.traverse((mesh) => {
    if (mesh.isMesh) {
      mesh.material.normalMap = textures.microchips;
      mesh.material.normalScale.set(2, 2);
      mesh.material.needsupdate = true;
      disposeTexturesAndGeometries(mesh.material);
    }
  });
  PLP.Pages.chipAddedToScene(chip);
}

function chipMaterials(chip, textures) {
  let offset = 1;

  chip.traverse((mesh) => {
    if (mesh.isMesh) {
      offset = offset * 2;
      mesh.material.normalMap = textures.microchips;
      mesh.material.normalScale.set(offset, offset);
      mesh.material.needsupdate = true;
    }
  });
}

function chipGroup(textures, group) {
  let count = 0;
  let clone;

  const range = gsap.utils.wrap(1, 6);

  group.children.forEach((chip) => {
    chipMaterials(chip, textures);
    clone = chip.clone();
    group.add(clone);
  });

  group.children.forEach((chip, index) => {
    count += 1;
    count = range(count);
    chip.name = count + "-chip";
    chip.userData.id = count;
    chip.userData.order = index;
    chip.userData.chip = true;
  });
}

function updateChipProperties(PLP, textures) {
  chipGroup(textures, PLP.group);
  updateChipPositions(PLP);
  PLP.group.name = "Chips";
  PLP.group.position.set(0, 0, -225);
  PLP.scene.add(PLP.group);
  PLP.group.updateMatrixWorld();

  PLP.group.traverse((chip) => {
    if (chip.isMesh) {
      disposeTexturesAndGeometries(chip.material);
    }
  });
}

function updateChipPositions(PLP) {
  let rotationY, positionX, positionY;

  PLP.group.children.sort((a, b) => a.userData.order - b.userData.order);

  PLP.group.children.forEach((chip, index) => {
    rotationY = index % 2 === 0 ? 5.025 : 4.5;
    positionX = index % 2 === 0 ? -75 : 75;
    positionY = -PLP.sectionWidth * (index - 1);
    chip.scale.set(170, 170, 170);
    chip.rotation.set(Math.PI / 2, rotationY, 0);
    chip.position.set(positionX, 0, positionY);
    chip.userData.active = false;
    chip.userData.rotation = chip.rotation.clone();

    if (index === 0) {
      PLP.view = chip;
    }
  });
}

export { chipGlobal, chipGroup, updateChipProperties, updateChipPositions };

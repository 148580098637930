import React, { useRef, useEffect, useState } from "react";
import gsap from "../components/Animations";
import {
  Wrapper,
  CartContainer,
  CartBanner,
  CartHeading,
  Close,
  CartItems,
  SubTotal,
  TotalItems,
  Total,
  Value,
  Item,
  NoItemsContainer,
  ItemImage,
  ItemDetails,
  ItemPrice,
  Image,
  SmallHeading,
  Paragraph,
  TotalsContainer,
  CheckOut,
  Column,
  Button,
} from "../styles/components/Global/Cart";
import { COLOURS } from "../styles/variables/Colors";

const ButtonItem = ({ classNames, title, colour, dark, onClick }) => {
  const [active, setActive] = useState(false);
  const linkRef = useRef(null);

  const onMouseEnter = () => {
    if (active) return;
    setActive(true);
    const text = linkRef.current.querySelector(".text");
    const timeline = gsap.timeline({});
    gsap.rescrambleAnimation(text, timeline);
    timeline.then(() => setActive(false));
  };

  return (
    <Button
      ref={linkRef}
      className={classNames}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      $dark={dark}
    >
      <SmallHeading className="text" $setcolour={colour}>
        {title}
      </SmallHeading>
    </Button>
  );
};

const Cart = ({ items, update }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartIsEmpty, setCartIsEmpty] = useState(false);
  const [initiateCart, setInitiateCart] = useState(false);

  const clearLocalStorage = () => {
    setCartItems([]);
    setCartIsEmpty(true);
    localStorage.removeItem("cartItems");
  };

  useEffect(() => {
    if (initiateCart) return;
    const initialCartItems = JSON.parse(localStorage.getItem("cartItems"));
    setCartItems(initialCartItems || []);
    setCartIsEmpty(!initialCartItems || initialCartItems.length === 0);
    setInitiateCart(true);
  }, [initiateCart]);

  useEffect(() => {
    if (!initiateCart) return;
    const updatedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    setCartItems(updatedCartItems);
    setCartIsEmpty(!updatedCartItems || updatedCartItems.length === 0);
  }, [update]);

  let subtotal = cartItems?.reduce((total, cartItemId) => {
    const selectedItem = items.find((item) => item.id === cartItemId);
    return total + (selectedItem ? selectedItem.price : 0);
  }, 0);

  const totalCount = cartItems ? cartItems.length : 0;
  subtotal = subtotal || 0;

  return (
    <Wrapper className="cart-container">
      <CartContainer>
        <CartBanner>
          <CartHeading>
            <SmallHeading>Cart</SmallHeading>
          </CartHeading>
          <TotalItems>
            <SmallHeading>Total ({totalCount})</SmallHeading>
          </TotalItems>
          <Close className="cart close">
            <SmallHeading>X</SmallHeading>
          </Close>
        </CartBanner>
        {cartItems && !cartIsEmpty && (
          <CartItems>
            {items.map(
              (item) =>
                cartItems?.includes(item.id) && (
                  <Item key={item.id}>
                    <ItemImage>
                      <Image
                        src="https://static.highsnobiety.com/dato/40484/1693243100-cart-chip-order.png"
                        loading="lazy"
                      />
                    </ItemImage>
                    <ItemDetails>
                      <Paragraph $bold>
                        {item.title} 0{item.id}
                      </Paragraph>
                      <Paragraph>{item.cart}</Paragraph>
                    </ItemDetails>
                    <ItemPrice>
                      <Paragraph $bold>PRICE: {item.price}</Paragraph>
                    </ItemPrice>
                  </Item>
                )
            )}
          </CartItems>
        )}
        {cartIsEmpty && (
          <CartItems>
            <NoItemsContainer>
              <SmallHeading $margin="top">No items in your cart</SmallHeading>
            </NoItemsContainer>
          </CartItems>
        )}
        <TotalsContainer>
          <SubTotal>
            <Total>
              <SmallHeading>Subtotal</SmallHeading>
            </Total>
            <Value>
              <SmallHeading>{subtotal}</SmallHeading>
            </Value>
          </SubTotal>
          <CheckOut>
            <Column>
              <ButtonItem
                classNames="checkout"
                title="Checkout"
                colour={COLOURS.primary.black}
              />
            </Column>
            <Column>
              <ButtonItem
                title="Clear Cart"
                colour={COLOURS.primary.white}
                dark={true}
                onClick={clearLocalStorage}
              />
            </Column>
          </CheckOut>
        </TotalsContainer>
      </CartContainer>
    </Wrapper>
  );
};

export default Cart;

import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ContainerOverlay, Hidden } from "../styles/components/PDP/Global";
import {
  Border,
  LeftColumn,
  RightColumn,
  LeftNavigation,
  RightNavigation,
  Logo,
  BannerHeading,
  LeftContainer,
  VerticalBanner,
  SVGContainer,
  Banner,
  ColorBlock,
  HorizontalSeparator,
  SmallHeading,
  SideLine,
  NavigationWrapper,
  NavigationContainer,
  MobileNavigation,
  SVGWrapper,
} from "../styles/components/Global/Navigation";
import { SideLines } from "./SVGs";
import MenuNavigation from "./Menu-Navigation";
import PLPNavigation from "./PLP-Navigation";

const Navigation = (props) => {
  const { pdp, plp, pages, checkout } = props;
  const source = props.global || props;
  const { symbol, code, subheading } = source;

  const themeContext = useContext(ThemeContext);

  return (
    <NavigationWrapper
      className={`${
        (pdp && "pdp-") ||
        (plp && "plp-") ||
        (pages && "pages-") ||
        (checkout && "checkout-")
      }navigation`}
      $absolute={pdp}
    >
      <NavigationContainer $full={plp} $plp={plp}>
        <LeftColumn $full={plp}>
          <LeftNavigation>
            <HorizontalSeparator />
            <LeftContainer $plp={plp}>
              <Logo
                className="reset"
                src={themeContext.logo.source}
                alt={themeContext.alt}
                loading="lazy"
              />
            </LeftContainer>
            <MobileNavigation>
              <MenuNavigation {...props} />
            </MobileNavigation>
            <LeftContainer $hiddenmd>
              <BannerHeading className="scramble-delay" $hiddensm>
                <Hidden className="text">{code}</Hidden>
              </BannerHeading>
            </LeftContainer>
          </LeftNavigation>
          {plp && <PLPNavigation {...props} />}
        </LeftColumn>
        <RightColumn $full={plp} $hiddensm>
          <RightNavigation>
            <MenuNavigation {...props} />
          </RightNavigation>
        </RightColumn>
      </NavigationContainer>
      <ContainerOverlay $fixed $plp={plp} $pdp={pdp}>
        <Border>
          {pdp ||
            (checkout && (
              <SVGWrapper>
                <SVGContainer>
                  <symbol.large />
                </SVGContainer>
              </SVGWrapper>
            ))}
          <VerticalBanner $hiddensm>
            <Banner>
              <ColorBlock className="block" width="15px" />
            </Banner>
            <Banner>
              <SideLine>
                <SmallHeading $blend>{subheading}</SmallHeading>
                <SideLines />
              </SideLine>
            </Banner>
          </VerticalBanner>
        </Border>
      </ContainerOverlay>
    </NavigationWrapper>
  );
};

export default Navigation;

import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import {
  BREAKPOINTS,
  hiddenxl,
  hiddensm,
  ipad,
  mobile,
} from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Wrapper = styled.div`
  ${setFlex({ direction: "column" })};
  ${setPosition({ position: "relative" })};
  min-height: 100vh;
  width: 100%;
  height: auto;
`;

export const ContainerOverlay = styled.div`
  min-height: 100vh;
  height: auto;
  width: 100%;
  ${(props) => props.$blend && `mix-blend-mode: difference`};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 0 })};
  ${setFlex({ justify: "flex-end", align: "center" })}
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  ${generateFont("0.6rem", false)};
  ${generateLineHeight("0.6rem", false)};
  margin: 0;
  text-align: left;
`;

export const OverlayColumn = styled.div`
  ${setFlex({ align: "center", justify: "flex-end" })};
  ${setPosition({ position: "relative" })};
  width: 100%;
  height: 100vh;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ align: "flex-start", justify: "flex-start" })};
  }
`;

export const LandingWrapper = styled.div`
  ${setFlex({})};
  ${setPosition({ position: "absolute" })};
  width: 50%;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 60%;
    margin: 1.5rem;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${setFlex({ justify: "flex-end", align: "flex-end" })};
    width: 70%;
    margin-left: 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "flex-start", align: "flex-start" })};
    width: 100%;
    max-width: 100vw;
    margin-top: 5rem;
  }
`;

export const LandingContainer = styled.div`
  min-width: 500px;
  max-width: 40vw;
  margin: 1rem;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: unset;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 75%;
    margin-left: 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
    margin: 0;
  }
`;

export const DisplayTitle = styled.div`
  ${setFlex({ direction: "row" })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "row-reverse" })};
    margin-left: 1rem;
    h1 {
      ${generateFont("5rem", false, "xs")};
      ${generateLineHeight("4.7rem", false, "xs")};
    }
  }
`;

export const HeadingWrapper = styled.div`
  ${setFlex({ direction: "column" })};
  width: fit-content;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
  }
`;

export const HeadingContain = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  height: auto;
  ${setPosition({ position: "relative" })};
  ${(props) =>
    props.$margin &&
    `
  svg {
    margin-left: 0.6rem;
  }
`};
  ${(props) =>
    props.$fitWidth &&
    `
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    width: fit-content;
  }
`};
  ${(props) => props.$hiddenxl && hiddenxl};
  ${(props) => props.$ipad && ipad};
  ${(props) => props.$mobile && mobile};
  ${(props) => props.$hiddensm && hiddensm};
`;

export const HeadingContainer = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${setPosition({ position: "relative" })};
  margin-bottom: 0.8rem;
  max-height: 60px;
  width: fit-content;
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
    max-height: unset;
    max-width: 90vw;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-width: 95vw;
    margin-left: 0.5rem;
  }
  @media (max-width: ${BREAKPOINTS.xxs}px) {
    max-width: 100vw;
  }
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    max-height: unset;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const MaskContainer = styled.div`
  ${setFlex({})};
  ${setPosition({ position: "relative" })};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.9rem`};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: unset;
    margin: 0;
  }
`;

export const Mask = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: -1 })};
  background-color: ${COLOURS.primary.white};
  height: 100%;
  width: 0%;
  ${(props) =>
    props.$desktop &&
    `
      @media (max-width: ${BREAKPOINTS.sm}px) {
      background-color: transparent;
    }
  `};
`;

export const Link = styled.a`
  ${setFlex({})};
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const QRCode = styled.img`
  width: 60px;
  height: 60px;
  max-width: unset;
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    width: -webkit-fill-available;
    width: -moz-available;
    max-height: 80px;
    height: 100%;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const SymbolContainer = styled.div`
  ${setFlex({})};
  margin-bottom: ${(props) => (props.$noMargin ? "0" : "0.6rem")};
  > svg {
    margin-right: 0.6rem;
  }
`;

export const ProductInfoWrapper = styled.div`
  ${setFlex({})};
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "space-between" })};
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${setFlex({ justify: "flex-start" })};
    h4 {
      ${setFlex({ align: "flex-end" })};
    }
  }
`;

export const ProductNo = styled.div`
  ${setPosition({ position: "relative" })};
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${COLOURS.primary.yellow};
  font-size: 4.5rem;
  line-height: 3.75rem;
  margin: 0;
  text-align: center;
  width: 30%;
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    ${generateFont("3.4rem", false, "xxxl")};
    ${generateLineHeight("3.1rem", false, "xxxl")};
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const SmallHeading = styled(Headline)`
  color: ${(props) => props.$setcolour || COLOURS.primary.black};
  ${(props) =>
    props.$width &&
    `
    max-width: ${props.$width}px;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      max-width: unset;
    }
  `};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
  ${(props) =>
    props.$setcolourMobile &&
    `
      @media (max-width: ${BREAKPOINTS.sm}px) {
        color: ${props.$setcolourMobile};
    }
  `};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${generateFont("1.2rem", false, "sm")};
    ${generateLineHeight("1.2rem", false, "sm")};
  }
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    ${generateFont("0.5rem", false, "xxl")};
    ${generateLineHeight("0.5rem", false, "xxl")};
  }
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$mobile && mobile};
  ${(props) => props.$break && "word-break: break-all"};
`;

export const BlendTitleContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ direction: "column" })};
`;

export const FloatContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ align: "flex-end", direction: "column" })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    order: 2;
    ${setFlex({ align: "flex-start", direction: "row" })};
    margin: 0.8rem 0;
  }
`;

export const FloatText = styled.div`
  ${setPosition({ position: "absolute", top: 30, left: 30 })}
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ top: "0", right: "-30" })};
    left: unset;
  }
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$mobile && mobile};
`;

export const SVGContainer = styled.div`
  ${setFlex({ align: "flex-end" })};
  ${setPosition({ position: "absolute" })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 60%;
  }
`;

export const SVGWrapper = styled.div`
  ${setPosition({ position: "relative" })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0 0.5rem;
  }
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$mobile && mobile};
`;

export const Title = styled.h1`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  margin: 0;
  ${(props) =>
    props.$margin &&
    `
    margin-${props.$margin}: 2.5rem;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      margin-${props.$margin}: 1rem;
  }
  `};
  ${generateFont("3.5rem", false)};
  ${generateLineHeight("3.2rem", false)};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${generateFont("3.5rem", false, "sm")};
    ${generateLineHeight("3rem", false, "sm")};
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${generateFont("2rem", false, "xs")};
    ${generateLineHeight("1.8rem", false, "xs")};
  }
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    ${generateFont("3.4rem", false, "xxxl")};
    ${generateLineHeight("3.1rem", false, "xxxl")};
  }
  ${(props) =>
    props.$mobileFlex &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      ${setFlex({ justify: "flex-end" })};
    }
  `};
  ${(props) => props.$hiddensm && hiddensm};
`;

export const ProductTitle = styled(Title)`
  color: ${COLOURS.primary.black};
  margin: 0 0 0 2.5rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0 0 0 1rem;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const SecondaryTitleContainer = styled.div`
  ${setFlex({ direction: "column" })};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-right: 0.5rem;
  }
`;

export const TitleFloatContainer = styled.div`
  ${setPosition({ position: "relative" })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    order: 1;
    margin: 1rem 0 0 0;
  }
`;

export const TitleFooterContainer = styled.div`
  ${setPosition({ position: "relative" })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    order: 3;
    margin: 0.8rem 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 60%;
  }
`;

export const TitleFooter = styled.div`
  ${(props) => props.$flex && setFlex({})};
  width: 100%;
  padding: 0.6rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 0;
  }
`;

export const VerticalText = styled.div`
  ${setFlex({ align: "center" })};
  ${setPosition({ position: "relative" })};
  padding-right: 0.9rem;
  writing-mode: vertical-lr;
  text-orientation: mixed;
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  background-color: ${COLOURS.primary.blue};
  min-height: 1.5rem;
  margin-left: -0.6rem;
`;

export const MiniHeading = styled(Headline)`
  color: ${COLOURS.primary.white};
  ${(props) => props.$margin && "margin-top: 0.6rem"};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${generateFont("0.5m", false, "sm")};
    ${generateLineHeight("0.5rem", false, "sm")};
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

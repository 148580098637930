import styled from "styled-components";
import { BREAKPOINTS, hiddensm } from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const LoadingWrapper = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: "9999" })};
  background-color: ${COLOURS.primary.black};
  height: 100%;
  width: 100%;
  opacity: 0;
  display: none;
  &.active {
    display: block;
  }
  ${(props) => props.$visibility && "opacity: 1"};
`;

export const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0;
  ${(props) => props.$visibility && "opacity: 1"};
`;

export const Container = styled.div`
  ${setFlex({ justify: "center", direction: "column" })};
  height: 100%;
  width: 100%;
  max-width: 50vw;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    max-width: 65vw;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    max-width: 75vw;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 80vw;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-width: 100vw;
  }
`;

export const LoadingBar = styled.div`
  ${setFlex({ align: "center" })};
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "center", direction: "column" })};
  }
`;

export const ProgressBar = styled.div`
  ${setPosition({ position: "absolute", left: 100 })};
  border: 1px solid ${COLOURS.primary.blue};
  width: 100vw;
  min-height: 5px;
  height: fit-content;
  margin-left: 0.5rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative", left: "0" })};
    margin: 0 0 2rem 0;
    width: 100%;
  }
`;

export const Progress = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0" })};
  background-color: ${COLOURS.primary.blue};
  height: 100%;
`;

export const LoadingContainer = styled.div`
  ${setFlex({ justify: "center", align: "center", direction: "column" })};
  max-width: 60%;
  @media (max-width: ${BREAKPOINTS.md}px) {
    max-width: 65%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 100vw;
    padding: 0 1rem;
  }
`;

export const DisclaimerContainer = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  max-width: 50vw;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 60vw;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-width: 100vw;
  }
`;

export const HeadingContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ justify: "flex-start", align: "center" })};
  ${(props) =>
    setFlex({
      direction: props.$direction,
    })};
  width: ${(props) => (props.$fitwidth ? "fit-content" : "100%")};
  margin: 0.5rem 0;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "center" })};
  }
`;

export const LandingContainer = styled.div`
  ${setPosition({ position: "relative" })};
`;

export const AudioLabelContainer = styled.div`
  ${setPosition({ position: "relative" })};
  margin-right: 1rem;
  width: fit-content;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0.5rem 0;
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.white};
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin: 0 0.9rem 0 0;
  text-align: left;
  ${(props) =>
    props.$mobileCenter &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
        text-align: center;
    }
  `};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  @media (max-width: ${BREAKPOINTS.xxs}px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-right: 0;
  }
`;

export const SmallHeading = styled(Headline)`
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 0;
  mix-blend-mode: difference;
  pointer-events: none;
  ${(props) => props.$nowrap && "white-space: nowrap"};
  ${(props) => props.$hiddensm && hiddensm};

  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  @media (max-width: ${BREAKPOINTS.xxxs}px) {
    font-size: 0.6rem;
    line-height: 0.6rem;
  }

  ${(props) => props.$padding && "padding-bottom: 1rem;"};
`;

export const Hidden = styled.span`
  opacity: 0;
  pointer-events: none;
`;

export const DisclaimerHeading = styled(Headline)`
  color: ${COLOURS.primary.yellow};
  font-size: 0.8rem;
  line-height: 1rem;
  margin: 0;
  pointer-events: none;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    text-align: center;
  }
`;

export const EnableAudioContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ align: "center" })};
  margin-top: 1.25rem;
  text-align: center;
  opacity: 0;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
    padding: 0 1rem;
  }
`;

export const AudioContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({})};
  ${(props) => props.$margin && "margin: 0 0.3rem"};
  text-align: center;
`;

export const AudioLabel = styled.label`
  ${setPosition({ position: "relative" })};
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 0;
  cursor: pointer;
  ${(props) => props.$nowrap && "white-space: nowrap"};
`;

export const AudioInput = styled.input`
  display: none;
`;

export const CheckboxWrapper = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
`;

export const CheckboxContainer = styled.div`
  width: 50px;
  height: 30px;
`;

export const CrossCheckbox = styled.span`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "absolute", top: "0", left: "0" })};
  font-family: frekuent-mono-regular;
  width: 100%;
  height: 100%;
  border: 1px solid ${COLOURS.primary.white};
  background-color: transparent;
  font-size: 0.6rem;
  line-height: 0.8rem;
  text-align: center;
  cursor: pointer;
  color: ${COLOURS.primary.white};
`;

export const Logo = styled.img`
  width: auto;
  max-width: 100%;
  height: fit-content;
  max-height: 20px;
  margin: 0 0.6rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: unset;
  }
`;

export const IntroductionHeadline = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  max-width: 40vw;
  opacity: 0;
  height: 250px;

  @media (max-width: ${BREAKPOINTS.xxs}px) {
    height: 200px;
  }

  &.active {
    opacity: 1;
    z-index: 1;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ left: "0" })};
    max-width: 100%;
    padding: 0 1rem;
  }
`;

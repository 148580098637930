import React, { useEffect, useState, useCallback } from "react";
import { SceneContainer, Scene } from "../styles/components/Global/Global";
import { Global, Senses, Ava } from "./Sense-Objects";
import Transition from "./Transition";
import Loader from "./Loader";
import Init from "../3D/SceneManager";
import Plp from "./PLP";
import Pdp from "./PDP";
import Pages from "./Pages";
import ChatBot from "./ChatBot";
import Cart from "./Cart";
import CheckoutLanding from "./Checkout-Landing";
import { AppWrapper } from "../styles/components/PLP/Global";
import { BREAKPOINTS } from "../styles/variables/Breakpoints";

const App = () => {
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [updateCart, setUpdateCart] = useState(0);
  const [loaderPlayed, setLoaderPlayed] = useState(false);
  const [active, setActive] = useState(0);
  const [sense, setSense] = useState(0);
  const [chat, setChat] = useState({
    stage: 1,
    glitch: false,
  });

  const isMobile =
    typeof window !== "undefined" && window.innerWidth <= BREAKPOINTS.md;

  const enableAudio = useCallback((enable) => {
    setAudioEnabled(enable);
  }, []);

  useEffect(() => {
    const activeLoaderPlayed = (state) => setLoaderPlayed(state);
    const activeSection = (section) => setActive(section);
    const activeSense = (sense) => setSense(sense);
    const activeAva = (block, enable) =>
      setChat({
        stage: block,
        glitch: enable,
      });
    const activeCart = () => setUpdateCart((updateCart) => updateCart + 1);

    Init(activeSense, activeAva, activeCart, activeSection, activeLoaderPlayed);
  }, []);

  return (
    <AppWrapper id="wrapper">
      <canvas id="app" />
      <Transition />
      {!loaderPlayed && (
        <Loader
          {...Global}
          audioEnabled={audioEnabled}
          toggleAudio={enableAudio}
        />
      )}
      <Cart {...Senses} update={updateCart} />
      <SceneContainer
        id="section-plp"
        className={`section ${active === 0 && "active"}`}
      >
        <Plp {...Global} senses={Senses} />
        <Scene className="scene">
          <ChatBot {...Ava} current={sense} ava={chat} />
        </Scene>
      </SceneContainer>
      <SceneContainer
        id="section-pdp"
        className={`section ${active === 1 && "active"}`}
      >
        {active === 1 && sense !== 0 && (
          <Pdp
            {...Senses}
            current={sense}
            global={Global}
            audioEnabled={audioEnabled}
            toggleAudio={enableAudio}
          />
        )}
        <Scene className="scene">
          {isMobile && <ChatBot {...Ava} current={sense} ava={chat} />}
        </Scene>
      </SceneContainer>
      <SceneContainer
        id="section-pages"
        className={`section ${active === 2 && "active"}`}
      >
        {active === 2 && (
          <Pages
            {...Global}
            senses={Senses}
            audioEnabled={audioEnabled}
            toggleAudio={enableAudio}
          />
        )}
        <Scene className="scene" />
      </SceneContainer>
      <SceneContainer
        id="section-checkout"
        className={`section ${active === 3 && "active"}`}
      >
        {loaderPlayed && (
          <CheckoutLanding
            {...Global}
            senses={Senses}
            audioEnabled={audioEnabled}
            toggleAudio={enableAudio}
          />
        )}
        <Scene className="scene" />
      </SceneContainer>
    </AppWrapper>
  );
};

export default App;

import styled from "styled-components";
import { BREAKPOINTS, hiddenxs } from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Heading = styled.h2`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 70px;
  line-height: 60px;
  font-weight: normal;
  margin: 0;
  flex-grow: 1;
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

export const SecondaryHeadline = styled(Headline)`
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 0.8rem;
  line-height: 0.8rem;
  ${(props) => props.$striked && "text-decoration: line-through"};
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 0.6rem;
  line-height: 0.8rem;
  max-width: 300px;
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 1.25rem`};
`;

export const Color = styled.div`
  color: ${(props) => props.$setcolour || COLOURS.primary.black};
`;

export const Hidden = styled.span`
  opacity: 0;
  pointer-events: none;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  ${setFlex({ direction: "column" })};
  min-height: 100vh;
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify || "center",
      align: props.$align || "center",
    })};
  ${setPosition({ position: "relative" })};
  height: ${(props) => props.$height || "250vh"};
  width: 100%;
  ${(props) => props.$blend && " mix-blend-mode: difference;"};
  ${(props) => props.$index && `z-index: ${props.$index}`};
  ${(props) =>
    props.$margin &&
    `
    margin-bottom: 10vh;
    @media (max-width: ${BREAKPOINTS.xs}px) {
      margin-bottom: 0;
    }
  `};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: fit-content;
    ${(props) => props.$mobileStrict && "min-height: 110vh"};
  }
  ${(props) => props.$background && `background-color: ${props.$background}`};
`;

export const ContainerOverlay = styled.div`
  min-height: 100dvh;
  height: auto;
  width: 100%;
  ${(props) =>
    props.$blend &&
    `
        align-items: center; 
        mix-blend-mode: difference
    `};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 10 })};
  ${(props) =>
    props.$fixed &&
    `
    ${setPosition({ position: "fixed", zIndex: 15 })};
    pointer-events: none;
  `};
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
`;

export const TitleBorder = styled.div`
  max-width: 100vw;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: ${(props) => (props.$lateral ? "0 1rem" : "1rem")};
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin: ${(props) => (props.$lateral ? "0 1rem" : "0.25rem 0 0 1rem")};
  }
  ${(props) =>
    props.$flex &&
    setFlex({ align: props.$align, direction: props.$direction })};
  ${(props) => props.$full && "width: 100%"};
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const Image = styled.img`
  ${setPosition({ position: "absolute" })};
  width: auto;
  max-width: 100%;
  margin: 1.25rem;
`;

export const Block = styled.div`
  height: 100%;
  width: 100%;
`;

import React from "react";
import { Wrapper, Container } from "../styles/components/PLP/Global";
import { ThemeProvider } from "styled-components";
import { THEMES } from "../styles/variables/Themes";
import Navigation from "./Navigation";

const PLPLanding = (senses) => {
  return (
    <Wrapper>
      <Container id="plp-landing" $height="250vh">
        <ThemeProvider theme={THEMES.dark}>
          <Navigation {...senses} plp={true} />
        </ThemeProvider>
      </Container>
    </Wrapper>
  );
};

export default PLPLanding;

import React from "react";
import {
  OverflowContainer,
  OverflowWrapper,
  ChatWrapper,
  ChatContainer,
  Chat,
  Name,
  SmallHeading,
  MicroHeading,
  FinalContainer,
  ProductMessage,
  Product,
} from "../styles/components/Global/ChatBot";

const ChatBot = ({ ava, blocks, current }) => {
  const active = ava.glitch ? 6 : current;
  const selected = blocks.find(
    (block) => block.sense === active && block.stage === ava.stage
  );

  return (
    <OverflowContainer>
      <ChatWrapper className="chat">
        <OverflowWrapper>
          {selected && (
            <ChatContainer className="message">
              <Chat>
                <SmallHeading>{selected.text}</SmallHeading>
              </Chat>
              <Name>
                <MicroHeading className="name">{selected.name}</MicroHeading>
              </Name>
            </ChatContainer>
          )}
          <FinalContainer className="reset">
            <Product
              src="https://static.highsnobiety.com/dato/40484/1692887442-chip-add-to-cart.png"
              loading="lazy"
            />
            <ProductMessage>
              <Chat>
                <SmallHeading>See our other products</SmallHeading>
              </Chat>
            </ProductMessage>
          </FinalContainer>
        </OverflowWrapper>
      </ChatWrapper>
    </OverflowContainer>
  );
};

export default ChatBot;

import gsap from "../../../components/Animations";
import { applyAnimation } from "../PDP/Chip-Animations";
import { mm } from "./Modules";
import { BREAKPOINTS } from "../../../styles/variables/Breakpoints";

async function waitForChip(Pages) {
  return new Promise((resolve) => {
    const resolveChip = () => {
      const chip = Pages.scene.children.find((child) => child.name === "Chip");
      if (chip) {
        resolve(chip);
      } else {
        setTimeout(resolveChip, 100);
      }
    };
    resolveChip();
  });
}

export async function createAnimations(Pages, timeline) {
  try {
    const chip = await waitForChip(Pages);
    pageAnimations(Pages, chip, timeline);
  } catch (error) {
    console.error(error);
  }
}

function pageAnimations(Pages, chip, timeline) {
  const { context, ctx } = Pages;
  const target = Pages.pages;

  if (!target) return;

  const svgs = target.querySelectorAll("rect, path, line, circle");
  const images = target.querySelectorAll(".fade-image");
  const parallax = target.querySelectorAll(".parallax");
  const navigationContainer = target.querySelector(".pages-navigation");
  const visionDisplay = document.getElementById("vision-display");
  const visionVideo = document.getElementById("vision-video");
  const video = visionVideo?.querySelector(".video");

  if (!context.isMobile) {
    gsap.set(svgs, { drawSVG: 0 });
    gsap.set(images, { opacity: 0 });
  }

  const visionTextElements = [
    target.querySelector("#vision-text-1"),
    target.querySelector("#vision-text-2"),
    target.querySelector("#vision-text-3"),
    target.querySelector("#vision-text-4"),
    target.querySelector("#vision-text-5"),
    target.querySelector("#vision-text-6"),
  ];

  const visionSenseElements = [
    target.querySelector("#vision-sense-1"),
    target.querySelector("#vision-sense-2"),
    target.querySelector("#vision-sense-3"),
    target.querySelector("#vision-sense-4"),
  ];

  const scrollTriggerConfig = {
    start: "top top",
    end: "bottom bottom",
    pin: !context.isMobile,
    pinSpacing: !context.isIpad || !context.isMobile ? false : true,
    anticipatePin: 1,
    invalidateOnRefresh: true,
  };

  timeline.add(() => {
    gsap.scrambleAnimation(navigationContainer);
    if (context.isDesktop) gsap.scrambleAnimation(visionDisplay);
    if (!context.isDesktop)
      if (!context.isDesktop)
        gsap.drawMask(visionDisplay?.querySelectorAll(".mask"));
    gsap.drawSVG(visionDisplay?.querySelectorAll("rect, path, line, circle"));
  }, "-=1.5");

  ctx.add(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        id: "vision-display",
        trigger: visionDisplay,
        start: () => (context.isMobile ? "top+=200 top" : "top top"),
        scrub: true,
      },
    });

    applyAnimation({
      tl,
      chip,
      xPosition: context.isMobile ? -30 : -60,
      zPosition: 40,
      positionPosition: 0,
      yRotation: "+=10",
      rotationPosition: 0,
      duration: 1,
    });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-text-0",
          trigger: visionTextElements[0],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionTextElements[0]);
        if (!context.isDesktop)
          gsap.drawMask(visionTextElements[0]?.querySelectorAll(".mask"));
        gsap.drawSVG(
          visionTextElements[0]?.querySelectorAll("rect, path, line, circle")
        );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-sense-0",
          trigger: visionSenseElements[0],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionSenseElements[0]);
        if (!context.isDesktop)
          gsap.drawSVG(
            visionSenseElements[0]?.querySelectorAll("rect, path, line, circle")
          );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-text-1",
          trigger: visionTextElements[1],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionTextElements[1]);
        if (!context.isDesktop)
          gsap.drawSVG(
            visionTextElements[1]?.querySelectorAll("rect, path, line, circle")
          );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-sense-1",
          trigger: visionSenseElements[1],
          scrub: true,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionSenseElements[1]);
        if (!context.isDesktop)
          gsap.drawSVG(
            visionSenseElements[1]?.querySelectorAll("rect, path, line, circle")
          );
      })
      .fromTo(
        parallax,
        { yPercent: context.isMobile ? 0 : -30 },
        {
          yPercent: context.isMobile ? 0 : 30,
          ease: "none",
        }
      );

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-text-2",
          trigger: visionTextElements[2],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionTextElements[2]);
        if (!context.isDesktop)
          gsap.drawSVG(
            visionTextElements[2]?.querySelectorAll("rect, path, line, circle")
          );
      });

    gsap.timeline({
      scrollTrigger: {
        id: "vision-video",
        trigger: visionVideo,
        start: "top top",
        end: "bottom bottom",
        pin: true,
        pinSpacing: false,
        anticipatePin: 1,
        onEnter: () => video.play(),
        onEnterBack: () => video.play(),
        onLeave: () => {
          if (video.currentTime > 0) video.pause();
        },
        onLeaveBack: () => {
          if (video.currentTime > 0) video.pause();
        },
      },
    });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-text-3",
          trigger: visionTextElements[3],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionTextElements[3]);
        if (!context.isDesktop)
          gsap.drawSVG(
            visionTextElements[3]?.querySelectorAll("rect, path, line, circle")
          );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-sense-2",
          trigger: visionSenseElements[2],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionSenseElements[2]);
        if (!context.isDesktop)
          gsap.drawSVG(
            visionSenseElements[2]?.querySelectorAll("rect, path, line, circle")
          );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-text-4",
          trigger: visionTextElements[4],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionTextElements[4]);
        if (!context.isDesktop)
          gsap.drawMask(visionTextElements[4]?.querySelectorAll(".mask"));
        gsap.drawSVG(
          visionTextElements[4]?.querySelectorAll("rect, path, line, circle")
        );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-sense-3",
          trigger: visionSenseElements[3],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionSenseElements[3]);
        if (!context.isDesktop)
          gsap.drawSVG(
            visionSenseElements[3]?.querySelectorAll("rect, path, line, circle")
          );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "vision-text-5",
          trigger: visionTextElements[5],
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (context.isDesktop) gsap.scrambleAnimation(visionTextElements[5]);
        if (!context.isDesktop)
          gsap.drawMask(visionTextElements[5]?.querySelectorAll(".mask"));
        gsap.drawSVG(
          visionTextElements[5]?.querySelectorAll("rect, path, line, circle")
        );
      });
  });
}

export function chipAnimations(chip) {
  const mediaMatcher = mm();
  const tl = gsap.timeline({});

  mediaMatcher.add(`(min-width: ${BREAKPOINTS.sm}px)`, () => {
    applyAnimation({
      tl,
      chip,
      xPosition: -10,
      yPosition: 0,
      zPosition: 25,
      positionPosition: 0,
      xRotation: 6.5,
      yRotation: -0.9,
      zRotation: -1.3,
      rotationPosition: 0,
      duration: 0,
    });
  });

  mediaMatcher.add(
    `(min-width: ${BREAKPOINTS.xs}px) and (max-width: ${BREAKPOINTS.sm - 1}px)`,
    () => {
      applyAnimation({
        tl,
        chip,
        xPosition: 0,
        yPosition: -10,
        zPosition: 15,
        positionPosition: 0,
        xRotation: 0.7,
        yRotation: -Math.PI / 2,
        zRotation: -1,
        rotationPosition: 0,
        duration: 0,
      });
    }
  );

  mediaMatcher.add(`(max-width: ${BREAKPOINTS.xs}px)`, () => {
    applyAnimation({
      tl,
      chip,
      xPosition: 0,
      yPosition: -15,
      zPosition: 25,
      positionPosition: 0,
      xRotation: 0.7,
      yRotation: -Math.PI / 2,
      zRotation: -1,
      rotationPosition: 0,
      duration: 0,
    });
  });
}

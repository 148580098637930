import gsap from "../../components/Animations";
import { cartAnimation } from "./PDP/Cart";

function onClick(event, [plp, pdp, pages, checkout], activeSection) {
  if (!plp.loaded) return;

  const target = event.target;

  if (target.classList.contains("vision") && !pages.sectionActive) {
    const tl = triggerTransition();
    tl.add(() => {
      setActiveScene([plp, pdp, pages, checkout], pages, activeSection);
      pages.updatePageScene(tl);
      endTransition(tl);
    });
  }
  if (target.classList.contains("reset")) {
    const tl = triggerTransition();
    tl.add(() => {
      plp.updatePLPScene(tl);
      setActiveScene([plp, pdp, pages, checkout], plp, activeSection);
    });
  }
  if (target.classList.contains("cart")) {
    cartAnimation(target, [plp, pdp, pages, checkout]);
  }
  if (target.classList.contains("checkout")) {
    const tl = triggerTransition();
    tl.add(() => {
      checkout.updateCheckoutScene();
      setActiveScene([plp, pdp, pages, checkout], checkout, activeSection);
      endTransition(tl);
    });
  }
}

function setActiveScene(subjects, selected, activeSection) {
  subjects.forEach((subject) => {
    subject.sectionActive = subject === selected;
  });
  activeSection(selected.id);
}

function triggerTransition() {
  const transition = document.getElementById("scene-transition");
  const wrapper = document.getElementById("wrapper");

  const tl = gsap.timeline();

  tl.to(transition, {
    autoAlpha: 0,
  })
    .add(() => transition.classList.add("active"))
    .to(transition, { autoAlpha: 1, duration: 1.5 })
    .add(() => {
      wrapper.classList.toggle("hovering", false);
    });

  return tl;
}

function endTransition(tl) {
  const transition = document.getElementById("scene-transition");

  tl.to(transition, {
    delay: 0.5,
    autoAlpha: 0,
    duration: 1.5,
  }).add(() => transition.classList.remove("active"));
}

export { onClick, triggerTransition, endTransition };

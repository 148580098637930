function handleScroll(enable, subjects) {
  let body = document.body;
  let plp;

  if (subjects) {
    plp = subjects[0];
  }

  if (enable) {
    plp && plp.observer.enable();
    body.classList.remove("disable-scroll");
  } else {
    plp && plp.observer.disable();
    body.classList.add("disable-scroll");
  }
}

export { handleScroll };

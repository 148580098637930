import React from "react";
import { PosterImage } from "../styles/components/Pages/Pages";

const ImageDisplay = ({ src, mobile, fullHeight = true }) => {
  return (
    <>
      <PosterImage src={src} $desktop={mobile} $fullHeight={fullHeight} />
      {mobile && <PosterImage src={mobile} $mobile />}
    </>
  );
};

export default ImageDisplay;

import React from "react";
import {
  Container,
  Color,
  Hidden,
  TitleBorder,
} from "../styles/components/PDP/Global";
import {
  ContainerOverlay,
  TitleContainer,
  Title,
  SmallHeading,
  HeadingContainer,
  MaskContainer,
  Mask,
  ProductNo,
  HeadingContain,
  SVGContainer,
  FloatText,
  ColorBlock,
  DescriptionTitle,
  TitleFooter,
  ProductNoContainer,
} from "../styles/components/PDP/Description";
import { PlusSvg, ProductSeparator, DescriptionBorder } from "./SVGs";
import { COLOURS } from "../styles/variables/Colors";

const PDPDescription = (sense) => {
  const { id, description } = sense;
  const { chip, copyright, code, secondaryCode } = sense.global;

  return (
    <Container id="sense-description" $blend>
      <ContainerOverlay className="pdp-description">
        <TitleContainer>
          <DescriptionTitle>
            <HeadingContain $margin="bottom" $mobileXs>
              <SmallHeading
                className="scramble-delay"
                $margin="right"
                $width={300}
                $mobile
              >
                <Hidden className="text">
                  {code}
                  <br></br>
                  {secondaryCode}
                </Hidden>
              </SmallHeading>
            </HeadingContain>
            <HeadingContainer>
              <ProductNoContainer>
                <TitleBorder>
                  <SmallHeading className="scramble" $hiddensm>
                    <Hidden className="text">
                      Product <br></br> No.
                    </Hidden>
                  </SmallHeading>
                  <ProductNo>
                    <Color
                      className="scramble"
                      $setcolour={COLOURS.primary.blue}
                    >
                      <Hidden className="text">0{id}</Hidden>
                    </Color>
                  </ProductNo>
                  <SVGContainer $align="flex-end">
                    <ProductSeparator />
                  </SVGContainer>
                </TitleBorder>
              </ProductNoContainer>
              <HeadingContain $direction="column">
                <TitleBorder>
                  <FloatText $top={-10} $hiddenxs>
                    <HeadingContain>
                      <PlusSvg />
                      <SmallHeading className="scramble-delay" $hiddensm>
                        <Hidden className="text">{chip}</Hidden>
                      </SmallHeading>
                    </HeadingContain>
                  </FloatText>
                  <MaskContainer>
                    <Mask />
                    <Title className="scramble" $margin="left" $mobileMargin>
                      <Hidden className="text">{description[0]}</Hidden>
                    </Title>
                  </MaskContainer>
                  <HeadingContain $direction="column" $mobilePadding>
                    <Title className="scramble">
                      <Hidden className="text">{description[1]}</Hidden>
                    </Title>
                    <Title className="scramble" $justify="flex-end" $flex>
                      <Hidden className="text">{description[2]}</Hidden>
                    </Title>
                    <Title className="scramble" $justify="flex-end" $flex>
                      <Hidden className="text">{description[3]}</Hidden>
                    </Title>
                    <Title className="scramble" $justify="flex-start" $flex>
                      <Hidden className="text">{description[4]}</Hidden>
                    </Title>
                  </HeadingContain>
                </TitleBorder>
              </HeadingContain>
            </HeadingContainer>
            <TitleFooter>
              <SmallHeading
                className="scramble-delay"
                $margin="right"
                $width={300}
                $mobile
              >
                <Hidden className="text">
                  {code}
                  <br></br>
                  {secondaryCode}
                </Hidden>
              </SmallHeading>
              <TitleBorder>
                <HeadingContain
                  $justify="flex-start"
                  $align="flex-end"
                  $direction="column"
                  $margin="top"
                  $mobileAlign
                >
                  <ColorBlock className="block" width="200" $margin="left" />
                  <SmallHeading
                    className="scramble-delay"
                    $margin="right"
                    $width={300}
                  >
                    <Hidden className="text">{copyright}</Hidden>
                  </SmallHeading>
                  <SVGContainer $justify="flex-end" $align="flex-end">
                    <DescriptionBorder />
                  </SVGContainer>
                </HeadingContain>
              </TitleBorder>
            </TitleFooter>
          </DescriptionTitle>
        </TitleContainer>
      </ContainerOverlay>
    </Container>
  );
};

export default PDPDescription;

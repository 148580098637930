import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import {
  BREAKPOINTS,
  IPADBREAKPOINTS,
  generateMediaQueries,
  hiddensm,
  hiddenxs,
  mobileXs,
} from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Container = styled.div`
  ${setFlex({ justify: "center", align: "center" })}
  ${setPosition({ position: "relative" })};
  height: ${(props) => props.$height || "250vh"};
  width: 100%;
  mix-blend-mode: difference;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    mix-blend-mode: unset;
    ${setFlex({ direction: "column" })};
    height: fit-content;
  }
`;

export const ContainerOverlay = styled.div`
  ${setFlex({
    justify: "flex-start",
    align: "flex-start",
    direction: "column",
  })};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 10 })};
  min-height: 100vh;
  height: auto;
  width: 100%;
  mix-blend-mode: difference;
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ justify: "space-around" })};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
    min-height: unset;
    height: fit-content;
  }
  @medua (min-width: ${BREAKPOINTS.xxl}px) {
    ${setFlex({ justify: "space-around" })};
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 0;
  text-align: left;
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 1rem;
  line-height: 1rem;
  max-width: 300px;
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 1.25rem`};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const SecondaryParagraph = styled(Paragraph)`
  font-size: 0.6rem;
  line-height: 0.8rem;
  ${(props) =>
    props.$absolute &&
    setPosition({
      position: "absolute",
      bottom: props.$bottom,
      left: props.$left,
    })};
  ${(props) => props.$margin && `margin-${props.$margin}: 1.25rem`};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$mobile && mobileXs};
`;

export const TitleContainer = styled.div`
  text-align: left;
  margin: 4rem 0 2.5rem 2rem;
  @media (max-width: ${BREAKPOINTS.md}px) {
    margin: 5rem 2rem 0 2rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 3rem 0 0 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin: 1.5rem 0 0 0;
    max-width: 95vw;
  }
`;

export const Title = styled.h1`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${COLOURS.primary.white};
  ${generateFont("3.8rem")};
  ${generateLineHeight("3.5rem")};
  margin: 0;
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) => props.$margin && `margin-${props.$margin}: 2.5rem`};
`;

export const SecondaryTitle = styled.h2`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  margin: 0 0 0.9rem 0;
  ${generateFont("2rem")};
  ${generateLineHeight("1.7rem")};
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) => props.$margin && `margin-${props.$margin}: 1.8rem;`};
  ${(props) => props.$hiddensm && hiddensm};
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${generateFont("3rem")};
    ${generateLineHeight("2.8rem")};
  }
`;

export const FooterContainer = styled.div`
  ${setFlex({ align: "center" })};
  width: 100%;
  margin-left: 0.5rem;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    margin-left: 0.8rem;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 95vw;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    margin: 0 0.8rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${setFlex({ align: "flex-start" })};
  }
`;

export const Footer = styled.div`
  ${setFlex({})};
  ${setPosition({ position: "relative" })};
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
  }
`;

export const ThirdContainer = styled.div`
  ${(props) => setFlex({ align: props.$align, direction: props.$direction })};
  flex-grow: 1;
  padding: 1.25rem;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 1rem;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 0.2rem 0.8rem;
  }
`;

export const HeadingContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({})};
  margin-bottom: 0.6rem;
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
  ${(props) =>
    props.$mobileDirection &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      ${setFlex({ direction: "column" })};
      width: 85%;
    }
  `};
`;

export const SymbolContainer = styled.div`
  ${(props) => setFlex({ justify: props.$justify, align: props.$align })};
  > svg {
    margin-right: 0.6rem;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const SVGContainer = styled.div`
  ${setFlex({ align: "flex-end" })};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const Column = styled.div`
  ${setPosition({ position: "relative" })};
  ${(props) =>
    props.$flex &&
    setFlex({ align: props.$align, direction: props.$direction })};
  width: auto;
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  background-color: ${COLOURS.primary.blue};
  min-height: 1.5rem;
  margin-left: -0.6rem;
  max-width: 100%;
`;

export const HeadingContain = styled.div`
  ${(props) =>
    setFlex({ justify: props.$justify, direction: props.$direction })};
  height: auto;
  max-width: 100vw;
  ${setPosition({ position: "relative" })};
  > svg {
    margin-left: 0.6rem;
  }
  ${(props) =>
    props.$mobileColumn &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      width: 60%;
    }
  `};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const TitleHeader = styled.div`
  padding: 0 0 1.8rem 3rem;
  svg {
    margin-right: 0.6rem;
  }
  p {
    padding-top: 0.6rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0;
  }
`;

export const TitleFooter = styled.div`
  ${setFlex({ justify: "flex-start" })};
  width: 100%;
  padding: 1.8rem 0.6rem 0.6rem 0.6rem;
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: fit-content;
  }
  ${hiddensm};
`;

export const SmallHeading = styled(Headline)`
  ${setPosition({ position: "absolute", bottom: "0", left: -20 })};
  margin-bottom: 0.6rem;
  font-size: 0.5rem;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  @media (max-width: ${BREAKPOINTS.xs}px) {
    bottom: unset;
  }
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$mobile && mobileXs};
`;

export const MobileBanner = styled.div`
  ${setFlex({ justify: "center", direction: "column" })};
  margin-top: 2rem;
  padding: 1rem;
  ${mobileXs};
`;

export const PosterContainer = styled.div`
  ${setFlex({ justify: "center" })}
  height: 95vh;
  width: 100vw;
  margin: 4rem 0;
  ${mobileXs};
`;

export const PosterImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

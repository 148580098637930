import React, { useState, useRef, memo } from "react";
import {
  ContainerOverlay,
  Paragraph,
  Hidden,
} from "../styles/components/Pages/Global";
import {
  Container,
  IntroContainer,
  TitleContainer,
  Title,
  SmallHeading,
  HeadingContainer,
  MaskContainer,
  Mask,
  HeadingContain,
  SVGContainer,
  DescriptionTitle,
  Column,
  PosterContainer,
  Poster,
  QRCode,
  TitleFooter,
  VerticalText,
  ColorBlock,
  VisionFooter,
  ParallaxContainer,
  SecondaryTitle,
  FooterWrapper,
  TitleBorder,
  SenseHeadingContainer,
  FloatingColumn,
  FloatingWrapper,
  Link,
  LandingWrapper,
  DisplayTitle,
  Logo,
} from "../styles/components/Pages/Pages";
import gsap from "./Animations";
import {
  DescriptionBorder,
  PosterLeftBorder,
  PlusSvg,
  TechBorder,
  ProductSeparator,
  DisplayBorder,
} from "./SVGs";
import { COLOURS } from "../styles/variables/Colors";
import ImageDisplay from "./Image";
import SenseVideo from "./Video";

const Vision = memo(
  ({
    code,
    secondaryCode,
    copyright,
    description,
    material,
    symbol,
    logo,
    qrcode,
    vision,
    senses,
    audioEnabled,
    toggleAudio,
  }) => {
    const [active, setActive] = useState(false);
    const menuRef = useRef(null);

    const onMouseEnter = () => {
      if (active) return;
      setActive(true);
      const timeline = gsap.timeline({});
      gsap.rescrambleAnimation(menuRef.current, timeline);
      timeline.then(() => setActive(false));
    };

    return (
      <>
        <Container id="vision-display" $minHeight="100vh">
          <ContainerOverlay $justify="center">
            <IntroContainer>
              <LandingWrapper>
                <TitleBorder $splitWidth>
                  <HeadingContainer $margin="left">
                    <HeadingContain>
                      {senses.items
                        ?.filter((sense) => sense.id <= 5)
                        .map((sense, i) => {
                          return <sense.media.symbol key={i} size="medium" />;
                        })}
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer $margin="top" $flex $maxHeight>
                    <HeadingContain
                      $align="center"
                      $margin="right"
                      $mobileMargin
                    >
                      <Link href={qrcode.url} target="_blank">
                        <QRCode
                          className="fade-image"
                          src={qrcode.source}
                          alt={qrcode.alt}
                          loading="lazy"
                          $hiddensm
                        />
                      </Link>
                    </HeadingContain>
                    <MaskContainer $margin="left">
                      <Mask className="mask" width="100" />
                      <DisplayTitle>
                        <Title
                          className="scramble"
                          $setcolour={COLOURS.primary.black}
                          $noWrap
                        >
                          <Hidden className="text">Our vision</Hidden>
                        </Title>
                      </DisplayTitle>
                    </MaskContainer>
                    <HeadingContain $align="center" $margin $hiddenxl>
                      <symbol.icon />
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer
                    $direction="column"
                    $margin="left"
                    $mobileMaxWidth
                  >
                    <TitleFooter $padding>
                      <HeadingContain $direction="column">
                        <SmallHeading
                          className="scramble"
                          $setcolour={COLOURS.primary.white}
                        >
                          <Hidden className="text">{code}</Hidden>
                        </SmallHeading>
                        <SVGContainer>
                          <DisplayBorder />
                        </SVGContainer>
                      </HeadingContain>
                    </TitleFooter>
                    <TitleFooter $flex>
                      <VerticalText>
                        <PlusSvg />
                        <SmallHeading
                          className="scramble"
                          $setcolour={COLOURS.primary.white}
                          $margin="top"
                        >
                          <Hidden className="text">{code}</Hidden>
                        </SmallHeading>
                      </VerticalText>
                      <HeadingContain>
                        <ColorBlock className="block" width="200" />
                        <SmallHeading
                          className="scramble"
                          $setcolour={COLOURS.primary.white}
                          $margin="top"
                          $width={400}
                        >
                          <Hidden className="text">{copyright}</Hidden>
                        </SmallHeading>
                      </HeadingContain>
                    </TitleFooter>
                  </HeadingContainer>
                </TitleBorder>
              </LandingWrapper>
            </IntroContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-text-1" $blend>
          <ContainerOverlay $direction="column" $justify="center" $mobileUnpin>
            <TitleContainer $justify="center" $padding>
              <TitleBorder>
                <DescriptionTitle>
                  <HeadingContainer>
                    <HeadingContain $direction="column">
                      <SecondaryTitle className="scramble">
                        <Hidden className="text">Aging is </Hidden>
                      </SecondaryTitle>
                      <SmallHeading
                        className="scramble"
                        $margin="left"
                        $justify="flex-end"
                        $flex
                        $break
                      >
                        <Hidden className="text">
                          {code}
                          {code}
                        </Hidden>
                      </SmallHeading>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-end"
                        $flex
                      >
                        <Hidden className="text">obsolete. Disease is</Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-end"
                        $flex
                      >
                        <Hidden className="text">a thing </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-start"
                        $flex
                      >
                        <Hidden className="text">of the past. </Hidden>
                      </SecondaryTitle>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-start"
                        $flex
                        $break
                      >
                        <Hidden className="text">
                          {code}
                          {code}
                        </Hidden>
                      </SmallHeading>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-start"
                        $flex
                        $break
                      >
                        <Hidden className="text">{secondaryCode}</Hidden>
                      </SmallHeading>
                      <SecondaryTitle
                        className="scramble"
                        $justify="center"
                        $flex
                      >
                        <Hidden className="text">Productivity is</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
            <VisionFooter>
              <TitleBorder $align>
                <DescriptionTitle>
                  <HeadingContainer>
                    <HeadingContain $direction="column" $margin="right">
                      <HeadingContain
                        $margin="bottom"
                        $align="flex-end"
                        $hiddensm
                      >
                        {senses.items
                          ?.filter((sense) => sense.id <= 5)
                          .map((sense, i) => {
                            return <sense.media.symbol key={i} size="medium" />;
                          })}
                        <SmallHeading
                          className="scramble"
                          $margin="left"
                          $align="flex-end"
                          $flex
                          $break
                          $hiddensm
                        >
                          <Hidden className="text">{code}</Hidden>
                        </SmallHeading>
                      </HeadingContain>
                      <SecondaryTitle className="scramble" $margin="left">
                        <Hidden className="text">at an</Hidden>
                      </SecondaryTitle>
                      <FooterWrapper $align="flex-end" $direction="column">
                        <MaskContainer>
                          <Mask className="mask" width="95" />
                          <SecondaryTitle
                            className="scramble"
                            $margin="right"
                            $setcolour={COLOURS.primary.black}
                            $noWrap
                          >
                            <Hidden className="text">all-time high. </Hidden>
                          </SecondaryTitle>
                        </MaskContainer>
                        <SVGContainer
                          $justify="flex-end"
                          $align="flex-end"
                          $hiddensm
                        >
                          <DescriptionBorder />
                        </SVGContainer>
                      </FooterWrapper>
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </VisionFooter>
          </ContainerOverlay>
        </Container>
        <Container id="vision-sense-1">
          <ContainerOverlay
            $align="center"
            $justify="center"
            $direction="column"
            $mobileUnpin
          >
            <PosterContainer $justify="center" $direction="column">
              <HeadingContainer $justify="center">
                <Column>
                  <Poster $padding>
                    {senses.items
                      ?.filter((sense) => sense.id === 5)
                      .map((sense, i) => {
                        return (
                          <ImageDisplay
                            key={i}
                            src={sense.media.cover.source}
                            mobile={sense.media.poster.source}
                            fullHeight={false}
                          />
                        );
                      })}
                  </Poster>
                  <SVGContainer
                    $right={1}
                    $bottom="0"
                    $align="flex-end"
                    $justify="flex-end"
                    $absolute
                  >
                    <PosterLeftBorder />
                  </SVGContainer>
                </Column>
                <Column $right="0" $absolute>
                  <SmallHeading
                    className="scramble"
                    $justify="flex-end"
                    $flex
                    $hiddensm
                  >
                    <Hidden className="text">
                      {code}
                      {code}
                    </Hidden>
                  </SmallHeading>
                </Column>
              </HeadingContainer>
              <SenseHeadingContainer
                $justify="space-between"
                $direction="row"
                $margin="top"
              >
                <HeadingContain
                  $justify="flex-start"
                  $align="flex-start"
                  $direction="column"
                >
                  <Paragraph className="scramble" $margin="bottom">
                    <Hidden className="text">{description}</Hidden>
                  </Paragraph>
                </HeadingContain>
                {senses.items
                  ?.filter((sense) => sense.id === 5)
                  .map((sense, i) => {
                    return (
                      <HeadingContainer key={i} $height>
                        <HeadingContain>
                          <SmallHeading className="scramble" $margin="left">
                            <Hidden className="text">
                              Product 0{sense.id}: {sense.title}
                            </Hidden>
                          </SmallHeading>
                        </HeadingContain>
                        <HeadingContain>
                          <sense.media.symbol size="medium" />
                        </HeadingContain>
                        <HeadingContain>
                          <symbol.medium />
                        </HeadingContain>
                      </HeadingContainer>
                    );
                  })}
              </SenseHeadingContainer>
            </PosterContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-text-2" $blend>
          <ContainerOverlay $justify="center" $direction="column" $mobileUnpin>
            <TitleContainer $text="center" $justify="center" $padding>
              <TitleBorder>
                <DescriptionTitle $padding>
                  <HeadingContainer $direction="column" $justify="center">
                    <HeadingContain $direction="column" $justify="center">
                      <HeadingContain $justify="flex-start" $margin="bottom">
                        <SVGContainer $padding="top" $hiddensm>
                          <PlusSvg />
                        </SVGContainer>
                      </HeadingContain>
                      <SecondaryTitle
                        className="scramble"
                        $flex
                        $justify="flex-start"
                      >
                        <Hidden className="text">Despite our </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle className="scramble" $margin="left">
                        <Hidden className="text">
                          biotechnological triumphs,
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $margin="left"
                        $justify="flex-end"
                        $flex
                      >
                        <Hidden className="text">we face a</Hidden>
                      </SecondaryTitle>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-end"
                        $flex
                        $absolute
                        $hiddensm
                      >
                        <Hidden className="text">
                          {code}
                          {code}
                          {code}
                          {code}
                          <br></br>
                          {code}
                          {code}
                          {code}
                          {code}
                          {secondaryCode}
                        </Hidden>
                      </SmallHeading>
                      <SecondaryTitle
                        className="scramble"
                        $margin="left"
                        $justify="center"
                        $flex
                      >
                        <Hidden className="text">health crisis</Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-start"
                        $flex
                      >
                        <Hidden className="text"> greater than any</Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $setcolour={COLOURS.primary.blue}
                        $justify="flex-start"
                        $flex
                      >
                        <Hidden className="text">virus: apathy. </Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                    <HeadingContain $justify="flex-end">
                      <ColorBlock className="block" width="200" />
                      <SmallHeading
                        className="scramble"
                        $setcolour={COLOURS.primary.white}
                        $margin="top"
                        $width={400}
                      >
                        <Hidden className="text">{material}</Hidden>
                      </SmallHeading>
                    </HeadingContain>

                    <HeadingContain $justify="flex-end">
                      <SVGContainer $padding="top">
                        <PlusSvg />
                      </SVGContainer>
                    </HeadingContain>
                    <HeadingContain $mobile>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-end"
                        $flex
                      >
                        <Hidden className="text">
                          {code}
                          <br></br>

                          {code}
                        </Hidden>
                      </SmallHeading>
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-sense-2" $minHeight="80vh">
          <ContainerOverlay
            $align="center"
            $justify="center"
            $direction="column"
            $relative
          >
            <ParallaxContainer>
              {senses.items
                ?.filter((sense) => sense.id === 1 || sense.id === 4)
                .map((sense, i) => (
                  <Column
                    key={i}
                    className="parallax"
                    $direction="column"
                    $margin
                    $extra
                  >
                    <HeadingContainer $direction="column" $height>
                      <HeadingContain
                        $justify="space-between"
                        $align="flex-end"
                      >
                        <SmallHeading className="scramble" $margin="bottom">
                          <Hidden className="text">
                            Product 0{sense.id}: {sense.title}
                          </Hidden>
                        </SmallHeading>
                        <HeadingContain $margin="bottom">
                          <sense.media.symbol size="medium" />
                        </HeadingContain>
                      </HeadingContain>
                      <Poster>
                        <ImageDisplay src={sense.media.cover.source} />
                      </Poster>
                      <HeadingContain $margin="top">
                        <SmallHeading className="scramble">
                          <Hidden className="text">
                            {sense.code}
                            {sense.code}
                          </Hidden>
                        </SmallHeading>
                      </HeadingContain>
                      <HeadingContain>
                        <SmallHeading className="scramble">
                          <Hidden className="text">{sense.code}</Hidden>
                        </SmallHeading>
                      </HeadingContain>
                    </HeadingContainer>
                  </Column>
                ))}
            </ParallaxContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-text-3" $blend>
          <ContainerOverlay $justify="center" $direction="column" $mobileUnpin>
            <TitleContainer $text="center" $padding>
              <TitleBorder>
                <DescriptionTitle>
                  <HeadingContainer>
                    <HeadingContain $direction="column" $justify="center">
                      <SecondaryTitle className="scramble" $margin="left">
                        <Hidden className="text">
                          Over time, our capacity for
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle className="scramble" $margin="left">
                        <Hidden className="text">
                          sensation — a biological
                        </Hidden>
                      </SecondaryTitle>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-start"
                        $flex
                        $absolute
                        $left={-50}
                        $break
                        $hiddensm
                      >
                        <Hidden className="text">
                          {" "}
                          {secondaryCode}
                          {secondaryCode}
                          <br></br>
                          {secondaryCode}
                          {secondaryCode}
                          <br></br>
                          {secondaryCode}
                          {secondaryCode}
                          <br></br>
                          {secondaryCode}
                          {secondaryCode}
                          <br></br>
                        </Hidden>
                      </SmallHeading>
                      <SecondaryTitle className="scramble">
                        <Hidden className="text">
                          system present in most living
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle className="scramble" $margin="left">
                        <Hidden className="text">
                          beings — has been blunted by
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-start"
                      >
                        <Hidden className="text">overstimulation.</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
            <TitleContainer $justify="flex-end" $padding>
              <TitleBorder>
                <DescriptionTitle $direction="column">
                  <HeadingContainer>
                    <HeadingContain $direction="row">
                      <HeadingContain
                        $justify="center"
                        $align="center"
                        $margin="right"
                      >
                        {senses.items
                          ?.filter((sense) => sense.id <= 5)
                          .map((sense, i) => {
                            return <sense.media.symbol key={i} size="medium" />;
                          })}
                      </HeadingContain>
                      <SecondaryTitle
                        className="scramble"
                        $setcolour={COLOURS.primary.blue}
                      >
                        <Hidden className="text">we felt a lot</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer>
                    <SmallHeading
                      className="scramble"
                      $justify="flex-end"
                      $margin="top"
                      $flex
                    >
                      <Hidden className="text">
                        {code}
                        {code}
                      </Hidden>
                    </SmallHeading>
                    <HeadingContain $direction="row">
                      <HeadingContain
                        $justify="flex-end"
                        $align="flex-end"
                        $margin="right"
                      >
                        <SVGContainer $hiddensm>
                          <ProductSeparator />
                        </SVGContainer>
                      </HeadingContain>
                      <SecondaryTitle
                        className="scramble"
                        $setcolour={COLOURS.primary.blue}
                        $noTransform
                      >
                        <Hidden className="text">DURING THE 2020s.</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-video" $align="flex-start" $minHeight="200vh">
          <SenseVideo
            audioEnabled={audioEnabled}
            toggleAudio={toggleAudio}
            videoSrc={vision.source}
            videoPoster={vision.poster}
            pdp={true}
          />
        </Container>
        <Container id="vision-text-4" $blend>
          <ContainerOverlay $justify="center" $direction="column" $mobileUnpin>
            <TitleContainer $text="center" $justify="center" $padding>
              <TitleBorder>
                <DescriptionTitle
                  $direction="column"
                  $justify="flex-end"
                  $padding
                >
                  <HeadingContainer>
                    <SmallHeading
                      className="scramble"
                      $justify="flex-end"
                      $flex
                      $absolute
                      $left={-100}
                      $break
                      $hiddenxs
                    >
                      <Hidden className="text">
                        {secondaryCode} {secondaryCode}
                        <br></br>
                        {secondaryCode} {secondaryCode}
                        <br></br>
                        {secondaryCode} {secondaryCode}
                        <br></br>
                        {secondaryCode} {secondaryCode}
                        <br></br>
                      </Hidden>
                    </SmallHeading>
                    <HeadingContain $direction="column">
                      <SecondaryTitle
                        className="scramble"
                        $justify="center"
                        $margin="left"
                      >
                        <Hidden className="text">
                          The dulling of our senses is
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="center"
                        $margin="left"
                      >
                        <Hidden className="text">a defense mechanism. </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-start"
                      >
                        <Hidden className="text">It renders us</Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle className="scramble" $justify="flex-end">
                        <Hidden className="text">
                          supremely resilient, but can{" "}
                        </Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer>
                    <HeadingContain
                      $justify="flex-end"
                      $align="flex-end"
                      $direction="column"
                      $margin="right"
                    >
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-start"
                        $flex
                      >
                        <Hidden className="text">result in symptoms </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-end"
                        $flex
                      >
                        <Hidden className="text">
                          ranging from nausea to{" "}
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $setcolour={COLOURS.primary.blue}
                        $justify="flex-end"
                        $margin="left"
                        $flex
                      >
                        <Hidden className="text">social withdrawal.</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer
                    $justify="flex-end"
                    $align="flex-end"
                    $margin="top"
                  >
                    <HeadingContain>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-start"
                        $flex
                        $hiddensm
                      >
                        <Hidden className="text">{secondaryCode}</Hidden>
                      </SmallHeading>
                    </HeadingContain>
                    <HeadingContain $margin="top">
                      {senses.items
                        ?.filter((sense) => sense.id <= 5)
                        .map((sense, i) => {
                          return <sense.media.symbol key={i} size="medium" />;
                        })}
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-sense-3">
          <ContainerOverlay
            $align="center"
            $justify="center"
            $direction="column"
            $mobileUnpin
          >
            {senses.items
              ?.filter((sense) => sense.id === 3)
              .map((sense, i) => {
                return (
                  <FloatingWrapper key={i}>
                    <FloatingColumn $align="center" $direction="column" $margin>
                      <HeadingContainer $direction="row" $height>
                        <HeadingContain $margin="right">
                          <sense.media.symbol size="medium" />
                        </HeadingContain>
                        <HeadingContainer $direction="column" $height>
                          <HeadingContain $justify="flex-end" $align="flex-end">
                            <SmallHeading className="scramble" $margin="bottom">
                              <Hidden className="text">
                                Product 0{sense.id}: {sense.title}
                              </Hidden>
                            </SmallHeading>
                          </HeadingContain>
                          <SVGContainer
                            $align="flex-end"
                            $justify="flex-end"
                            $hiddensm
                          >
                            <PosterLeftBorder />
                          </SVGContainer>
                        </HeadingContainer>
                      </HeadingContainer>
                    </FloatingColumn>
                    <PosterContainer $justify="center" $direction="column">
                      <HeadingContainer>
                        <Column
                          $justify="center"
                          $align="flex-start"
                          $direction="row"
                          $margin
                          $mobileColumn
                        >
                          <HeadingContainer $margin="right" $hiddensm>
                            <SVGContainer>
                              <HeadingContain>
                                <PlusSvg />
                              </HeadingContain>
                            </SVGContainer>
                          </HeadingContainer>
                          <HeadingContainer
                            className="here"
                            $direction="column"
                            $height
                          >
                            <Poster>
                              <ImageDisplay src={sense.media.cover.source} />
                            </Poster>
                            <HeadingContain $margin="top">
                              <SmallHeading className="scramble">
                                <Hidden className="text">
                                  {sense.code}
                                  {sense.code}
                                </Hidden>
                              </SmallHeading>
                            </HeadingContain>
                            <HeadingContain>
                              <SmallHeading className="scramble">
                                <Hidden className="text">{sense.code}</Hidden>
                              </SmallHeading>
                            </HeadingContain>
                          </HeadingContainer>
                        </Column>
                      </HeadingContainer>
                    </PosterContainer>
                  </FloatingWrapper>
                );
              })}
          </ContainerOverlay>
        </Container>
        <Container id="vision-text-5" $blend>
          <ContainerOverlay
            $justify="center"
            $align="center"
            $direction="column"
            $mobileUnpin
          >
            <TitleContainer $text="center" $align="center" $padding>
              <TitleBorder>
                <DescriptionTitle $direction="column">
                  <HeadingContainer>
                    <HeadingContain $direction="column" $justify="flex-end">
                      <SecondaryTitle className="scramble">
                        <Hidden className="text">
                          By increasing your ability
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle className="scramble" $margin="left">
                        <Hidden className="text">to form social</Hidden>
                      </SecondaryTitle>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-start"
                        $flex
                        $absolute
                        $hiddensm
                      >
                        <Hidden className="text">
                          {secondaryCode}
                          {secondaryCode}
                          {secondaryCode}
                          {secondaryCode}
                          <br></br>
                          {secondaryCode}
                          {secondaryCode}
                        </Hidden>
                      </SmallHeading>
                      <SecondaryTitle className="scramble" $justify="flex-end">
                        <Hidden className="text">
                          connections and develop a
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="center"
                        $margin="left"
                      >
                        <Hidden className="text">
                          sense of identity independent{" "}
                        </Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer $direction="column">
                    <HeadingContain
                      $direction="row"
                      $justify="center"
                      $align="center"
                    >
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-start"
                      >
                        <Hidden className="text">from your job,</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer $direction="column">
                    <HeadingContain
                      $direction="row"
                      $justify="flex-start"
                      $align="flex-end"
                    >
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-start"
                      >
                        <Hidden className="text">SENSE helps renew your</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
            <TitleContainer $justify="flex-end">
              <TitleBorder $margin>
                <DescriptionTitle $direction="column" $margin="right" $padding>
                  <HeadingContainer $justify="flex-end">
                    <SVGContainer $padding="bottom" $hiddenxl>
                      <PlusSvg />
                    </SVGContainer>
                  </HeadingContainer>
                  <HeadingContainer>
                    <HeadingContain
                      $justify="flex-end"
                      $align="flex-start"
                      $margin="right"
                      $hiddenxl
                    >
                      <symbol.medium />
                    </HeadingContain>
                    <HeadingContainer $justify="flex-end" $direction="column">
                      <HeadingContain $direction="column">
                        <MaskContainer>
                          <Mask className="mask" width="100" />
                          <SecondaryTitle
                            className="scramble"
                            $setcolour={COLOURS.primary.black}
                            $alignCenter
                          >
                            <Hidden className="text">zest for life.</Hidden>
                          </SecondaryTitle>
                        </MaskContainer>
                      </HeadingContain>
                      <HeadingContain
                        $align="flex-start"
                        $justify="center"
                        $direction="column"
                        $margin="top"
                        $fullWidth
                      >
                        <SVGContainer $padding="top" $hiddensm>
                          <TechBorder />
                        </SVGContainer>
                        <SmallHeading
                          className="scramble"
                          $justify="flex-start"
                          $margin="top"
                          $flex
                          $absolute
                          $left={-50}
                          $hiddenxs
                        >
                          <Hidden className="text">
                            {secondaryCode}
                            {secondaryCode}
                            <br></br>
                            {secondaryCode}
                          </Hidden>
                        </SmallHeading>
                      </HeadingContain>
                      <HeadingContain $align="flex-end" $direction="column">
                        <SVGContainer $padding="top" $hiddensm>
                          <PlusSvg />
                        </SVGContainer>
                      </HeadingContain>
                    </HeadingContainer>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-sense-4">
          <ContainerOverlay
            $align="center"
            $justify="center"
            $direction="column"
            $mobileUnpin
          >
            <PosterContainer $justify="center" $direction="row">
              {senses.items
                ?.filter((sense) => sense.id === 2)
                .map((sense, i) => {
                  return (
                    <Column key={i} $direction="column" $margin>
                      <HeadingContainer $direction="column" $height>
                        <HeadingContain
                          $justify="space-between"
                          $align="flex-end"
                          $mobilePadding
                        >
                          <SmallHeading className="scramble" $margin="bottom">
                            <Hidden className="text">
                              Product 0{sense.id}: {sense.title}
                            </Hidden>
                          </SmallHeading>
                          <HeadingContain $margin="bottom">
                            <sense.media.symbol size="medium" />
                          </HeadingContain>
                        </HeadingContain>
                        <Poster>
                          <ImageDisplay
                            src={sense.media.cover.source}
                            mobile={sense.media.poster.source}
                          />
                          <SVGContainer
                            $right={1}
                            $bottom="0"
                            $align="flex-end"
                            $justify="flex-end"
                            $padding="right"
                            $absolute
                            $hiddensm
                          >
                            <PosterLeftBorder />
                          </SVGContainer>
                        </Poster>
                        <HeadingContain $margin="top">
                          <SmallHeading className="scramble">
                            <Hidden className="text">
                              {sense.code}
                              {sense.code}
                            </Hidden>
                          </SmallHeading>
                        </HeadingContain>
                        <HeadingContain>
                          <SmallHeading className="scramble">
                            <Hidden className="text">{sense.code}</Hidden>
                          </SmallHeading>
                        </HeadingContain>
                      </HeadingContainer>
                    </Column>
                  );
                })}
            </PosterContainer>
          </ContainerOverlay>
        </Container>
        <Container id="vision-text-6" $blend $mobileUnblend>
          <ContainerOverlay $justify="center" $direction="column" $mobileUnpin>
            <TitleContainer $justify="center" $padding>
              <TitleBorder>
                <DescriptionTitle $direction="column">
                  <HeadingContainer $direction="column" $justify="center">
                    <HeadingContain $justify="flex-start" $margin="bottom">
                      <SVGContainer $padding="top" $hiddensm>
                        <PlusSvg />
                      </SVGContainer>
                    </HeadingContain>
                    <HeadingContain $direction="column" $justify="flex-end">
                      <SecondaryTitle
                        className="scramble"
                        $margin="left"
                        $flex
                        $justify="flex-start"
                      >
                        <Hidden className="text">SENSE Synthetic </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $margin="left"
                        $flex
                        $justify="center"
                      >
                        <Hidden className="text">
                          Assistants are empathic
                        </Hidden>
                      </SecondaryTitle>
                      <SmallHeading
                        className="scramble"
                        $justify="flex-start"
                        $flex
                        $absolute
                        $right={-300}
                        $hiddensm
                      >
                        <Hidden className="text">
                          {" "}
                          {secondaryCode}
                          {secondaryCode}
                          {secondaryCode}
                          <br></br>
                          {secondaryCode}
                          {secondaryCode}
                          {secondaryCode}
                          <br></br>
                          {secondaryCode}
                          {secondaryCode}
                          {secondaryCode}
                        </Hidden>
                      </SmallHeading>
                      <SecondaryTitle
                        className="scramble"
                        $justify="center"
                        $flex
                      >
                        <Hidden className="text"> microchips that</Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle className="scramble">
                        <Hidden className="text">
                          {" "}
                          temporarily grant access{" "}
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle className="scramble" $margin="left">
                        <Hidden className="text">to the full spectrum</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer>
                    <HeadingContain $direction="column" $justify="flex-end">
                      <SecondaryTitle
                        className="scramble"
                        $justify="center"
                        $flex
                      >
                        <Hidden className="text">
                          of sight, smell, taste,
                        </Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $margin="left"
                        $flex
                        $justify="flex-start"
                      >
                        <Hidden className="text">sound, and touch — and</Hidden>
                      </SecondaryTitle>
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-end"
                        $flex
                        $setcolour={COLOURS.primary.blue}
                      >
                        <Hidden className="text">their resulting</Hidden>
                      </SecondaryTitle>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer $direction="column" $margin="bottom">
                    <HeadingContain
                      $direction="row"
                      $justify="flex-start"
                      $align="flex-end"
                    >
                      <SecondaryTitle
                        className="scramble"
                        $justify="flex-end"
                        $flex
                        $setcolour={COLOURS.primary.blue}
                      >
                        <Hidden className="text">emotions.</Hidden>
                      </SecondaryTitle>
                      <HeadingContain $hiddensm $margin="bottom">
                        {senses.items
                          ?.filter((sense) => sense.id <= 5)
                          .map((sense, i) => {
                            return <sense.media.symbol key={i} size="medium" />;
                          })}
                      </HeadingContain>
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer $justify="center" $margin="top">
                    <HeadingContain $direction="column" $click $hiddensm>
                      <MaskContainer>
                        <Mask className="mask" width="100" />
                        <SecondaryTitle
                          ref={menuRef}
                          onMouseEnter={onMouseEnter}
                          className="scramble reset"
                          $setcolour={COLOURS.primary.black}
                          $alignCenter
                        >
                          <Hidden className="text">
                            Experience the senses
                          </Hidden>
                        </SecondaryTitle>
                      </MaskContainer>
                    </HeadingContain>
                    <HeadingContain $mobile>
                      <Logo
                        src="https://static.highsnobiety.com/dato/40484/1707929095-sense-logo-white.png"
                        alt={logo.alt}
                      />
                    </HeadingContain>
                  </HeadingContainer>
                </DescriptionTitle>
              </TitleBorder>
            </TitleContainer>
          </ContainerOverlay>
        </Container>
      </>
    );
  }
);

export default Vision;

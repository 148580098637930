import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import {
  BREAKPOINTS,
  IPADBREAKPOINTS,
  generateMediaQueries,
  hiddenxl,
  hiddensm,
  hiddenxs,
  mobile,
  mobileXs,
} from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Container = styled.div`
  ${setFlex({ justify: "center", align: "center" })}
  ${setPosition({ position: "relative" })};
  height: ${(props) => props.$height || "250vh"};
  width: 100%;
  mix-blend-mode: difference;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    mix-blend-mode: unset;
    ${setFlex({ direction: "column" })};
    height: fit-content;
  }
`;

export const ContainerOverlay = styled.div`
  ${setFlex({
    justify: "flex-start",
    align: "flex-start",
    direction: "column",
  })};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 10 })};
  min-height: 100vh;
  height: auto;
  width: 100%;
  mix-blend-mode: difference;
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ justify: "space-around" })};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
    min-height: unset;
    height: fit-content;
  }
  @medua (min-width: ${BREAKPOINTS.xxl}px) {
    ${setFlex({ justify: "space-around" })};
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 1rem;
  line-height: 1rem;
  max-width: 400px;
  margin: 0;
  ${(props) =>
    props.$margin &&
    `
  margin-${props.$margin}: 1.25rem
    @media (max-width: ${BREAKPOINTS.xs}px) {
      margin: 0;
    }
  `};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${generateFont("2rem")};
    ${generateLineHeight("1.7rem")};
  }
`;

export const SecondaryParagraph = styled(Paragraph)`
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  ${(props) => props.$margin && `margin-${props.$margin}: 1.25rem`};
  ${(props) => props.$hiddensm && hiddensm};
`;

export const TitleContainer = styled.div`
  text-align: left;
  margin: 4rem 0 2.5rem 2rem;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    margin-bottom: 0;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    margin: 5rem 2rem 0 2rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 3rem 0 0 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin: 2rem 0 0 0;
    max-width: 95vw;
  }
`;

export const Title = styled.h1`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${COLOURS.primary.white};
  ${generateFont("4rem")};
  ${generateLineHeight("3.7rem")};
  margin: 0;
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) => props.$margin && `margin-${props.$margin}: 2.5rem`};
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const FooterContainer = styled.div`
  ${setFlex({ align: "center" })};
  width: 100%;
  margin: 1.25rem 0 0 0.5rem;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    margin: 0 0 0 0.8rem;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 95vw;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    margin: 0 0.8rem;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0;
  }
`;

export const Footer = styled.div`
  ${setFlex({ justify: "center" })};
  ${setPosition({ position: "relative" })};
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
  }
`;

export const ThirdContainer = styled.div`
  padding: 1.9rem;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 1rem;
  }
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const SmallHeading = styled(Headline)`
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  ${(props) => props.$width && `max-width: ${props.$width}px`};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
`;

export const HeadingContain = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${(props) =>
    props.$padding &&
    `
    padding-${props.$padding}: 10px;
    @media (max-width: ${BREAKPOINTS.xs}px) {
      padding-${props.$padding}: 2rem;
    }
  `};
  height: auto;
  ${setPosition({ position: "relative" })};
  > svg {
    margin-left: 0.6rem;
  }
  ${(props) =>
    props.$mobileRow &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      ${setFlex({ direction: "row" })};
    }
  `};
  ${(props) => props.$hiddenxl && hiddenxl};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$mobile && mobile};
`;

export const HeadingContainer = styled.div`
  ${(props) =>
    setFlex({ justify: props.$justify, direction: props.$direction })};
  ${setPosition({ position: "relative" })};
  margin-bottom: 0.6rem;
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
`;

export const SymbolContainer = styled.div`
  ${setFlex({})};
  margin-bottom: 0.6rem;
  > svg {
    margin-right: 0.6rem;
  }
`;

export const SVGContainer = styled.div`
  ${(props) => setFlex({ justify: props.$justify, align: props.$align })};
  ${(props) => props.$absolute && setPosition({ position: "absolute" })};
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const TitleFooter = styled.div`
  ${setFlex({ direction: "column" })};
  width: 100%;
  padding: 0.6rem;
  ${(props) => props.$hiddensm && hiddensm};
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  background-color: ${COLOURS.primary.blue};
  min-height: 25px;
  margin-left: -0.6rem;
  max-width: 100%;
`;

export const Column = styled.div`
  ${setPosition({ position: "relative" })};
  width: auto;
`;

export const SecondaryTitle = styled.h2`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${COLOURS.primary.white};
  ${generateFont("2rem")};
  ${generateLineHeight("1.7rem")};
  margin: 0 0 0.6rem 0;
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) => props.$margin && `margin-${props.$margin}: 1.9rem`};
  ${(props) =>
    props.$mobileMargin &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      margin-left: 0.6rem;
    }
  `};
  ${(props) => props.$hiddensm && hiddensm};
`;

export const MobileBanner = styled.div`
  ${setFlex({ justify: "center", direction: "column" })};
  padding: 1.5rem;
  mix-blend-mode: difference;
  ${mobileXs};
`;

export const PosterContainer = styled.div`
  ${setFlex({ justify: "center" })}
  height: 95vh;
  width: 100vw;
  margin: 4rem 0;
  ${mobileXs};
`;

export const PosterImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

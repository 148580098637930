import THREE from "../../Three";
import gsap from "../../../components/Animations";
import {
  createAnimations,
  audioPlayer,
  setCart,
  updateRatios,
  mm,
} from "./Modules";

export default class Checkout {
  constructor(scene, activeSense, renderer) {
    this.scene = scene;
    this.renderer = renderer;
    this.camera = scene.userData.camera;
    this.element = scene.userData.element;
    this.canvas = renderer.domElement;
    this.fov = this.camera.fov;
    this.ratio = 0.75;

    this.activeSense = activeSense;

    this.id = 3;
    this.sectionActive = false;

    this.environment = null;
    this.ctx = gsap.context(() => {});

    this.checkout = document.getElementById("section-checkout");
    this.wrapper = document.getElementById("wrapper");

    this.scene.userData.constructor = this;

    this.onResize = this.onResize.bind(this);

    this.initScene();
  }

  initScene() {
    const mediaMatcher = mm();
    this.context = mediaMatcher.contexts[0].conditions;

    this.scene.background = new THREE.Color(0x7a7a7a);
    this.scene.fog = new THREE.Fog(0x7a7a7a, 250, 1000);

    this.primaryLight = new THREE.PointLight(0xffffff, 180, 2000, 0.8);
    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.1 * Math.PI);

    this.primaryLight.position.set(-75, 150, 0);

    this.primaryLight.shadow.bias = -0.005;
    this.primaryLight.castShadow = true;

    this.scene.add(this.primaryLight, this.ambientLight);

    this.camera.position.x = 0;
    this.camera.position.z = 75;
  }

  setClasses(plpInstance, pdpInstance, pageInstance) {
    this.PLP = plpInstance;
    this.PDP = pdpInstance;
    this.Pages = pageInstance;
  }

  updateCheckoutScene() {
    window.scrollTo(0, 0);
    this.moveSceneChildren();

    setCart();

    this.PLP.disposePLPScene();
    this.PDP.disposePDPScene();
    this.Pages.disposePagesScene();
    this.activeSense(0);

    this.createCheckoutEventListeners();
    this.onResize();

    this.videoTl = createAnimations(this);
  }

  disposeCheckoutScene() {
    this.removeCheckoutEventListeners();

    if (this.environment !== null) {
      this.PLP.moveItemsBetweenScenes(
        this.scene,
        this.PLP.scene,
        this.environment
      );
    }
    this.ctx.revert();

    audioPlayer(this, false);
    this.videoTl && this.videoTl.kill();
  }

  moveSceneChildren() {
    for (const child of this.PLP.scene.children) {
      if (child.isGroup && child.name === "Environment") {
        this.PLP.scene.remove(child);
        this.scene.add(child);
        this.environment = child;
      }
    }
  }

  createCheckoutEventListeners() {
    window.addEventListener("resize", this.onResize);
  }

  removeCheckoutEventListeners() {
    window.removeEventListener("resize", this.onResize);
  }

  render() {
    if (!this.sectionActive) return;
  }

  onResize() {
    updateRatios(this);
  }
}

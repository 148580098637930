import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import {
  BREAKPOINTS,
  generateMediaQueries,
  IPADBREAKPOINTS,
  hiddensm,
  hiddenmd,
} from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Container = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "relative" })};
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "center", align: "flex-end" })};
    margin: 0;
  }
`;

export const ContainerOverlay = styled.div`
  ${setFlex({
    justify: "center",
    align: "flex-start",
    direction: "column",
  })};
  height: auto;
  width: 100%;
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    margin: 0 0 8rem 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0 0 2rem 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin: 0 0 8rem 0;
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: ${generateFont("0.5rem")};
  line-height: ${generateLineHeight("0.5rem")};
  margin: 0;
  text-align: left;
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.6rem;
  line-height: 0.6rem;
  max-width: 300px;
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 1.25rem`};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    color: ${COLOURS.primary.white};
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    color: ${COLOURS.primary.white};
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    ${generateFont("0.5rem", false, "xxl")};
    ${generateLineHeight("0.5rem", false, "xxl")};
  }
`;

export const TitleContainer = styled.div`
  text-align: left;
  margin: 0 0 1rem 1rem;
  @media (max-width: ${BREAKPOINTS.xxxs}px) {
    margin: 0 1rem 0.5rem 1rem;
  }
`;

export const Title = styled.h1`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: ${generateFont("3.4rem")};
  line-height: ${generateLineHeight("3.1rem")};
  margin: 0;
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) => props.$margin && `margin-${props.$margin}: 2.5rem`};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    color: ${COLOURS.primary.white};
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    color: ${COLOURS.primary.white};
  }
`;

export const FooterContainer = styled.div`
  ${setFlex({ align: "center" })};
  width: 100%;
  margin: 1.25rem;
  ${(props) => props.$hiddenmd && hiddenmd};
`;

export const SmallHeading = styled(Headline)`
  color: ${COLOURS.primary.black};
  ${(props) => props.$width && `max-width: ${props.$width}px`};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
  margin: 0;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    color: ${COLOURS.primary.white};
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    color: ${COLOURS.primary.white};
  }
`;

export const HeadingContain = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${(props) => props.$padding && `padding-${props.$padding}: 0.3rem`};
  ${(props) =>
    props.$bullet &&
    `
    &:not(.active) {
      opacity: 0.2;
      @media (max-width: ${BREAKPOINTS.sm}px) {
        display: none;
      }
    }
  `};
  height: auto;
  ${setPosition({ position: "relative" })};
  > svg {
    margin-left: 0.6rem;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const HeadingContainer = styled.div`
  ${(props) =>
    setFlex({ justify: props.$justify, direction: props.$direction })};
  ${setPosition({ position: "relative" })};
  margin-bottom: 0.6rem;
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
`;

export const SVGContainer = styled.div`
  ${(props) => setFlex({ justify: props.$justify, align: props.$align })};
  ${(props) => props.$absolute && setPosition({ position: "absolute" })};
  filter: invert(1);
  @media (max-width: ${BREAKPOINTS.sm}px) {
    filter: invert(0);
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    filter: invert(0);
  }
`;

export const TitleFooter = styled.div`
  ${setFlex({ direction: "column" })};
  width: 100%;
  padding: 0.6rem;
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  background-color: ${COLOURS.primary.blue};
  min-height: 1.5rem;
  margin: -0.6rem 0 0 -0.6rem;
  max-width: 100%;
`;

import styled from "styled-components";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Wrapper = styled.div`
  ${setPosition({ position: "relative" })};
`;

export const Container = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "relative" })};
  height: ${(props) => props.$height || "200vh"};
  width: 100%;
  ${(props) => props.$blend && "mix-blend-mode: difference"};
  ${(props) => props.$index && `z-index: ${props.$index}`};
`;

export const ContainerOverlay = styled.div`
  max-height: 100vh;
  max-width: 100vw;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.$blend &&
    `
        align-items: center; 
        mix-blend-mode: difference
    `};
  ${(props) =>
    props.$blend &&
    `
        align-items: center; 
        mix-blend-mode: difference
    `};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 999 })};
  ${(props) => props.$fixed && setPosition({ position: "fixed", zIndex: 99 })};
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  display: none;
  &.active {
    display: block;
  }
  ${(props) => props.$loader && `background-color: ${COLOURS.primary.black}`};
`;

export const Color = styled.div`
  color: ${(props) => props.$setcolour || COLOURS.primary.black};
`;

export const TransitionContainer = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: -1000 })};
  background-color: ${COLOURS.primary.black};
  opacity: 1;
  height: 0;
  width: 0;
  &.active {
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 9999;
  }
`;

export const SceneContainer = styled.div`
  ${setPosition({ position: "relative" })};
  width: 100%;
  height: 100%;
  display: none;
  overflow: hidden;
  &.active {
    display: block;
  }
`;

export const Scene = styled.div`
  ${setPosition({ position: "fixed", top: "0", left: "0" })};
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  &.back {
    z-index: -1;
  }
`;

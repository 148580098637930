import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Container = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "relative" })};
  height: ${(props) => props.$height || "200vh"};
  width: 100%;
  ${(props) => props.$blend && "mix-blend-mode: difference"};
`;

export const ContainerOverlay = styled.div`
  height: ${(props) => (props.$fullHeight ? "100%" : "auto")};
  ${(props) => props.$fullHeight && "max-height: 100vh"};
  width: 100%;
  ${(props) =>
    props.$blend &&
    `
        align-items: center; 
        mix-blend-mode: difference
    `};
  ${(props) => props.$fixed && setPosition({ position: "fixed", zIndex: 99 })};
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
`;

export const Wrapper = styled.div`
  ${setPosition({ position: "relative" })};
`;

export const AppWrapper = styled.div`
  ${setPosition({ position: "relative" })};
  overflow-x: hidden;
  min-height: 100vh;
  &.hovering {
    cursor: pointer;
  }
  &.active {
    height: 100vh;
    overflow: hidden;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Hidden = styled.span`
  opacity: 0;
  pointer-events: none;
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: ${generateFont("0.5rem")};
  line-height: ${generateLineHeight("0.5rem")};
  max-width: 300px;
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 1.25rem`};
`;

import React from "react";
import { Hidden, TitleBorder } from "../styles/components/PDP/Global";
import {
  Container,
  ContainerOverlay,
  TitleContainer,
  Title,
  Paragraph,
  SecondaryParagraph,
  FooterContainer,
  Footer,
  ThirdContainer,
  SecondaryTitle,
  SmallHeading,
  HeadingContain,
  HeadingContainer,
  SymbolContainer,
  SVGContainer,
  TitleFooter,
  ColorBlock,
  Column,
  MobileBanner,
  PosterImage,
  PosterContainer,
} from "../styles/components/PDP/Tech";
import { COLOURS } from "../styles/variables/Colors";
import { TechBorder, TechSeparator, PlusSvg } from "./SVGs";

const PDPTech = (sense) => {
  const { title, code, secondaryCode, banner, media } = sense;
  const { symbol, tech, effects, copyright, disclaimer } = sense.global;

  return (
    <Container id="sense-tech" $height="300vh" $blend>
      <ContainerOverlay
        $justify="center"
        $align="flex-start"
        $direction="column"
        $blend
      >
        <TitleContainer className="pdp-tech">
          <TitleBorder>
            <HeadingContain $justify="center" $hiddenxl>
              <SymbolContainer>
                <symbol.small />
              </SymbolContainer>
              <SymbolContainer>
                <media.symbol size="small" />
              </SymbolContainer>
            </HeadingContain>
            <HeadingContain
              $justify="flex-start"
              $align="flex-start"
              $direction="column"
              $mobile
            >
              <SmallHeading className="scramble" $margin="left">
                <Hidden className="text">
                  {secondaryCode}
                  {secondaryCode}
                  <br></br>
                  {secondaryCode}
                </Hidden>
              </SmallHeading>
            </HeadingContain>
            <HeadingContain $direction="column">
              <Title className="scramble">
                <Hidden className="text">Tech Specs</Hidden>
              </Title>
              <Title className="scramble" $justify="flex-end" $flex $hiddenxs>
                <Hidden className="text">{title} Empathic</Hidden>
              </Title>
              <Title
                className="scramble"
                $justify="flex-end"
                $margin="right"
                $flex
                $hiddenxs
              >
                <Hidden className="text">Chip</Hidden>
              </Title>
            </HeadingContain>
            <TitleFooter $hiddensm>
              <HeadingContainer>
                <HeadingContain
                  $justify="flex-start"
                  $align="flex-start"
                  $direction="column"
                >
                  <SmallHeading className="scramble" $margin="left">
                    <Hidden className="text">{secondaryCode}</Hidden>
                  </SmallHeading>
                  <SVGContainer $justify="flex-end" $align="flex-end" $absolute>
                    <TechBorder />
                  </SVGContainer>
                </HeadingContain>
              </HeadingContainer>
              <HeadingContain
                $justify="flex-start"
                $align="flex-start"
                $direction="row"
                $padding="top"
              >
                <Column>
                  <SymbolContainer>
                    <PlusSvg />
                  </SymbolContainer>
                </Column>
                <Column>
                  <ColorBlock className="block" width="200" />
                  <SmallHeading
                    className="scramble-delay"
                    $margin="top"
                    $width={300}
                  >
                    <Hidden className="text">{effects}</Hidden>
                  </SmallHeading>
                </Column>
              </HeadingContain>
            </TitleFooter>
          </TitleBorder>
        </TitleContainer>
        <FooterContainer className="footer">
          <TitleBorder $align="center" $flex $full $lateral>
            <ColorBlock className="block" width="600" />
            <Footer>
              <ThirdContainer $hiddenxs>
                <HeadingContainer $direction="column">
                  <HeadingContain>
                    <SecondaryTitle className="scramble" $hiddensm>
                      <Hidden className="text">01.</Hidden>
                    </SecondaryTitle>
                    <SVGContainer $align="flex-end" $hiddenxs>
                      <TechSeparator />
                    </SVGContainer>
                  </HeadingContain>
                  <HeadingContain $direction="column" $padding="top">
                    <SecondaryTitle className="scramble">
                      <Hidden className="text">{tech[0].title}</Hidden>
                    </SecondaryTitle>
                    <SecondaryTitle className="scramble" $margin="left">
                      <Hidden className="text">{tech[0].subtitle}</Hidden>
                    </SecondaryTitle>
                  </HeadingContain>
                </HeadingContainer>
                <Paragraph className="scramble" $margin="left">
                  <Hidden className="text">{tech[0].text}</Hidden>
                </Paragraph>
              </ThirdContainer>
              <ThirdContainer>
                <HeadingContainer $direction="column">
                  <HeadingContain>
                    <SecondaryTitle className="scramble" $hiddensm>
                      <Hidden className="text">02.</Hidden>
                    </SecondaryTitle>
                    <SVGContainer $align="flex-end">
                      <TechSeparator />
                    </SVGContainer>
                  </HeadingContain>
                  <HeadingContain $direction="column" $padding="top">
                    <SecondaryTitle className="scramble">
                      <Hidden className="text">{tech[1].title}</Hidden>
                    </SecondaryTitle>
                    <SecondaryTitle className="scramble" $margin="left">
                      <Hidden className="text">{tech[1].subtitle}</Hidden>
                    </SecondaryTitle>
                  </HeadingContain>
                </HeadingContainer>
                <Paragraph className="scramble" $margin="left">
                  <Hidden className="text">{tech[1].text}</Hidden>
                </Paragraph>
              </ThirdContainer>
              <ThirdContainer>
                <HeadingContain
                  $justify="flex-start"
                  $align="flex-start"
                  $direction="column"
                  $mobile
                >
                  <SmallHeading $margin="left">
                    {code}
                    {code} {code}
                    <br></br>
                    {code}
                  </SmallHeading>
                </HeadingContain>
                <HeadingContainer $direction="column">
                  <HeadingContain>
                    <SecondaryTitle className="scramble" $hiddensm>
                      <Hidden className="text">03.</Hidden>
                    </SecondaryTitle>
                    <SVGContainer $align="flex-end" $hiddenxs>
                      <TechSeparator />
                    </SVGContainer>
                  </HeadingContain>
                  <HeadingContain $mobileRow $direction="column" $padding="top">
                    <SecondaryTitle className="scramble" $margin="left">
                      <Hidden className="text">{tech[2].title}</Hidden>
                    </SecondaryTitle>
                    <SecondaryTitle className="scramble" $mobileMargin>
                      <Hidden className="text">{tech[2].subtitle}</Hidden>
                    </SecondaryTitle>
                  </HeadingContain>
                </HeadingContainer>
                <HeadingContain>
                  <ColorBlock className="block" width="350" />
                  <Paragraph className="scramble" $margin="left">
                    <Hidden className="text">{tech[2].text}</Hidden>
                  </Paragraph>
                </HeadingContain>
              </ThirdContainer>
            </Footer>
          </TitleBorder>
        </FooterContainer>
      </ContainerOverlay>
      <MobileBanner>
        <HeadingContain
          $justify="flex-start"
          $align="flex-start"
          $direction="column"
        >
          <HeadingContain>
            <SecondaryParagraph
              $setcolour={COLOURS.primary.aqua}
              className="scramble"
            >
              {banner}
            </SecondaryParagraph>
          </HeadingContain>
        </HeadingContain>
        <HeadingContain
          $justify="flex-start"
          $align="flex-start"
          $direction="column"
          $padding="top"
        >
          <ColorBlock className="block" width="200" $margin="left" />
          <SmallHeading $margin="right" $width={300}>
            {copyright}
          </SmallHeading>
        </HeadingContain>
        <HeadingContain
          $justify="flex-start"
          $align="flex-start"
          $direction="row"
          $padding="top"
        >
          <SmallHeading $margin="right" $width={300}>
            {disclaimer}
          </SmallHeading>
          <SmallHeading $margin="right">{effects}</SmallHeading>
        </HeadingContain>
      </MobileBanner>
      <PosterContainer>
        <PosterImage src={media.tech.source} alt={title} />
      </PosterContainer>
    </Container>
  );
};

export default PDPTech;

import React, { useEffect, useState } from "react";
import { Container } from "../styles/components/PDP/Global";
import { Senses } from "./Sense-Objects";
import SenseVideo from "./Video";

const GlitchVideo = (sense) => {
  const { audioEnabled, toggleAudio } = sense;
  const { items } = Senses;
  const [glitchItem, setGlitchItem] = useState([]);

  useEffect(() => {
    const existingProductIds =
      JSON.parse(localStorage.getItem("cartItems")) || [];
    const itemCount = existingProductIds.length;
    const shouldApplyGlitchEffect =
      (!existingProductIds.includes(6) || !existingProductIds.includes(7)) &&
      (itemCount === 0 || itemCount === 1);

    if (shouldApplyGlitchEffect) {
      const currentProductId = existingProductIds.includes(6) ? 7 : 6;
      const selectedProduct = items.find(
        (item) => item.id === currentProductId
      );
      setGlitchItem(selectedProduct);
    }
  }, []);

  return (
    <Container $height="100vh">
      {glitchItem && glitchItem.media?.video && (
        <SenseVideo
          audioEnabled={audioEnabled}
          toggleAudio={toggleAudio}
          videoSrc={glitchItem.media.video.source}
          videoPoster={glitchItem.media.video.poster}
          glitch={true}
        />
      )}
    </Container>
  );
};

export default GlitchVideo;

import THREE from "../../Three";
import gsap from "../../../components/Animations";
import {
  fragmentShader,
  vertexParticlesShader,
  fragmentSimulation,
} from "../../../../static/materials/shaders/ava-shaders";
import {
  createEnvironment,
  createAnimations,
  updateChipPositions,
  triggerAvaMessage,
  revertAvaTimelines,
  triggerTransition,
  endTransition,
  updateRatios,
  handleScroll,
  mm,
} from "./Modules";
import { BREAKPOINTS } from "../../../styles/variables/Breakpoints";

export default class PLP {
  constructor(
    scene,
    activeSense,
    activeAva,
    activeCart,
    activeSection,
    activeLoaderPlayed,
    renderer
  ) {
    this.scene = scene;
    this.renderer = renderer;
    this.camera = scene.userData.camera;
    this.element = scene.userData.element;
    this.canvas = renderer.domElement;
    this.fov = this.camera.fov;
    this.ratio = 0.75;

    this.labelRenderer = new THREE.CSS2DRenderer();
    this.group = new THREE.Group();
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.intersecting = false;
    this.clock = new THREE.Clock();
    this.world = new THREE.Vector3();
    this.current = 1;
    this.ctx = gsap.context(() => {});
    this.avaCtx = gsap.context(() => {});
    this.observer = null;

    this.time = 0;
    this.speed = { value: 1 };
    this.sectionWidth = 150;
    this.width = 128;

    this.wrapper = document.getElementById("wrapper");
    this.transition = document.getElementById("scene-transition");

    this.activeSense = activeSense;
    this.activeAva = activeAva;
    this.activeCart = activeCart;
    this.activeSection = activeSection;
    this.activateAva = false;
    this.activeLoaderPlayed = activeLoaderPlayed;

    this.id = 0;
    this.sectionActive = false;

    this.loaded = false;
    this.animating = false;
    this.sizzle = false;
    this.selected = null;
    this.view = null;
    this.ava = null;

    this.plp = document.getElementById("section-plp");

    this.onResize = this.onResize.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.scene.userData.constructor = this;

    this.initScene();
    this.initGPGPU();
    this.createAva();
    createEnvironment(this);
  }

  initScene() {
    const mediaMatcher = mm();
    this.context = mediaMatcher.contexts[0].conditions;

    this.activeSense(0);

    this.scene.background = new THREE.Color(0x7a7a7a);
    this.scene.fog = new THREE.Fog(0x7a7a7a, 250, 1000);

    this.primaryLight = new THREE.PointLight(0xffffff, 180, 2000, 0.8);

    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.1 * Math.PI);

    this.primaryLight.position.set(-75, 150, 0);

    this.primaryLight.shadow.bias = -0.005;
    this.primaryLight.castShadow = true;

    this.scene.add(this.primaryLight, this.ambientLight);
  }

  setClasses(pdpInstance, pagesInstances, checkoutInstance) {
    this.PDP = pdpInstance;
    this.Pages = pagesInstances;
    this.Checkout = checkoutInstance;
  }

  initGPGPU() {
    this.gpuCompute = new THREE.GPUComputationRenderer(
      this.width,
      this.width,
      this.renderer
    );
    this.dtPosition = this.gpuCompute.createTexture();
    this.positionVariable = this.gpuCompute.addVariable(
      "texturePosition",
      fragmentSimulation(),
      this.dtPosition
    );
    this.fillPositions(this.dtPosition);
    this.positionVariable.material.uniforms["u_time"] = { value: 0 };
    this.positionVariable.wrapS = THREE.RepeatWrapping;
    this.positionVariable.wrapT = THREE.RepeatWrapping;

    this.gpuCompute.init();
  }

  fillPositions(texture) {
    let arr = texture.image.data;
    for (let i = 0; i < arr.length; i = i + 4) {
      let x = Math.random();
      let y = Math.random();
      let z = Math.random();

      arr[i] = x;
      arr[i + 1] = y;
      arr[i + 2] = z;
      arr[i + 3] = 1;
    }
  }

  createAva() {
    this.frequency = 0.4;
    this.amplitude = 6.5;
    this.maxDistance = 13;

    this.avaMaterial = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        u_time: { type: "f", value: 0 },
        u_positionTexture: { value: null },
        u_frequency: { value: this.frequency },
        u_amplitude: { value: this.amplitude },
        u_maxDistance: { value: 0 },
      },
      vertexShader: vertexParticlesShader(),
      fragmentShader: fragmentShader(),
      transparent: true,
    });

    this.avaGeometry = new THREE.BufferGeometry();
    let positions = new Float32Array(this.width * this.width * 3);
    let reference = new Float32Array(this.width * this.width * 2);

    for (let i = 0; i < this.width * this.width; i++) {
      let x = Math.random();
      let y = Math.random();
      let z = Math.random();
      let xx = (i % this.width) / this.width;
      let yy = ~~(i / this.width) / this.width;
      positions.set([x, y, z], i * 3);
      reference.set([xx, yy], i * 2);
    }

    this.avaGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );

    this.avaGeometry.setAttribute(
      "reference",
      new THREE.BufferAttribute(reference, 2)
    );

    this.avaIcosahedronGeometry = new THREE.IcosahedronGeometry(1, 68);
    this.plane = new THREE.Points(
      this.avaIcosahedronGeometry,
      this.avaMaterial
    );
    this.plane.scale.set(7, 7, 3);
    this.plane.name = "Ava";
    this.plane.material.depthTest = false;

    this.plane.visible = this.context.isDesktop;
    this.scene.add(this.plane);

    this.createAvaLabels();
  }

  createAvaLabels() {
    this.chatElement = this.element.querySelector(".chat");

    this.plane.layers.enableAll();

    this.chatLabel = new THREE.CSS2DObject(this.chatElement);
    this.chatLabel.name = "Chat";

    this.chatLabel.position.copy(this.plane.position);
    this.chatLabel.center.set(1.35, 0.35);
    this.plane.add(this.chatLabel);

    this.chatLabel.layers.set(0);

    this.labelRenderer.setSize(
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = "0px";
    this.element.appendChild(this.labelRenderer.domElement);
  }

  loadAvaToScene() {
    const mediaMatcher = mm();
    this.activateAva = true;
    this.chatAnimationTl = gsap.timeline();

    const triggerAvaTl = gsap.timeline({
      delay: 1,
      onComplete: () => {
        this.sectionActive && triggerAvaMessage(this, true);
        if (this.context.isDesktop) this.activateAva = true;
        triggerAvaTl.kill();
      },
    });

    this.avaCtx.add(() => {
      this.avaChatTl = gsap.timeline({
        onComplete: () => {
          this.activateAva = false;
        },
        onUpdate: () => {
          !this.activateAva && this.avaChatTl.kill();
        },
      });

      this.avaTl = gsap.timeline({
        repeat: -1,
        repeatDelay: 0.5,
        onUpdate: () => {
          if (!this.activateAva) {
            this.avaTl.progress(0).pause();
          }
        },
      });
    });

    mediaMatcher.add(`(min-width: ${BREAKPOINTS.md}px)`, () => {
      triggerAvaTl.to(this.avaMaterial.uniforms.u_maxDistance, {
        delay: 1,
        value: this.maxDistance,
        duration: 2,
        ease: "Bounce.easeOut",
      });
    });
  }

  moveItemsBetweenScenes(sourceScene, destinationScene, ...items) {
    items.forEach((item) => {
      const isChip = item.name && item.name.includes("-chip");
      if (sourceScene === this.scene) {
        if (isChip) {
          this.group.remove(item);
        } else {
          this.scene.remove(item);
        }
        destinationScene.add(item);
      } else {
        destinationScene.remove(item);
        if (isChip) {
          this.group.add(item);
        } else {
          this.scene.add(item);
        }
      }
    });
  }

  updatePLPScene(tl) {
    tl.add(() => {
      window.scrollTo(0, 0);

      this.PDP.disposePDPScene();
      this.Pages.disposePagesScene();
      this.Checkout.disposeCheckoutScene();

      revertAvaTimelines(this);

      this.onResize();

      updateChipPositions(this);
      createAnimations(this, true);

      this.selected = null;

      this.camera.position.x = this.world.x;
      this.camera.position.z = this.world.z + this.sectionWidth;

      this.activeSense(0);
      this.activeAva(1, false);
      this.activeCart();

      this.createPLPEventListeners();
    }).then(() => endTransition(tl));
  }

  updatePDPScene(current) {
    this.onMouseOutChip();

    this.selected = current.isInstancedMesh
      ? current
      : current.parent.parent.parent;

    this.ctx.add(() => {
      gsap.to(this.camera.position, {
        z: "-=55",
        duration: 2,
      });
    });

    const tl = triggerTransition();

    tl.add(() => {
      this.sectionActive = false;
      this.PDP.sectionActive = true;

      this.activeSection(this.PDP.id);
      this.Pages.disposePagesScene();
      this.Checkout.disposeCheckoutScene();

      window.scrollTo(0, 0);

      this.moveItemsBetweenScenes(
        this.scene,
        this.PDP.scene,
        this.selected,
        this.plane
      );

      if (this.activateAva) {
        this.activateAva = false;
      }

      revertAvaTimelines(this);
    })
      .add(() => {
        this.PDP.updatePDPScene();
        this.wrapper.classList.toggle("hovering", false);
      })
      .then(() => endTransition(tl));
  }

  disposePLPScene() {
    this.removePLPEventListeners();

    this.avaGeometry.dispose();
    this.avaMaterial.dispose();
    this.plane.geometry.dispose();
    this.plane.material.dispose();
    this.gpuCompute.dispose();
  }

  updateAvaUniforms() {
    this.time += 0.05 * this.speed.value;
    this.positionVariable.material.uniforms["u_time"].value = this.time;
    this.gpuCompute.compute();
    this.avaMaterial.uniforms.u_positionTexture.value = this.gpuCompute.getCurrentRenderTarget(
      this.positionVariable
    ).texture;

    this.avaMaterial.uniforms.u_time.value = this.time;
  }

  updateAvaPosition() {
    const { clientWidth, clientHeight } = this.canvas;

    const depth = 55;

    const multiplier = clientWidth > 1800 ? 0.44 : 0.465;
    const frustumHeight =
      2.0 * depth * Math.tan((this.fov * multiplier * Math.PI) / 180.0);
    const frustumWidth = frustumHeight * (clientWidth / clientHeight);

    const margin = 10;

    const right = frustumWidth / 2 - margin;
    const bottom = -frustumHeight / 2 + margin;

    this.plane.position.copy(this.camera.position);
    this.plane.rotation.copy(this.camera.rotation);
    this.plane.updateMatrix();

    this.plane.translateX(right);
    this.plane.translateY(bottom);
    this.plane.translateZ(-depth);

    this.plane.userData.position = this.plane.position.clone();
  }

  raycasterMouse(event, selected) {
    if (this.animating) return;
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    this.raycaster.layers.set(0);
    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.params.Line.threshold = 1;
    this.raycaster.params.Points.threshold = 1;
    const intersects = this.raycaster.intersectObjects(this.group.children);
    if (intersects.length > 0) {
      this.wrapper.classList.toggle("hovering", true);
      if (selected) {
        this.updatePDPScene(intersects[0].object);
      } else {
        this.onMouseMoveChip(intersects[0]);
      }
    } else {
      this.wrapper.classList.toggle("hovering", false);
      this.intersecting = false;
    }
  }

  onMouseMoveChip(intersect) {
    if (this.intersecting) return;

    this.intersecting = true;
    let intersectionPoint = intersect.point;
    let intersectObject = intersect.object;

    let hoverCurrent = intersectObject.isInstancedMesh
      ? intersectObject
      : intersectObject.parent.parent.parent;

    if (this.view !== hoverCurrent) return;

    let moveVector = new THREE.Vector3().subVectors(
      intersectionPoint,
      hoverCurrent.rotation
    );

    const ratio = 0.1;
    const maxDistance = 0.1;
    const maxTilt = 0.15;

    moveVector.x = THREE.MathUtils.clamp(moveVector.x, -ratio, ratio);
    moveVector.y = THREE.MathUtils.clamp(moveVector.y, -ratio, ratio);

    let originalPosition = new THREE.Vector3().copy(
      hoverCurrent.userData.rotation
    );

    let angle = Math.atan2(
      intersectionPoint.y - originalPosition.y,
      intersectionPoint.z - originalPosition.z
    );

    angle = THREE.MathUtils.clamp(angle, -maxDistance, maxDistance);

    let newHoverPosition = new THREE.Vector3().addVectors(
      hoverCurrent.rotation,
      moveVector.setZ(angle * maxTilt)
    );

    newHoverPosition.x = THREE.MathUtils.clamp(
      newHoverPosition.x,
      originalPosition.x - maxDistance,
      originalPosition.x + maxDistance
    );

    newHoverPosition.y = THREE.MathUtils.clamp(
      newHoverPosition.y,
      originalPosition.y - maxDistance,
      originalPosition.y + maxDistance
    );

    newHoverPosition.z = THREE.MathUtils.clamp(
      newHoverPosition.z,
      originalPosition.z - maxDistance,
      originalPosition.z + maxDistance
    );

    gsap.to(hoverCurrent.rotation, {
      duration: 0.1,
      x: newHoverPosition.x,
      y: newHoverPosition.y,
      z: newHoverPosition.z,
      ease: "power2.out",
      onComplete: () => {
        this.intersecting = false;
      },
    });
  }

  onMouseOutChip() {
    let originalRotation = new THREE.Vector3().copy(
      this.view.userData.rotation
    );

    gsap.to(this.view.rotation, {
      delay: 0.5,
      duration: 0.1,
      x: originalRotation.x,
      y: originalRotation.y,
      z: originalRotation.z,
      ease: "power2.in",
    });
  }

  createPLPEventListeners() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("mousedown", this.onMouseDown);
    window.addEventListener("mousemove", this.onMouseMove);

    this.element.addEventListener("mouseenter", this.onMouseEnter);
    this.element.addEventListener("mouseleave", this.onMouseLeave);

    if (!this.observer.isEnabled) this.observer.enable();
    this.wrapper.classList.toggle("active", true);
    handleScroll(false);
    updateRatios(this);
  }

  removePLPEventListeners() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("mousedown", this.onMouseDown);
    window.removeEventListener("mousemove", this.onMouseMove);

    this.element.removeEventListener("mouseenter", this.onMouseEnter);
    this.element.removeEventListener("mouseleave", this.onMouseLeave);

    if (this.observer.isEnabled) this.observer.disable();
    this.wrapper.classList.toggle("active", false);
    handleScroll(true);
  }

  render() {
    if (!this.loaded || !this.sectionActive) return;

    if (this.context.isDesktop) {
      this.plane.visible = true;
      this.updateAvaUniforms();
      this.updateAvaPosition();
      this.labelRenderer.render(this.scene, this.camera);
    } else {
      this.plane.visible = false;
    }
  }

  onMouseDown(event) {
    if (this.selected !== null) return;
    this.raycasterMouse(event, true);
  }

  onMouseMove(event) {
    if (this.selected !== null) return;
    this.raycasterMouse(event);
  }

  onMouseEnter(event) {
    if (this.selected !== null) return;
    this.raycasterMouse(event);
  }

  onMouseLeave() {
    if (this.selected !== null) return;
    this.onMouseOutChip();
  }

  onResize() {
    updateRatios(this);
    this.labelRenderer.setSize(
      this.canvas.clientWidth,
      this.canvas.clientHeight
    );
  }
}

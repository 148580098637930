import React from "react";
import { Wrapper, Container } from "../styles/components/Checkout/Checkout";
import { ThemeProvider } from "styled-components";
import { THEMES } from "../styles/variables/Themes";
import Navigation from "./Navigation";
import Checkout from "./Checkout";

const CheckoutLanding = (senses) => {
  return (
    <Wrapper>
      <Container $height="100vh">
        <ThemeProvider theme={THEMES.dark}>
          <Navigation {...senses} checkout={true} />
          <Checkout {...senses} />
        </ThemeProvider>
      </Container>
    </Wrapper>
  );
};

export default CheckoutLanding;

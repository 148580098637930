import React from "react";
import GlobalStyles from "../components/GlobalStyles";
import App from "../components/App";
import GlobalFonts from "../components/GlobalFonts";
import Seo from "../components/SEO";

const IndexPage = () => {
  return (
    <>
      <Seo
        title="SENSE by Highsnobiety & 91 Rules"
        description="Connect like humans."
        image="/sense-image-logo.jpg"
        pathname="/"
      />
      <GlobalStyles />
      <GlobalFonts />
      <App />
    </>
  );
};

export default IndexPage;

import React, { useRef, useState } from "react";
import gsap from "../components/Animations";
import {
  RightNavigation,
  Menu,
  DropDown,
  Selection,
  LinkList,
  RightContainer,
  LinkContainer,
  PlayerContainer,
  SelectedItem,
  Hidden,
} from "../styles/components/Global/Navigation";
import Player from "./Player";

const MenuItem = ({ classNames, text, title }) => {
  const [active, setActive] = useState(false);
  const menuRef = useRef(null);

  const onMouseEnter = () => {
    if (active) return;
    setActive(true);
    const timeline = gsap.timeline({});
    gsap.rescrambleAnimation(menuRef.current, timeline);
    timeline.then(() => setActive(false));
  };

  return (
    <>
      <Menu
        ref={menuRef}
        className={`scramble ` + classNames}
        $pdp={title && title}
        onMouseEnter={onMouseEnter}
      >
        <Hidden className="text" $colon={title && title}>
          {text}
        </Hidden>
      </Menu>
      {title && (
        <Selection className="scramble">
          <Hidden className="text">{title}</Hidden>
        </Selection>
      )}
    </>
  );
};

const MenuNavigation = ({
  plp,
  pdp,
  checkout,
  global,
  audioEnabled,
  toggleAudio,
  title: propsTitle,
  id,
}) => {
  const title = global && propsTitle;

  return (
    <RightNavigation>
      <RightContainer $plp={plp}>
        {!plp && (
          <DropDown $border={pdp} $checkout={checkout}>
            {checkout && (
              <PlayerContainer>
                <Player
                  audioEnabled={audioEnabled}
                  toggleAudio={toggleAudio}
                  checkout={true}
                />
              </PlayerContainer>
            )}
            <LinkContainer $pdp={title && title}>
              <MenuItem
                classNames="reset"
                text="Product List"
                title={title && title}
              />
            </LinkContainer>
            {title && (
              <SelectedItem>
                <Menu $bold $removeMargin>
                  0{id}. {title}
                </Menu>
              </SelectedItem>
            )}
          </DropDown>
        )}
        <LinkList $plp={plp}>
          <LinkContainer>
            <MenuItem classNames="vision" text="Our Vision" />
          </LinkContainer>
          <LinkContainer $border $checkout={checkout}>
            <MenuItem classNames="cart open" text="My Cart" />
          </LinkContainer>
        </LinkList>
      </RightContainer>
    </RightNavigation>
  );
};

export default MenuNavigation;

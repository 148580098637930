import React from "react";
import { Hidden } from "../styles/components/PLP/Global";
import {
  Container,
  ContainerOverlay,
  TitleContainer,
  Paragraph,
  Title,
  FooterContainer,
  SmallHeading,
  HeadingContain,
  HeadingContainer,
  SVGContainer,
  TitleFooter,
  ColorBlock,
} from "../styles/components/PLP/Navigation";
import { COLOURS } from "../styles/variables/Colors";

const PLPNavigation = ({ copyright, code, senses }) => {
  return (
    <Container $height="100%" $blend>
      <ContainerOverlay>
        <TitleContainer>
          {senses.items
            ?.filter((sense) => sense.id <= 5)
            .map((sense, i) => {
              return (
                <HeadingContain
                  key={i}
                  className={`${sense.id === 1 && "active"} ${sense.id}-bullet`}
                  $direction="row"
                  $align="center"
                  $padding="bottom"
                  $bullet
                >
                  <SVGContainer $justify="flex-start" $align="flex-start">
                    <sense.media.symbol size="small" />
                  </SVGContainer>
                  <Paragraph className="scramble" $margin="left">
                    <Hidden className="text">{sense.banner}</Hidden>
                  </Paragraph>
                </HeadingContain>
              );
            })}
          <HeadingContain
            $direction="row"
            $align="flex-start"
            $padding="top"
            $hiddensm
          >
            <Paragraph className="scramble" $setcolour={COLOURS.primary.black}>
              <Hidden className="text">
                Sense <br></br>©
              </Hidden>
            </Paragraph>
            <Paragraph className="scramble" $margin="left">
              <Hidden className="text">{code}</Hidden>
            </Paragraph>
          </HeadingContain>
        </TitleContainer>
        <TitleContainer>
          {senses.items
            ?.filter((sense) => sense.id <= 5)
            .map((sense, i) => (
              <HeadingContain
                key={i}
                className={`${sense.id === 1 && "active"} ${sense.id}-bullet`}
                $direction="column"
                $padding="bottom"
                $bullet
              >
                <Title className="scramble">
                  <Hidden className="text">
                    0{sense.id}.{sense.title}
                  </Hidden>
                </Title>
              </HeadingContain>
            ))}
        </TitleContainer>
        <FooterContainer $hiddenmd>
          <TitleFooter>
            <HeadingContainer>
              <HeadingContain
                $justify="flex-start"
                $align="flex-start"
                $direction="column"
              >
                <ColorBlock className="block" width="200" />
                <SmallHeading className="scramble-delay" $width={400}>
                  <Hidden className="text">{copyright}</Hidden>
                </SmallHeading>
              </HeadingContain>
            </HeadingContainer>
          </TitleFooter>
        </FooterContainer>
      </ContainerOverlay>
    </Container>
  );
};

export default PLPNavigation;

import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import {
  BREAKPOINTS,
  hiddensm,
  hiddenxs,
  mobile,
  mobileXs,
} from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const ContainerOverlay = styled.div`
  ${setFlex({
    justify: "center",
    align: "center",
    direction: "column",
  })};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 5 })};
  min-height: 100vh;
  height: auto;
  width: 100%;
  max-width: 100vw;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
    ${setFlex({ justify: "flex-end" })};
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  ${generateFont("0.6rem", false)};
  ${generateLineHeight("0.6rem", false)};
  margin: 0;
  text-align: left;
`;

export const TitleContainer = styled.div`
  ${setFlex({ justify: "center" })};
  width: 100%;
  height: 100%;
`;

export const Title = styled.h1`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${COLOURS.primary.white};
  ${generateFont("3.5rem")};
  ${generateLineHeight("3.2rem")};
  margin: 0;
  ${(props) => props.$flex && setFlex({ justify: props.$justify })};
  ${(props) => props.$margin && `margin-${props.$margin}: 2.5rem`};
  ${(props) =>
    props.$mobileMargin &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      margin-${props.$margin}: 1rem;
    }
  `};
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${generateFont("3rem")};
    ${generateLineHeight("2.8rem")};
  }
`;

export const SmallHeading = styled(Headline)`
  color: ${COLOURS.primary.white};
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    ${generateFont("0.5rem", false, "xxl")};
    ${generateLineHeight("0.5rem", false, "xxl")};
  }
  ${(props) => props.$width && `max-width: ${props.$width}px`};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$mobile && mobile};
`;

export const HeadingContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({})};
  margin: 2.5rem;
  width: fit-content;

  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
    margin: 0;
  }
`;

export const MaskContainer = styled.div`
  ${setFlex({})};
  ${setPosition({ position: "relative" })};
`;

export const Mask = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: -1 })};
  background-color: ${COLOURS.primary.white};
  height: 100%;
  width: 0%;
`;

export const ProductNo = styled.div`
  ${setPosition({ position: "relative" })};
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  color: ${COLOURS.primary.yellow};
  font-size: 4.5rem;
  line-height: 3.75rem;
  margin: 0;
  text-align: center;
  width: 30%;
`;

export const ProductNoContainer = styled.div`
  ${setPosition({ position: "relative" })};
  height: fit-content;
  flex-grow: 1;
  > svg {
    margin-left: 0.6rem;
  }
  ${hiddensm};
`;

export const HeadingContain = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  height: ${(props) => props.$height || "auto"};
  position: relative;
  > svg {
    margin-left: 0.6rem;
  }
  ${(props) =>
    props.$mobilePadding &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      padding: 1.5rem;
    }
  `};
  ${(props) =>
    props.$mobileAlign &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      ${setFlex({ align: "flex-start" })};
    }
  `};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.8rem`};
  ${(props) => props.$mobileXs && mobileXs};
`;

export const SVGContainer = styled.div`
  ${(props) => setFlex({ justify: props.$justify, align: props.$align })};
`;

export const FloatText = styled.div`
  ${(props) =>
    setPosition({ position: "absolute", top: props.$top, left: props.$right })};
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  background-color: ${COLOURS.primary.blue};
  min-height: 1.5rem;
  margin-left: -0.6rem;
`;

export const DescriptionTitle = styled.div`
  ${setFlex({ justify: "center", align: "center", direction: "column" })};
  ${setPosition({ position: "relative" })};
  height: 100%;
  max-width: 1200px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 1.5rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    flex-direction: column;
    margin: 0 0.8rem;
    width: inherit;
  }
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    max-width: 1400px;
  }
`;

export const TitleFooter = styled.div`
  ${setFlex({ justify: "flex-end" })};
  width: 100%;
  padding: 0.6rem;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: -webkit-fill-available;
    width: -moz-available;
    margin-right: 2.5rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
    width: 100%;
    margin-right: 0;
    padding: 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${setFlex({ align: "flex-start", direction: "column" })};
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

import React, { useRef, useState } from "react";
import gsap from "../components/Animations";
import {
  ContainerOverlay,
  Column,
  HeadingContain,
  Title,
  SecondaryTitle,
  Paragraph,
  Hidden,
  Image,
  TitleContainer,
  TitleWrapper,
  SmallHeading,
  SVGContainer,
  QRCode,
  Credit,
  CreditLink,
  Link,
  Mask,
  MaskContainer,
  FloatingColumn,
  ColorBlock,
  Button,
  VideoWrapper,
  VideoContainer,
  Video,
  LogoContainer,
  LinksContainer,
  ButtonContainer,
} from "../styles/components/Checkout/Checkout";
import SenseVideo from "./Video";
import { COLOURS } from "../styles/variables/Colors";

const LinkItem = ({ link, title, colour, heading }) => {
  const [active, setActive] = useState(false);
  const linkRef = useRef(null);

  const onMouseEnter = () => {
    if (active) return;
    setActive(true);
    const timeline = gsap.timeline({});
    gsap.rescrambleAnimation(linkRef.current, timeline);
    timeline.then(() => setActive(false));
  };

  return (
    <CreditLink
      ref={linkRef}
      className="scramble"
      onMouseEnter={onMouseEnter}
      href={link}
      $setcolour={colour}
      $heading={heading}
      target="_blank"
    >
      <Hidden className="text">{title}</Hidden>
    </CreditLink>
  );
};

const Checkout = ({ qrcode, copyright, senses }) => {
  return (
    <ContainerOverlay className="checkout-container">
      <Column>
        <TitleWrapper $mobilePadding>
          <TitleContainer $direction="column">
            <HeadingContain $hiddenxl>
              <ColorBlock className="block" width="200" />
              <SmallHeading
                className="scramble"
                $setcolour={COLOURS.primary.white}
                $margin="bottom"
                $width={300}
              >
                <Hidden className="text">{copyright}</Hidden>
              </SmallHeading>
            </HeadingContain>
            <HeadingContain $direction="column" $align="flex-start">
              <SVGContainer>
                {senses.items
                  ?.filter((sense) => sense.id <= 5)
                  .map((sense, i) => {
                    return <sense.media.symbol key={i} size="medium" />;
                  })}
              </SVGContainer>
            </HeadingContain>
          </TitleContainer>
        </TitleWrapper>
        <TitleWrapper $noMargin>
          <TitleContainer $hiddensm>
            <HeadingContain>
              <Link href={qrcode.url} target="_blank">
                <QRCode
                  className="fade-image"
                  src={qrcode.source}
                  alt={qrcode.alt}
                  loading="lazy"
                />
              </Link>
            </HeadingContain>
          </TitleContainer>
          <TitleContainer $direction="column">
            <HeadingContain $direction="column" $fitWidth $padding="bottom">
              <MaskContainer $margin="left">
                <Mask className="mask" width="100" />
                <Title className="scramble-delay" $margin="right">
                  <Hidden className="text">THIS IS A DIGITAL</Hidden>
                </Title>
              </MaskContainer>
            </HeadingContain>
            <HeadingContain $direction="column" $fitWidth $mobileRow>
              <MaskContainer $margin="left">
                <Mask className="mask" width="100" />
                <Title
                  className="scramble-delay"
                  $justify="flex-end"
                  $margin="right"
                >
                  <Hidden className="text">art EXHIBITON</Hidden>
                </Title>
              </MaskContainer>
              <Title className="scramble-delay" $justify="flex-end" $mobile>
                <Hidden className="text">by</Hidden>
              </Title>
            </HeadingContain>
            <LogoContainer>
              <Title className="scramble-delay" $justify="flex-end" $hiddensm>
                <Hidden className="text">by</Hidden>
              </Title>
              <Link
                href="https://instagram.com/91.rules"
                target="_blank"
                $align="center"
              >
                <Image
                  src="https://static.highsnobiety.com/dato/40484/1708352445-91rules-logo-white.png"
                  loading="lazy"
                  className="fade-image"
                />
              </Link>
              <SecondaryTitle
                className="scramble-delay"
                $setcolour={COLOURS.primary.white}
                $symbol
              >
                <Hidden className="text">&</Hidden>
              </SecondaryTitle>
              <Link
                href="https://highsnobiety.com/"
                target="_blank"
                $align="center"
              >
                <Image
                  src="https://static.highsnobiety.com/dato/40484/1708352449-hs-logo-white.png"
                  loading="lazy"
                  className="fade-image"
                />
              </Link>
            </LogoContainer>
            <HeadingContain $direction="column" $align="flex-start" $margin>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">
                    DIGITAL PRODUCT MANAGER: SHAUN ROACH
                  </Hidden>
                </Paragraph>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">
                    DIGITAL ART DIRECTOR: LISLE ABRAHAMS
                  </Hidden>
                </Paragraph>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">
                    CREATIVE DEVELOPER: BELEN JONES
                  </Hidden>
                </Paragraph>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">
                    PRODUCT/UX DESIGNER: ETHAN STUMPF
                  </Hidden>
                </Paragraph>
              </Credit>
              <Credit $padding>
                <Paragraph className="scramble">
                  <Hidden className="text">
                    WORDS BY: ALEXANDRA PAULY & GENEVIEVE WALKER
                  </Hidden>
                </Paragraph>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Model: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/yuniya_p"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Unia Pakhomova</Hidden>
                </CreditLink>
                <CreditLink
                  href="https://instagram.com/newyorkmodels"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">at New York Model Management</Hidden>
                </CreditLink>
              </Credit>

              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Director & Photographer: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/91.rules"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">91 rules</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Creative Directors: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/91.rules"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">91 rules</Hidden>
                </CreditLink>
                <CreditLink
                  href="https://instagram.com/alliesmithmakeup"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">& Allie Smith</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Make-Up Artist: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/alliesmithmakeup"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Allie Smith</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Director of Photography: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/evanburristrout"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Evan Burris Trout</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">
                    Production Design and Props:{" "}
                  </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/michael.younker"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Michael Younker</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Producer: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/cas.doy"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Cassie Doyle</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Hair: Shinya Nakagawa</Hidden>
                </Paragraph>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Stylist: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/vvutura"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Stephanie Perez</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Manicurist: Frances Laing</Hidden>
                </Paragraph>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Logo Design: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/hso_new_york"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">HSO</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Color: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/josephbicknell"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Joseph Bicknell</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Sound: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/staubaudio"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Staub Audio</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">VFX Services Provided By: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/finish_post"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">FINISH</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">VFX Artist: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/renemgibson"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Rene Gibson</Hidden>
                </CreditLink>
              </Credit>
              <Credit>
                <Paragraph className="scramble">
                  <Hidden className="text">Face Filter: </Hidden>
                </Paragraph>
                <CreditLink
                  href="https://instagram.com/una_blue"
                  className="scramble"
                  target="_blank"
                >
                  <Hidden className="text">Una Blue</Hidden>
                </CreditLink>
              </Credit>
            </HeadingContain>
            <ButtonContainer>
              <HeadingContain>
                <Button>
                  <LinkItem
                    link="https://highsnobiety.com/p/highsnobiety-91-rules-sense"
                    title="Interview with 91Rules"
                    colour={COLOURS.primary.white}
                    heading
                  />
                </Button>
              </HeadingContain>
              <LinksContainer>
                <Link
                  href="https://highsnobiety.com/en-de/pages/privacy/"
                  target="_blank"
                  $noWrap
                >
                  <SmallHeading
                    className="scramble"
                    $setcolour={COLOURS.primary.white}
                  >
                    <Hidden className="text">Privacy policy</Hidden>
                  </SmallHeading>
                </Link>
                <Link
                  href="https://highsnobiety.com/en-de/pages/imprint/"
                  target="_blank"
                  $justify="flex-end"
                  $noWrap
                >
                  <SmallHeading
                    className="scramble"
                    $setcolour={COLOURS.primary.white}
                  >
                    <Hidden className="text">Imprint</Hidden>
                  </SmallHeading>
                </Link>
              </LinksContainer>
            </ButtonContainer>
          </TitleContainer>
        </TitleWrapper>
      </Column>
      <FloatingColumn className="checkout-videos">
        <VideoContainer>
          <VideoWrapper>
            {senses.items
              ?.filter((sense) => sense.id === 1 || sense.id === 2)
              .map((sense, i) => {
                return (
                  <Video key={i} className={sense.title.toLowerCase()}>
                    <SenseVideo
                      audioEnabled={false}
                      toggleAudio={false}
                      videoSrc={sense.media.video.source}
                      videoPoster={sense.media.video.poster}
                      checkout={true}
                    />
                  </Video>
                );
              })}
          </VideoWrapper>
          <VideoWrapper>
            {senses.items
              ?.filter((sense) => sense.id === 3 || sense.id === 4)
              .map((sense, i) => {
                return (
                  <Video key={i} className={sense.title.toLowerCase()}>
                    <SenseVideo
                      audioEnabled={false}
                      toggleAudio={false}
                      videoSrc={sense.media.video.source}
                      videoPoster={sense.media.video.poster}
                      checkout={true}
                    />
                  </Video>
                );
              })}
          </VideoWrapper>
          <VideoWrapper>
            {senses.items
              ?.filter((sense) => sense.id === 5)
              .map((sense, i) => {
                return (
                  <Video key={i} className={sense.title.toLowerCase()}>
                    <SenseVideo
                      audioEnabled={false}
                      toggleAudio={false}
                      videoSrc={sense.media.video.source}
                      videoPoster={sense.media.video.poster}
                      checkout={true}
                    />
                  </Video>
                );
              })}
          </VideoWrapper>
        </VideoContainer>
      </FloatingColumn>
    </ContainerOverlay>
  );
};

export default Checkout;

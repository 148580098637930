import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { Reflector } from "three/examples/jsm/objects/Reflector";
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { OutputPass } from "three/examples/jsm/postprocessing/OutputPass";

if (typeof window !== "undefined") {
  window.THREE = THREE;
}
const three = {
  ...THREE,
  GLTFLoader: GLTFLoader,
  DRACOLoader: DRACOLoader,
  Reflector: Reflector,
  GPUComputationRenderer: GPUComputationRenderer,
  CSS2DRenderer: CSS2DRenderer,
  CSS2DObject: CSS2DObject,
  EffectComposer: EffectComposer,
  RenderPass: RenderPass,
  GlitchPass: GlitchPass,
  OutputPass: OutputPass,
};

export default three;

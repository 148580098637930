import styled from "styled-components";
import {
  BREAKPOINTS,
  generateMediaQueries,
  IPADBREAKPOINTS,
} from "../../variables/Breakpoints";
import { COLOURS, BORDERS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.white};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

export const SmallHeading = styled(Headline)`
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 0.9rem;
  line-height: 0.9rem;
  word-wrap: break-word;
  pointer-events: none;
  ${(props) => props.$bold && "font-weight: bold"};
  ${(props) => props.$margin && `margin-${props.$margin}: 10px;`};
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 0.75rem;
  line-height: 0.75rem;
  max-width: 300px;
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 20px;`};
  ${(props) => props.$bold && "font-weight: bold"};
`;

export const Wrapper = styled.div`
  ${setPosition({ position: "fixed", right: "0", zIndex: 25 })};
  ${setFlex({ direction: "column" })};
  border: 1px solid ${BORDERS.secondary};
  height: -webkit-fill-available;
  height: -moz-available;
  max-height: 100vh;
  width: 30vw;
  overscroll-behavior: contain;
  background-color: ${COLOURS.primary.black};
  &:not(.active) {
    display: none;
  }
  @media (min-width: ${BREAKPOINTS.xxl}px) {
    width: 25vw;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 35vw;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 40vw;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    width: 60vw;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 50vw;
    height: 100vh;
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100vw;
  }
`;

export const CartContainer = styled.div`
  ${setFlex({ direction: "column" })};
  height: 100dvh;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CartBanner = styled.div`
  ${setFlex({ align: "center", justify: "center" })};
  padding: 1.25rem 0.9rem;
  border-bottom: 1px solid ${COLOURS.primary.white};
`;

export const CartHeading = styled.div`
  flex: 1 1 0;
`;

export const Close = styled.div`
  ${setFlex({ justify: "flex-end" })};
  flex: 1 1 0;
  cursor: pointer;
`;

export const NoItemsContainer = styled.div`
  ${setFlex({ justify: "center" })};
  width: 100%;
  height: 100%;
`;

export const CartItems = styled.div`
  ${setFlex({ direction: "column" })};
  overflow-y: scroll;
  flex: 1;
`;

export const Item = styled.div`
  ${setFlex({})};
  border-bottom: 1px solid ${COLOURS.primary.white};
  margin-bottom: 15px;
`;

export const ItemImage = styled.div`
  margin: 0.9rem;
`;

export const ItemDetails = styled.div`
  width: 50%;
  margin: 10px;
`;

export const ItemPrice = styled.div`
  ${setFlex({ justify: "flex-end" })};
  text-align: right;
  margin: 0.6rem;
`;

export const Image = styled.img`
  width: auto;
  max-width: 150px;
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    max-width: 100px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-width: 150px;
  }
`;

export const TotalsContainer = styled.div`
  ${setFlex({ direction: "column" })};
`;

export const SubTotal = styled.div`
  ${setFlex({})};
  border-top: 1px solid ${COLOURS.primary.white};
  padding: 1.25rem 0.9rem;
`;

export const Total = styled.div`
  flex: 1 1 0;
`;

export const Value = styled.div`
  ${setFlex({ justify: "flex-end" })};
  flex: 1 1 0;
`;

export const CheckOut = styled.div`
  ${setFlex({})};
  width: 100%;
`;

export const Column = styled.div`
  width: 50%;
`;

export const Button = styled.button`
  ${setFlex({ justify: "center" })};
  width: 100%;
  padding: 0.9rem;
  background-color: ${COLOURS.primary.white};
  border: 1px solid ${COLOURS.primary.white};
  cursor: pointer;
  ${(props) =>
    props.$dark &&
    `
    background-color: ${COLOURS.primary.black};
    border: 1px solid ${COLOURS.primary.white};
    border-right-width: 0;
  `};
  border-bottom-width: 0;
`;

export const TotalItems = styled.div`
  ${setFlex({ justify: "center" })};
  flex: 1 1 0;
`;

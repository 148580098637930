import React, { useEffect, useRef, memo } from "react";

const SenseAudio = memo(({ audioEnabled }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (!audioRef.current) return;
    audioRef.current.muted = !audioEnabled;
  }, [audioEnabled]);

  return (
    <>
      <audio ref={audioRef} className="sense-audio" preload="none" loop>
        <source
          src="https://static.highsnobiety.com/dato/40484/1704928175-sense-background-audio.mp3"
          type="audio/mpeg"
        />
        Your browser does not support the audio tag.
      </audio>
    </>
  );
});

export default SenseAudio;

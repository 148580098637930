import React, { memo } from "react";
import { Container } from "../styles/components/Global/Video";
import SenseVideo from "./Video";
import { COLOURS } from "../styles/variables/Colors";

const PDPVideo = memo((sense) => {
  const { media, audioEnabled, toggleAudio } = sense;

  return (
    <Container id="sense-video" $align="flex-start" $height="200vh">
      <Container $height="100vh" $background={COLOURS.primary.black}>
        <Container $height="100vh" className="pdp-video">
          <SenseVideo
            audioEnabled={audioEnabled}
            toggleAudio={toggleAudio}
            videoSrc={media.video.source}
            videoPoster={media.video.poster}
            pdp={true}
          />
        </Container>
      </Container>
    </Container>
  );
});

export default PDPVideo;

import styled from "styled-components";
import { BREAKPOINTS, hiddensm } from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Container = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify || "center",
      align: props.$align || "center",
    })};
  ${setPosition({ position: "relative" })};
  height: ${(props) => props.$height || "250vh"};
  width: 100%;
  ${(props) => props.$background && `background-color: ${props.$background}`};
`;

export const AudioLabelContainer = styled.div`
  ${setPosition({ position: "relative" })};
  margin-right: 2rem;
  width: fit-content;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0.5rem 0;
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.white};
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin: 0 0.9rem 0 0;
  text-align: left;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    text-align: center;
  }
`;

export const SmallHeading = styled(Headline)`
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 0;
  mix-blend-mode: difference;
  pointer-events: none;
  ${(props) => props.$nowrap && "white-space: nowrap"};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$noPointers && "pointer-events: none"};
`;

export const EnableAudioContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ align: "center" })};
  margin-top: 1.25rem;
  text-align: center;
  opacity: 0;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
    padding: 0 1rem;
  }
`;

export const AudioContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({})};
  ${(props) => props.$margin && "margin: 0 0.3rem"};
  text-align: center;
`;

export const AudioLabel = styled.label`
  ${setPosition({ position: "relative" })};
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 0;
  cursor: pointer;
  ${(props) => props.$nowrap && "white-space: nowrap"};
`;

export const AudioInput = styled.input`
  display: none;
`;

export const CheckboxWrapper = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
`;

export const CheckboxContainer = styled.div`
  width: 50px;
  height: 30px;
`;

export const CrossCheckbox = styled.span`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "absolute", top: "0", left: "0" })};
  font-family: frekuent-mono-regular;
  width: 100%;
  height: 100%;
  border: 1px solid ${COLOURS.primary.white};
  background-color: transparent;
  font-size: 0.6rem;
  line-height: 0.8rem;
  text-align: center;
  cursor: pointer;
  color: ${COLOURS.primary.white};
`;

export const VideoControlsContainer = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: "5" })};
  ${setFlex({})};
  height: fit-content;
  margin: 1.25rem;
  cursor: pointer;
  opacity: 0;
`;

export const ControlsContainer = styled.div`
  ${setPosition({ position: "absolute", bottom: "0", left: "0" })};
  margin: ${(props) => (props.$glitch ? "2rem" : "0 2rem 0.8rem 2rem")};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 1.5rem;
  }
  ${(props) => props.$flex && setFlex({ direction: "row", align: "flex-end" })};
`;

export const Control = styled.div`
  margin-right: 0.6rem;
  pointer-events: none;
`;

export const VideoContainer = styled.div`
  ${setPosition({ position: "relative", top: "0", left: "0" })};
  ${(props) =>
    !props.$experience && `background-color: ${COLOURS.primary.black}`};
  height: ${(props) => (props.$pdp ? "100dvh" : "fit-content")};
  ${(props) =>
    props.$experience &&
    `
    min-width: 30vw;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      min-width: unset;
      height: auto;
    }
    video {
      max-height: 80vh;
    }
  `};
  ${(props) =>
    props.$loader &&
    `
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 100vh;
  }
  `};
  ${(props) =>
    props.$pdp &&
    `
    video {
      max-height: 95vh;
    }
  `};
  ${(props) =>
    props.$loader &&
    `
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 100vh;
  }
  `};
  width: 100%;
  overflow: hidden;
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${setFlex({ align: "center", justify: "center" })};
  }
`;

export const Video = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ justify: "center" })};
  width: ${(props) => (props.$experience ? "auto" : "100%")};
  ${(props) =>
    !props.$experience &&
    `
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ align: "center" })};
    height: 100%;
  }
  `};
  height: auto;
  ${(props) => props.$glitch && "max-height: 100vh"};
  margin: 0 auto;
  max-width: 100vw;
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      object-fit: contain;
    }
    &[poster] {
      object-fit: ${(props) => (props.$full ? "cover" : "contain")};
      height: ${(props) =>
        props.$experience || props.$checkout ? "auto" : "100vh"};
      width: 100%;
      @media (max-width: ${BREAKPOINTS.sm}px) {
        object-fit: contain;
      }
    }
  }
`;

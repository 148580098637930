export const fragmentShader = () => {
  return `
      uniform float u_time;
      uniform float progress;
      uniform sampler2D texture1;
      varying vec2 vUv;
      varying vec3 vPosition;
      float PI = 3.141592653589793238;
      void main()	{

        gl_FragColor = vec4(1.,1.,1.,1.);
      }
    `;
};

export const vertexParticlesShader = () => {
  return `
    uniform float u_time;
    varying vec2 vUv;
    varying vec3 vPosition;
    uniform sampler2D u_positionTexture;
    attribute vec2 reference;
    float PI = 3.141592653589793238;
    uniform float u_frequency;
    uniform float u_amplitude;
    uniform float u_maxDistance;
    

    vec3 mod289(vec3 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec2 mod289(vec2 x) {
      return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec3 permute(vec3 x) {
      return mod289(((x*34.0)+1.0)*x);
  }

    float noise(vec2 v)
    {
        const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                          0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                        -0.577350269189626,  // -1.0 + 2.0 * C.x
                          0.024390243902439); // 1.0 / 41.0
        // First corner
        vec2 i  = floor(v + dot(v, C.yy) );
        vec2 x0 = v -   i + dot(i, C.xx);

        // Other corners
        vec2 i1;
        //i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
        //i1.y = 1.0 - i1.x;
        i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
        // x0 = x0 - 0.0 + 0.0 * C.xx ;
        // x1 = x0 - i1 + 1.0 * C.xx ;
        // x2 = x0 - 1.0 + 2.0 * C.xx ;
        vec4 x12 = x0.xyxy + C.xxzz;
        x12.xy -= i1;

        // Permutations
        i = mod289(i); // Avoid truncation effects in permutation
        vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
            + i.x + vec3(0.0, i1.x, 1.0 ));

        vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
        m = m*m ;
        m = m*m ;

        // Gradients: 41 points uniformly over a line, mapped onto a diamond.
        // The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)

        vec3 x = 2.0 * fract(p * C.www) - 1.0;
        vec3 h = abs(x) - 0.5;
        vec3 ox = floor(x + 0.5);
        vec3 a0 = x - ox;

        // Normalise gradients implicitly by scaling m
        // Approximation of: m *= inversesqrt( a0*a0 + h*h );
        m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );

        // Compute final noise value at P
        vec3 g;
        g.x  = a0.x  * x0.x  + h.x  * x0.y;
        g.yz = a0.yz * x12.xz + h.yz * x12.yw;
        return 130.0 * dot(m, g);
    }

    vec3 curl(float	x,	float	y,	float	z)
    {

        float	eps	= 1., eps2 = 2. * eps;
        float	n1,	n2,	a,	b;

        x += u_time * .05;
        y += u_time * .05;
        z += u_time * .05;

        vec3	curl = vec3(0.);

        n1	=	noise(vec2( x,	y	+	eps ));
        n2	=	noise(vec2( x,	y	-	eps ));
        a	=	(n1	-	n2)/eps2;

        n1	=	noise(vec2( x,	z	+	eps));
        n2	=	noise(vec2( x,	z	-	eps));
        b	=	(n1	-	n2)/eps2;

        curl.x	=	a	-	b;

        n1	=	noise(vec2( y,	z	+	eps));
        n2	=	noise(vec2( y,	z	-	eps));
        a	=	(n1	-	n2)/eps2;

        n1	=	noise(vec2( x	+	eps,	z));
        n2	=	noise(vec2( x	+	eps,	z));
        b	=	(n1	-	n2)/eps2;

        curl.y	=	a	-	b;

        n1	=	noise(vec2( x	+	eps,	y));
        n2	=	noise(vec2( x	-	eps,	y));
        a	=	(n1	-	n2)/eps2;

        n1	=	noise(vec2(  y	+	eps,	z));
        n2	=	noise(vec2(  y	-	eps,	z));
        b	=	(n1	-	n2)/eps2;

        curl.z	=	a	-	b;

        return	curl;
    }


    void main() {
      vUv = reference;
      // vec3 pos = texture(u_positionTexture, reference).xyz;
      vec3 newpos = position;

      vec3 target = position + curl( newpos.x * u_frequency, newpos.y * u_frequency, newpos.z * u_frequency ) * u_amplitude;

      float d = length( newpos-target ) / u_maxDistance;
      newpos = mix( position, target, pow( d, 5. ) );

      vec4 mvPosition = modelViewMatrix * vec4( newpos, 1. );
      gl_PointSize = 50. * ( 1. / - mvPosition.z );
      gl_Position = projectionMatrix * mvPosition;
    }
    `;
};

export const fragmentSimulation = () => {
  return `
      uniform float u_time;
      uniform float delta;
      uniform sampler2D texturePosition;

      void main()	{

        vec2 uv = gl_FragCoord.xy / resolution.xy;
        vec4 tmpPos = texture2D( texturePosition, uv );
        vec3 pos = tmpPos.xyz;
        float f = 1.;
        float u_amplitude = 0.0001;
        gl_FragColor = vec4( pos , 1. );

      }
    `;
};

import React from "react";
import { Wrapper } from "../styles/components/Pages/Pages";
import { ThemeProvider } from "styled-components";
import { THEMES } from "../styles/variables/Themes";
import Navigation from "./Navigation";
import Vision from "./Page-Vision";

const Pages = (senses) => {
  const { audioEnabled, toggleAudio } = senses;

  return (
    <Wrapper id="sense-pages">
      <ThemeProvider theme={THEMES.light}>
        <Navigation {...senses} pages={true} />
      </ThemeProvider>
      <Vision
        {...senses}
        audioEnabled={audioEnabled}
        toggleAudio={toggleAudio}
      />
    </Wrapper>
  );
};

export default Pages;

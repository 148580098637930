import styled from "styled-components";
import { COLOURS, BACKGROUNDS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";
import {
  BREAKPOINTS,
  hiddenmd,
  hiddensm,
  mobileXs,
} from "../../variables/Breakpoints";

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => props.theme.colour};
  font-size: 0.75rem;
  line-height: 0.9rem;
  margin: 0;
  text-align: left;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 0.55rem;
    line-height: 0.75rem;
  }
`;

export const NavigationWrapper = styled.div`
  height: 100%;
  width: 100%;
  ${(props) => props.$blend && "mix-blend-mode: difference"};
  ${(props) => props.$absolute && setPosition({ position: "absolute" })};
`;

export const NavigationContainer = styled.div`
  ${setPosition({ position: "fixed", top: "0", zIndex: 20 })};
  ${setFlex({})};
  width: -webkit-fill-available;
  width: -moz-available;
  margin: 1.25rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: ${(props) => (!props.$full ? "auto" : "100%")};
    min-height: ${(props) => (!props.$full ? "15vh" : "unset")};
    margin: 0.6rem;
  }
  overflow: hidden;
  ${(props) =>
    props.$full &&
    `
    height: -webkit-fill-available;
    @supports (-moz-appearance:none) {
      height: 100%;
    }
  `};
`;

export const Border = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "relative" })};
  border: 1px solid ${(props) => props.theme.border};
  width: 100%;
  margin: 1.25rem;
  > svg {
    ${setPosition({ position: "absolute" })};
  }
  pointer-events: none;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "unset", align: "unset" })};
    margin: 0.6rem;
  }
`;

export const Column = styled.div`
  ${setPosition({ position: "relative" })};
  width: ${(props) => props.$width || "50%"};
  ${(props) =>
    props.$flex &&
    setFlex({ align: props.$align, direction: props.$direction })};
  height: ${(props) => (props.$full ? "100%" : "fit-content")};
`;

export const LeftColumn = styled.div`
  ${setPosition({ position: "relative" })};
  height: ${(props) => (props.$full ? "100%" : "fit-content")};
  width: 50%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
  }
`;

export const RightColumn = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ align: "flex-end", direction: "column" })};
  height: ${(props) => (props.$full ? "100%" : "fit-content")};
  width: 50%;
  ${(props) => props.$hiddensm && hiddensm};
`;

export const LeftContainer = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  min-height: 40px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    min-height: 30px;
  }
  ${(props) =>
    props.$plp &&
    `
    @media (max-width: ${BREAKPOINTS.xs}px) {
      border-right: 1px solid ${props.theme.border};
    }
  `};
  ${(props) => props.$hiddenmd && hiddenmd};
`;

export const LeftNavigation = styled.div`
  ${setFlex({ justify: "stretch" })};
  ${setPosition({ position: "relative" })};
  border-bottom: 1px solid ${(props) => props.theme.border};
  border-right: 1px solid ${(props) => props.theme.border};
  width: fit-content;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "absolute" })};
    border-right-width: 0;
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  border-bottom: 1px solid ${(props) => props.theme.border};
  border-left: ${(props) =>
    props.$plp
      ? `2.5px solid ${props.theme.colour}`
      : `1px solid ${props.theme.border}`};
  width: fit-content;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    border-left-width: 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    border-bottom-width: 0;
  }
  @media (max-width: ${BREAKPOINTS.xxs}px) {
    ${setFlex({ justify: "flex-end" })};
    width: 100%;
  }
`;

export const RightNavigation = styled.div`
  ${setFlex({ justify: "flex-end" })};
  ${setPosition({ position: "relative", zIndex: 3 })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
  }
  min-height: 40px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    min-height: 30px;
  }
`;

export const MobileNavigation = styled.div`
  height: 100%;
  width: 100%;
  display: none;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({})};
  }
`;

export const Logo = styled.img`
  width: auto;
  max-width: 100%;
  margin: 0 0.6rem;
  padding: 0.3rem;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: unset;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-height: 12px;
  }
  @media (max-width: ${BREAKPOINTS.xxs}px) {
    margin: 0 0.4rem;
    padding: 0;
  }
`;

export const BannerHeading = styled(Headline)`
  color: ${(props) => props.theme.colour};
  margin: 0 0.6rem;
  padding: 0.3rem;
  text-align: unset;
  ${(props) => props.$hiddensm && hiddensm};
`;

export const Menu = styled(Headline)`
  ${setPosition({ position: "relative", zIndex: 6 })};
  color: ${(props) => props.theme.colour};
  margin: ${(props) => (props.$removeMargin ? "0" : "0 0.6rem 0 1.25rem")};
  padding: 0.3rem;
  text-align: unset;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 0;
    margin: 0 0.6rem;
  }
  ${(props) => props.$bold && "font-weight: 800;"};
  ${(props) =>
    props.$pdp &&
    `
    &::after {
      content: ":";
    }
    @media (max-width: ${BREAKPOINTS.sm}px) {
      &::after {
        content: none;
      }
    }
  `};
`;

export const Selection = styled(Menu)`
  ${setFlex({ align: "center" })};
  font-weight: 800;
  margin-left: 0;
  ${hiddensm};
`;

export const AudioBars = styled.svg`
  overflow: hidden;
  outline: none;
  cursor: pointer;
  rect {
    fill: ${(props) => props.theme.colour || COLOURS.primary.white};
  }
  width: ${(props) => (props.$checkout ? "30px" : "45px")};
  height: ${(props) => (props.$checkout ? "20px" : "45px")};
  ${(props) =>
    props.$checkout &&
    `
    margin: 0 0 0.45rem 0.6rem;
    padding: 0 0 0.3rem 0.3rem;

    @media (max-width: ${BREAKPOINTS.md}px) {
      padding: 0;
      margin-bottom: 0.7rem;
    }
 `};
`;

export const DropDown = styled.div`
  ${setPosition({ position: "relative" })};
  ${(props) =>
    setFlex({
      justify: "center",
      direction: props.$checkout ? "row" : "column",
    })};
  min-width: 200px;
  ${(props) =>
    props.$border && `border-left: 2.5px solid ${COLOURS.primary.blue}`};
  height: 100%;
  @media (max-width: ${BREAKPOINTS.md}px) {
    min-width: unset;
    border-left-width: 0;
  }
`;

export const SelectedItem = styled.div`
  ${setPosition({ position: "absolute" })};
  ${setFlex({ justify: "center", align: "center" })};
  top: 100%;
  width: 100%;
  height: 100%;
  border: 1px solid ${COLOURS.primary.white};
  border-top-width: 0;
  border-right-width: 2.5px;
  box-sizing: border-box;
  ${mobileXs}
`;

export const PlayerContainer = styled.div`
  ${setFlex({ align: "center" })};
  height: 100%;
`;

export const LinkContainer = styled.div`
  ${setFlex({ align: "center" })};
  height: 100%;
  ${(props) =>
    props.$border && `border-left: 2.5px solid ${props.theme.colour}`};
  ${(props) =>
    props.$pdp &&
    `
    border-right: 2.5px solid ${props.theme.colour};
    @media (max-width: ${BREAKPOINTS.sm}px) {
      border-left: 1px solid ${props.theme.colour};
    }
    `};
  ${(props) => props.$checkout && hiddensm};
`;

export const LinkList = styled.div`
  ${setFlex({ justify: "flex-end", align: "center" })};
  ${(props) => props.$plp && "min-width: 300px"};
  height: 100%;
  @media (max-width: ${BREAKPOINTS.md}px) {
    min-width: unset;
    margin-right: 0.6rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-right: 0;
  }
`;

export const VerticalBanner = styled.div`
  ${setFlex({ justify: "space-between" })};
  ${setPosition({ position: "absolute", right: -2, bottom: -1, zIndex: 2 })};
  writing-mode: vertical-lr;
  text-orientation: mixed;
  height: 100%;
  ${(props) => props.$hiddensm && hiddensm};
`;

export const HorizontalSeparator = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 2 })};
  height: 2.5px;
  width: 50%;
  background-color: ${(props) => props.theme.colour};
`;

export const SmallHeading = styled(Headline)`
  color: ${COLOURS.primary.black};
  padding: 0.6rem;
  font-size: 0.5rem;
  pointer-events: none;
`;

export const SVGWrapper = styled.div`
  ${setPosition({ position: "relative" })};
  width: 100%;
  height: 100%;
  max-height: 85vh;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 90vh;
    max-height: unset;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-height: 90vh;
    height: 100%;
  }
`;

export const SVGContainer = styled.div`
  ${setPosition({ position: "absolute" })};
  ${setFlex({ align: "center" })};
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    svg {
      path {
        stroke-width: 0.8px;
      }
    }
  }
`;

export const Banner = styled.div`
  svg {
    margin-bottom: 0.9rem;
  }
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", right: "0", zIndex: -1 })};
  background-color: ${(props) => props.$background || COLOURS.primary.blue};
  min-height: 50px;
`;

export const SideLine = styled.div`
  ${setFlex({ align: "baseline", direction: "row-reverse" })};
  ${setPosition({ position: "relative", bottom: "0", right: -5, zIndex: 2 })};
  background: ${BACKGROUNDS.primary};
  padding: 0.15rem;
  mix-blend-mode: difference;
  > h4 {
    padding: 0;
  }
`;

export const Hidden = styled.span`
  opacity: 0;
  pointer-events: none;
`;

import React from "react";
import { Wrapper, Container } from "../styles/components/PDP/Global";
import { ThemeProvider } from "styled-components";
import { THEMES } from "../styles/variables/Themes";
import PDPDisplay from "./PDP-Display";
import PDPVideo from "./PDP-Video";
import PDPDescription from "./PDP-Description";
import PDPBenefits from "./PDP-Benefits";
import PDPTech from "./PDP-Tech";
import PDPPoster from "./PDP-Poster";
import Navigation from "./Navigation";

const PDPLanding = (sense) => {
  return (
    <Wrapper id={sense.id + "-chip"}>
      <Container $height="auto">
        <ThemeProvider theme={THEMES.light}>
          <Navigation {...sense} pdp={true} />
        </ThemeProvider>
        <PDPDisplay {...sense} />
      </Container>
      <Container $index={3} $align="flex-start">
        <PDPVideo {...sense} />
      </Container>
      <Container $margin>
        <PDPDescription {...sense} />
      </Container>
      <Container $height="300vh" $margin>
        <PDPBenefits {...sense} />
      </Container>
      <Container $height="300vh" $margin>
        <PDPTech {...sense} />
      </Container>
      <Container
        id="sense-poster"
        $height="150vh"
        $align="flex-start"
        $mobileStrict
      >
        <PDPPoster {...sense} />
      </Container>
    </Wrapper>
  );
};

export default PDPLanding;

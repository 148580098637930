import gsap from "../../../components/Animations";
import {
  triggerAvaMessage,
  senseIpadSettings,
  senseDesktopSettings,
  senseLandingAnimation,
  senseVideoAnimation,
  senseDescriptionAnimation,
  senseBenefitAnimation,
  senseTechAnimation,
  senseBenefitsFooterMobile,
  senseTechFooterMobile,
  senseExperience,
  sensePosterPosition,
  sensePosterVideo,
  mm,
} from "./Modules";
import { BREAKPOINTS } from "../../../styles/variables/Breakpoints";

function setSpecificSenseAnimation(chip, animations, stage, tl) {
  const mediaMatcher = mm();
  if (stage in animations) {
    const specificAnimations = animations[stage];

    specificAnimations.forEach((animationProps) => {
      for (const propertyName in animationProps) {
        if (animationProps.hasOwnProperty(propertyName)) {
          const props = animationProps[propertyName];

          const targetMappings = {
            position: chip.position,
            rotation: chip.rotation,
            scale: chip.scale,
          };

          const target = targetMappings[propertyName];

          if (target) {
            const { p, ...animation } = props;
            const params = { ...animation };
            mediaMatcher.add(`(min-width: ${BREAKPOINTS.md}px)`, () => {
              tl.to(target, params, p);
            });
          }
        }
      }
    });
  }
}

function senseSpecificLanding(chip) {
  const tl = gsap.timeline();
  senseLandingAnimation(tl, chip);
  return tl;
}

function senseSpecificVideo(chip) {
  const tl = gsap.timeline();
  senseVideoAnimation(tl, chip);
  return tl;
}

function senseSpecificDescription(chip, animations) {
  const tl = gsap.timeline();
  const stage = 2;
  senseDescriptionAnimation(tl, chip);
  setSpecificSenseAnimation(chip, animations, stage, tl);
  return tl;
}

function senseSpecificBenefits(chip) {
  const tl = gsap.timeline();
  senseBenefitAnimation(tl, chip);
  return tl;
}

function senseSpecificTech(chip) {
  const tl = gsap.timeline();
  senseTechAnimation(tl, chip);
  return tl;
}

function senseSpecificPoster(chip, animations) {
  const tl = gsap.timeline();
  const stage = 5;
  setSpecificSenseAnimation(chip, animations, stage, tl);
  return tl;
}

export function createAnimations(PDP, animations) {
  const { chip, PLP, context } = PDP;
  const target = document.getElementById(`${chip.userData.id}-chip`);

  if (!target) return;

  const landingContainer = document.getElementById("sense-display");
  const navigationContainer = target?.querySelector(".pdp-navigation");

  const videoContainer = document.getElementById("sense-video");
  const videoTrigger = videoContainer?.querySelector(".pdp-video");
  const video = videoContainer?.querySelector(".video");

  const descriptionContainer = document.getElementById("sense-description");
  const descriptionTrigger = descriptionContainer?.querySelector(
    ".pdp-description"
  );

  const benefitsContainer = document.getElementById("sense-benefits");
  const benefitsTitle = benefitsContainer?.querySelector(".pdp-benefits");
  const benefitsFooter = benefitsContainer?.querySelector(".footer");

  const techContainer = document.getElementById("sense-tech");
  const techTitle = techContainer?.querySelector(".pdp-tech");
  const techFooter = techContainer?.querySelector(".footer");

  const posterContainer = document.getElementById("sense-poster");
  const sensePosterContainer = posterContainer?.querySelector(
    "#sense-poster-container"
  );
  const posterWrapper = posterContainer?.querySelector(".poster-wrapper");
  const posterTrigger = posterContainer?.querySelector(".pdp-poster");
  const posterVideo = posterContainer?.querySelector(".video");
  const experiencePoster = posterContainer?.querySelector(".experience-poster");
  const posterColumnLeft = posterContainer?.querySelector(".pdp-column-left");
  const posterLoading = posterContainer?.querySelector(".poster-loading");
  const posterColumnRight = posterContainer?.querySelector(".pdp-column-right");
  const posterColumnRightSvgs = posterColumnRight?.querySelectorAll(
    "rect, path, line, circle"
  );

  const mediaMatcher = mm();

  let avaFirst = false,
    avaSecond = false,
    avaThird = false,
    completed = false;

  senseIpadSettings(benefitsFooter, techFooter, posterVideo);
  senseDesktopSettings(videoTrigger, posterVideo);

  const triggerOnce = (condition, action) => {
    if (!condition) {
      action();
      return true;
    }
    return condition;
  };

  const scrollTriggerConfig = {
    start: "top top",
    end: "bottom bottom",
    scrub: true,
    pin: context.isMobile ? false : true,
    anticipatePin: true,
    invalidateOnRefresh: true,
  };

  PDP.ctx.add(() => {
    const landingTL = gsap.timeline({
      scrollTrigger: {
        id: "sense-landing",
        trigger: target,
      },
    });

    landingTL.add(senseSpecificLanding(chip)).add(() => {
      avaFirst = triggerOnce(avaFirst, () => triggerAvaMessage(PLP, true));
    });

    gsap.scrambleAnimation(navigationContainer);
    gsap.scrambleAnimation(landingContainer);

    const videoTl = gsap
      .timeline({
        scrollTrigger: {
          id: "sense-video",
          trigger: videoContainer,
          start: "top top",
          end: "bottom bottom",
          pin: true,
          scrub: context.isMobile ? true : false,
          anticipatePin: 1,
          toggleActions: context.isMobile
            ? "play none none none"
            : "play none reverse reverse",
          onEnter: () => video.play(),
          onEnterBack: () => video.play(),
          onLeave: () => {
            if (video.currentTime > 0) video.pause();
          },
          onLeaveBack: () => {
            if (video.currentTime > 0) video.pause();
          },
        },
      })
      .add(senseSpecificVideo(chip));

    mediaMatcher.add(`(min-width: ${BREAKPOINTS.sm}px)`, () => {
      videoTl.to(videoTrigger, { scale: 1, opacity: 1, duration: 1 }, 0);
    });

    gsap
      .timeline({
        scrollTrigger: {
          id: "sense-description",
          trigger: descriptionContainer,
          start: () => (context.isMobile ? "top-=50 top" : "top top"),
          end: () => !context.isMobile && "bottom bottom",
          scrub: true,
          pin: context.isMobile ? false : true,
          anticipatePin: true,
          invalidateOnRefresh: true,
        },
      })
      .add(() => {
        if (!context.isMobile) gsap.scrambleAnimation(descriptionTrigger);
        if (context.isMobile)
          gsap.drawBlock(descriptionContainer?.querySelectorAll(".block"));
        gsap.drawMask(descriptionContainer?.querySelectorAll(".mask"));
      })
      .add(senseSpecificDescription(chip, animations));

    gsap
      .timeline({
        scrollTrigger: {
          id: "sense-benefits",
          trigger: benefitsContainer,
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (!context.isMobile) gsap.scrambleAnimation(benefitsContainer);
        if (context.isIpad) {
          gsap.scrambleAnimation(benefitsTitle);
          senseBenefitsFooterMobile(benefitsFooter);
        }
        if (context.isMobile)
          gsap.drawBlock(benefitsContainer?.querySelectorAll(".block"));
      })
      .add(senseSpecificBenefits(chip))
      .add(() => {
        if (!context.isDesktop) return;
        avaSecond = triggerOnce(avaSecond, () =>
          triggerAvaMessage(PLP, true, 2)
        );
      });

    gsap
      .timeline({
        scrollTrigger: {
          id: "sense-tech",
          trigger: techContainer,
          ...scrollTriggerConfig,
        },
      })
      .add(() => {
        if (!context.isMobile) gsap.scrambleAnimation(techContainer);
        if (context.isIpad) {
          gsap.scrambleAnimation(techTitle);
          senseTechFooterMobile(techFooter);
        }
        if (context.isMobile)
          gsap.drawBlock(techContainer?.querySelectorAll(".block"));
      })
      .add(senseSpecificTech(chip));

    mediaMatcher.add(`(min-width: ${BREAKPOINTS.sm}px)`, () => {
      gsap
        .timeline({
          scrollTrigger: {
            id: "sense-poster",
            trigger: posterContainer,
            start: "top top",
            end: "+=500",
            scrub: true,
          },
        })
        .add(senseSpecificPoster(chip, animations));
    });

    posterVideo?.load();

    gsap
      .timeline({
        scrollTrigger: {
          id: "sense-video-poster",
          trigger: sensePosterContainer,
          start: "top top",
          end: "bottom bottom",
          pin: true,
          pinSpacing: false,
          anticipatePin: 1,
        },
      })
      .add(() => {
        if (!context.isMobile) {
          gsap.scrambleAnimation(posterColumnLeft);
        }
        gsap.drawSVG(posterColumnRightSvgs);
      })
      .add(() => {
        if (completed) return;

        const tl = gsap.timeline({ once: true });

        sensePosterPosition(tl, posterTrigger, posterWrapper);

        tl.then(() => {
          avaThird = triggerOnce(avaThird, () =>
            triggerAvaMessage(PLP, true, 3, false, false, 0)
          );

          PLP.chatAnimationTl.then(() => {
            if (PLP.chatAnimationTl.data.state === 3) {
              sensePosterVideo(tl, posterVideo, experiencePoster);
              if (posterVideo) {
                posterVideo.play();
                posterLoading.classList.remove("active");
                gsap.scrambleAnimation(posterColumnRight);
                posterVideo.onended = () => {
                  senseExperience(PDP, PLP);
                  completed = true;
                };
              } else {
                console.error("posterVideo is null.");
              }
            }
          });
        });
      });
  });
}

import React, { useEffect, useRef, memo } from "react";
import { AudioBars } from "../styles/components/Global/Navigation";
import gsap from "./Animations";
import SenseAudio from "./Audio";

const Player = memo(({ audioEnabled, toggleAudio, checkout }) => {
  const musicRef = useRef(null);
  const timelineRef = useRef(null);

  const mutedState = () => {
    const bars = musicRef.current?.querySelectorAll(".bar");
    bars.forEach((bar) => {
      gsap.to(bar, {
        y: 0,
        duration: 0.5,
        ease: "power1.inOut",
      });
    });
  };

  const toggleMute = () => {
    if (timelineRef.current.isActive()) {
      timelineRef.current.pause();
      mutedState();
    } else {
      timelineRef.current.play();
    }

    toggleAudio(!audioEnabled);
  };

  useEffect(() => {
    timelineRef.current = gsap.timeline({ paused: !audioEnabled });

    const bars = musicRef.current?.querySelectorAll(".bar");
    bars.forEach((bar) => {
      timelineRef.current.to(
        bar,
        {
          y: () => -Math.random() * 10,
          duration: () => 0.2 + Math.random() * 0.3,
          repeat: -1,
          yoyo: true,
          ease: "power1.inOut",
        },
        0
      );
    });

    return () => {
      timelineRef.current?.kill();
      bars.forEach((bar) => {
        gsap.set(bar, { clearProps: "all" });
      });
    };
  }, []);

  useEffect(() => {
    if (audioEnabled) {
      timelineRef.current.resume();
    } else {
      timelineRef.current.pause();
      mutedState();
    }
  }, [audioEnabled]);

  return (
    <>
      {checkout && <SenseAudio audioEnabled={audioEnabled} />}
      <AudioBars
        ref={musicRef}
        onClick={toggleMute}
        viewBox="0 0 20 20"
        $checkout={checkout}
      >
        <defs>
          <rect id="rect" y="18" width="2" height="18" />
        </defs>
        <use className="bar" href="#rect" x="0" />
        <use className="bar" href="#rect" x="3" />
        <use className="bar" href="#rect" x="6" />
        <use className="bar" href="#rect" x="9" />
      </AudioBars>
    </>
  );
});

export default Player;

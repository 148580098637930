import React from "react";
import { Color, TitleBorder, Hidden } from "../styles/components/PDP/Global";
import {
  Wrapper,
  ContainerOverlay,
  OverlayColumn,
  LandingContainer,
  HeadingWrapper,
  HeadingContain,
  HeadingContainer,
  MaskContainer,
  Mask,
  QRCode,
  ProductNo,
  SmallHeading,
  BlendTitleContainer,
  FloatText,
  SVGContainer,
  Title,
  TitleFooter,
  VerticalText,
  ColorBlock,
  LandingWrapper,
  FloatContainer,
  TitleFooterContainer,
  TitleFloatContainer,
  MiniHeading,
  DisplayTitle,
  ProductInfoWrapper,
  SVGWrapper,
  ProductTitle,
  SecondaryTitleContainer,
  Link,
} from "../styles/components/PDP/Display";
import { PlusSvg, DisplayBorder } from "./SVGs";
import { COLOURS } from "../styles/variables/Colors";

const PDPDisplay = (sense) => {
  const { id, title, heading, subtitle, banner, code, secondaryCode } = sense;
  const { qrcode, chip, copyright, symbol } = sense.global;

  return (
    <Wrapper id="sense-display">
      <ContainerOverlay>
        <OverlayColumn>
          <LandingWrapper>
            <LandingContainer>
              <HeadingWrapper>
                <TitleBorder $lateral>
                  <HeadingContainer $align="center" $hiddensm>
                    <SmallHeading
                      className="scramble-delay"
                      $setcolour={COLOURS.primary.white}
                      $width={200}
                    >
                      <Hidden className="text">{banner}</Hidden>
                    </SmallHeading>
                    <HeadingContain $align="center" $margin $ipad>
                      <symbol.icon />
                    </HeadingContain>
                  </HeadingContainer>
                  <HeadingContainer $flex>
                    <HeadingContain $align="center" $fitWidth>
                      <Link href={qrcode.url} target="_blank">
                        <QRCode
                          className="fade-image"
                          src={qrcode.source}
                          alt={qrcode.alt}
                          loading="lazy"
                          $hiddensm
                        />
                      </Link>
                    </HeadingContain>
                    <ProductInfoWrapper>
                      <MaskContainer $margin="left">
                        <Mask className="mask" width="100" $desktop />
                        <ProductTitle className="scramble" $hiddensm>
                          <Hidden className="text">Product</Hidden>
                        </ProductTitle>
                        <SmallHeading
                          className="scramble"
                          $setcolourMobile={COLOURS.primary.white}
                        >
                          <Hidden className="text">
                            Product <br></br> 0{id}
                          </Hidden>
                        </SmallHeading>
                        <ProductNo $hiddensm>
                          <Color
                            className="scramble"
                            $setcolour={COLOURS.primary.blue}
                          >
                            <Hidden className="text">{id}</Hidden>
                          </Color>
                        </ProductNo>
                        <SmallHeading
                          className="scramble"
                          $setcolourMobile={COLOURS.primary.white}
                          $mobile
                        >
                          <Hidden className="text">NO.</Hidden>
                        </SmallHeading>
                      </MaskContainer>
                      <HeadingContain $align="center" $margin $mobile>
                        <symbol.icon />
                      </HeadingContain>
                    </ProductInfoWrapper>
                    <HeadingContain $align="center" $margin $hiddenxl>
                      <symbol.icon />
                    </HeadingContain>
                  </HeadingContainer>
                </TitleBorder>
              </HeadingWrapper>
              <HeadingContainer $flex>
                <Mask className="mask" width="100" />
                <DisplayTitle>
                  <Title
                    className="scramble"
                    $margin="left"
                    $setcolour={COLOURS.primary.black}
                  >
                    <Hidden className="text">{title}</Hidden>
                  </Title>
                  <HeadingContain $direction="column">
                    <SmallHeading className="scramble">
                      <Hidden className="text">Sense</Hidden>
                    </SmallHeading>
                    <SmallHeading className="scramble">
                      <Hidden className="text">©</Hidden>
                    </SmallHeading>
                  </HeadingContain>
                </DisplayTitle>
              </HeadingContainer>
              <BlendTitleContainer>
                <TitleBorder>
                  <FloatContainer>
                    <FloatText $hiddensm>
                      <MiniHeading
                        className="scramble-delay"
                        $setcolour={COLOURS.primary.white}
                      >
                        <Hidden className="text">{code}</Hidden>
                      </MiniHeading>
                      <MiniHeading
                        className="scramble-delay"
                        $setcolour={COLOURS.primary.white}
                      >
                        <Hidden className="text">{code}</Hidden>
                      </MiniHeading>
                    </FloatText>
                    <SVGWrapper $mobile>
                      <PlusSvg />
                    </SVGWrapper>
                    <SecondaryTitleContainer>
                      <Title className="scramble">
                        <Hidden className="text">{heading}</Hidden>
                      </Title>
                      {subtitle && (
                        <Title className="scramble" $mobileFlex>
                          <Hidden className="text">{subtitle}</Hidden>
                        </Title>
                      )}
                    </SecondaryTitleContainer>
                  </FloatContainer>
                  <TitleFloatContainer>
                    <TitleFooter>
                      <HeadingContain $direction="column">
                        <SmallHeading
                          className="scramble-delay"
                          $setcolour={COLOURS.primary.white}
                          $width={100}
                          $margin="left"
                        >
                          <Hidden className="text">{chip}</Hidden>
                        </SmallHeading>
                        <SVGContainer>
                          <DisplayBorder />
                        </SVGContainer>
                      </HeadingContain>
                      <FloatText $mobile>
                        <MiniHeading
                          className="scramble-delay"
                          $setcolour={COLOURS.primary.white}
                        >
                          <Hidden className="text">
                            {secondaryCode}
                            {code}
                          </Hidden>
                        </MiniHeading>
                        <MiniHeading
                          className="scramble-delay"
                          $setcolour={COLOURS.primary.white}
                        >
                          <Hidden className="text">{code}</Hidden>
                        </MiniHeading>
                      </FloatText>
                    </TitleFooter>
                  </TitleFloatContainer>
                  <TitleFooterContainer>
                    <TitleFooter $flex>
                      <VerticalText>
                        <SVGWrapper $hiddensm>
                          <PlusSvg />
                        </SVGWrapper>
                        <MiniHeading
                          className="scramble-delay"
                          $setcolour={COLOURS.primary.white}
                          $margin="top"
                        >
                          <Hidden className="text">{secondaryCode}</Hidden>
                        </MiniHeading>
                      </VerticalText>
                      <HeadingContain $hiddensm>
                        <ColorBlock className="block" width="200" />
                        <MiniHeading
                          className="scramble-delay"
                          $setcolour={COLOURS.primary.white}
                          $margin
                        >
                          <Hidden className="text">{copyright}</Hidden>
                        </MiniHeading>
                      </HeadingContain>
                    </TitleFooter>
                  </TitleFooterContainer>
                </TitleBorder>
              </BlendTitleContainer>
            </LandingContainer>
          </LandingWrapper>
        </OverlayColumn>
      </ContainerOverlay>
    </Wrapper>
  );
};

export default PDPDisplay;

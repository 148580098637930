import styled, { keyframes } from "styled-components";
import { BREAKPOINTS } from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

const blink = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

export const OverflowContainer = styled.div`
  ${setPosition({ position: "relative" })};
  height: 100dvh;
  width: 100%;
`;

export const OverflowWrapper = styled.div`
  ${setFlex({ direction: "column", align: "flex-end" })};
  ${setPosition({ position: "relative" })};
  height: fit-content;
`;

export const ChatWrapper = styled.div`
  ${setFlex({ direction: "column", align: "flex-end" })};
  height: 20%;
  z-index: 6 !important;
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${setPosition({ position: "absolute", bottom: 50, right: 50 })};
    height: 15%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ bottom: 20, right: 35 })};
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${setPosition({ right: 15 })};
    height: fit-content;
  }
`;

export const ChatContainer = styled.div`
  ${setFlex({ direction: "column" })};
  border-radius: 100px 20px 0px 100px;
  background-color: ${COLOURS.primary.black};
  border: 1px solid ${COLOURS.primary.white};
  padding: 0.9rem;
  max-width: 300px;
  opacity: 0;
  &.glitch {
    background-color: ${COLOURS.primary.red};
  }
`;

export const FinalContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ direction: "row" })};
  cursor: pointer;
  opacity: 0;
  &:not(.active) {
    display: none;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${setPosition({ zIndex: 10 })};
  }
`;

export const ProductMessage = styled.div`
  ${setFlex({ direction: "column" })};
  border-radius: 100px;
  background-color: ${COLOURS.primary.blue};
  border: 1px solid ${COLOURS.primary.white};
  height: fit-content;
  padding: 0.9rem;
  max-width: 170px;
  margin-top: -0.6rem;
  z-index: 1;
  pointer-events: none;
`;

export const Chat = styled.div`
  width: 100%;
  pointer-events: none;
`;

export const Name = styled.div`
  ${setFlex({ direction: "column" })};
  width: 100%;
`;

export const SmallHeading = styled(Headline)`
  color: ${COLOURS.primary.white};
  padding: 0.3rem 0.6rem;
  pointer-events: none;
`;

export const MicroHeading = styled(Headline)`
  color: ${COLOURS.primary.yellow};
  font-size: 0.5rem;
  text-align: right;
  .active {
    animation: 1s ${blink} infinite;
  }
`;

export const Product = styled.img`
  ${setPosition({ position: "absolute", top: 5, left: -60, zIndex: 5 })};
  width: auto;
  max-width: 100%;
  pointer-events: none;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ top: "0" })};
  }
`;

import THREE from "../../Three";

export function disposeTexturesAndGeometries(objects) {
  const objectsArray = Array.isArray(objects) ? objects : [objects];

  for (const object of objectsArray) {
    if (
      object &&
      (object.isTexture || object.isBufferGeometry || object.isMaterial)
    ) {
      object.dispose();
    } else if (object instanceof THREE.Object3D) {
      object.children.forEach((child) => disposeTexturesAndGeometries(child));
    } else if (typeof object === "object") {
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const value = object[key];
          disposeTexturesAndGeometries(value);
        }
      }
    }
  }
}

import styled from "styled-components";
import { generateFont, generateLineHeight } from "../../variables/Fonts";
import {
  BREAKPOINTS,
  generateMediaQueries,
  IPADBREAKPOINTS,
  hiddenxl,
  hiddensm,
  mobile,
} from "../../variables/Breakpoints";
import { setFlex, setPosition } from "../../variables/Position";
import { COLOURS } from "../../variables/Colors";

export const Wrapper = styled.div`
  ${setPosition({ position: "relative" })};
  height: 100vh;
  ::-webkit-scrollbar {
    display: none;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    height: auto;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: auto;
  }
`;

export const Container = styled.div`
  ${setFlex({ align: "center" })};
  ${setPosition({ position: "relative" })};
  height: 100vh;
  width: 100%;
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ align: "flex-start" })};
    height: fit-content;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ align: "flex-start" })};
    height: fit-content;
  }
`;

export const ContainerOverlay = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ align: "center" })};
  height: 100%;
  width: 100vw;
  @media (max-width: ${BREAKPOINTS.md}px) {
    ${setFlex({ justify: "center" })};
    width: 100%;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ direction: "column", justify: "flex-start" })};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
  }
`;

export const ColorBlock = styled.div`
  ${setPosition({ position: "absolute", zIndex: -1 })};
  background-color: ${COLOURS.primary.blue};
  min-height: 25px;
  margin-left: -0.9rem;
`;

export const Column = styled.div`
  ${setPosition({ position: "relative", zIndex: 3 })};
  width: 100vw;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "center", align: "flex-start", direction: "column" })};
    width: 100vw;
    min-height: 95vh;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ justify: "center", align: "flex-start", direction: "column" })};
    width: 100vw;
    min-height: 95vh;
  }
`;

export const FloatingColumn = styled.div`
  ${setPosition({ position: "absolute", right: "0", zIndex: 2 })};
  ${setFlex({ align: "center", justify: "center" })};
  width: 50vw;
  height: 100%;
  margin: 2rem 2rem 0 0;
  max-height: 70vh;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
    ${setFlex({ justify: "flex-start", align: "flex-start" })};
    z-index: unset;
    width: 100vw;
    margin: 0;
    max-height: unset;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setPosition({ position: "relative" })};
    ${setFlex({ justify: "flex-start", align: "flex-start" })};
    z-index: unset;
    width: 100vw;
    margin: 0;
    max-height: unset;
  }
`;

export const LogoContainer = styled.div`
  ${setFlex({ justify: "flex-start", align: "center" })};
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "space-between" })};
  }
`;

export const VideoWrapper = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({})};
  max-height: 25vh;
  height: -webkit-fill-available;
  @supports (-moz-appearance: none) {
    height: inherit;
  }
  margin-bottom: 1rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ direction: "column" })};
    height: auto;
    max-height: unset;
    margin: 0;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ direction: "column" })};
    height: auto;
    max-height: unset;
    margin: 0;
  }
`;

export const VideoContainer = styled.div`
  ${setFlex({ justify: "center", direction: "column" })};
  height: 100%;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "flex-start" })};
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setFlex({ justify: "flex-start" })};
    padding: 1rem;
  }
`;

export const Video = styled.div`
  ${setPosition({ position: "absolute" })};
  ${setFlex({ align: "center" })};
  overflow: hidden;
  height: 100%;
  width: 50%;
  &.sight {
    z-index: 1;
  }
  &.smell {
    right: 10px;
    top: -50px;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      right: unset;
      top: unset;
    }
    ${generateMediaQueries(IPADBREAKPOINTS)} {
      right: unset;
      top: unset;
    }
  }
  &.sound {
    right: 0;
    top: -25px;
    z-index: 1;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      right: unset;
      top: unset;
      z-index: unset;
    }
    ${generateMediaQueries(IPADBREAKPOINTS)} {
      right: unset;
      top: unset;
      z-index: unset;
    }
  }
  &.taste {
    top: 45px;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      top: unset;
    }
    ${generateMediaQueries(IPADBREAKPOINTS)} {
      top: unset;
    }
  }
  &.feel {
    right: 0;
    top: 15px;
    @media (max-width: ${BREAKPOINTS.sm}px) {
      right: unset;
      top: unset;
    }
    ${generateMediaQueries(IPADBREAKPOINTS)} {
      right: unset;
      top: unset;
    }
  }
  > div {
    margin: 1rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
    width: 100%;
    height: fit-content;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    ${setPosition({ position: "relative" })};
    width: 100%;
    height: fit-content;
  }
`;

export const TitleWrapper = styled.div`
  ${(props) => setFlex({ align: props.$align, direction: props.$direction })};
  margin: ${(props) => (props.$noMargin ? "1rem 2rem 0 2rem" : "1rem 2rem")};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 4rem 3rem 0 3rem;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin: 1rem 2rem;
  }
  ${(props) =>
    props.$mobilePadding &&
    `
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding-top: 2rem;
  }
  `};
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: -webkit-fill-available;
    width: -moz-available;
  }
`;

export const TitleContainer = styled.div`
  ${setPosition({ position: "relative" })};
  ${(props) => setFlex({ align: props.$align, direction: props.$direction })};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100%;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const Hidden = styled.span`
  opacity: 0;
  pointer-events: none;
`;

export const Title = styled.h1`
  font-family: frekuent-mono-regular;
  font-weight: 100;
  text-transform: uppercase;
  ${(props) => props.theme.colour};
  ${generateFont("2rem")};
  ${generateLineHeight("1.7rem")};
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 3rem`};
  ${(props) => props.$symbol && `margin-right: 0.6rem`};
  ${(props) => setFlex({ justify: props.$justify })};
  ${(props) => props.$mobile && mobile};
  ${(props) => props.$hiddensm && hiddensm};
`;

export const SecondaryTitle = styled(Title)`
  ${generateFont("1.5rem")};
  ${generateLineHeight("1.8rem")};
  color: ${(props) => props.$setcolour || props.theme.colour};
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || props.theme.colour};
  font-size: 0.8rem;
  line-height: 0.9rem;
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 1.25rem`};
  ${(props) => props.$space && `margin-${props.$space}: 0.6rem`};
  @media only screen and (min-device-width: ${IPADBREAKPOINTS.STANDARD
      .width}px) and (max-device-width: ${IPADBREAKPOINTS.STANDARD.height}px) {
    color: ${COLOURS.primary.white};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    color: ${COLOURS.primary.white};
    font-size: 0.6rem;
    line-height: 0.8rem;
  }
`;

export const Credit = styled.div`
  ${setFlex({ align: "center" })};
  ${(props) => props.$padding && "padding-bottom: 0.6rem"};
`;

export const CreditLink = styled.a`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || props.theme.colour};
  font-size: 0.8rem;
  line-height: 0.9rem;
  margin: 0 0 0 0.4rem;
  @media only screen and (min-device-width: ${IPADBREAKPOINTS.STANDARD
      .width}px) and (max-device-width: ${IPADBREAKPOINTS.STANDARD.height}px) {
    color: ${COLOURS.primary.white};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    color: ${COLOURS.primary.white};
    font-size: 0.6rem;
    line-height: 0.8rem;
    &:visited {
      color: ${COLOURS.primary.white};
    }
  }
  ${(props) =>
    props.$heading &&
    `
  font-size: 0.6rem;
  line-height: 0.8rem;
  white-space: nowrap;
  `};
`;

export const SmallHeading = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || props.theme.colour};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
  ${(props) => props.$width && `max-width: ${props.$width}px`};
  ${(props) =>
    props.$margin &&
    `
    margin-${props.$margin}: 1rem;
  `};
`;

export const HeadingContain = styled.div`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${setPosition({ position: "relative" })};
  height: auto;
  width: ${(props) => (props.$fitWidth ? "fit-content" : "100%")};
  ${(props) =>
    props.$mobileRow &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      ${setFlex({ direction: "row" })};
    }
  `};
  ${(props) => props.$margin && "margin: 1.25rem 0"};
  ${(props) => props.$padding && `padding-${props.$padding}: 0.2rem`};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxl && hiddenxl};
  ${(props) => props.$mobile && mobile};
`;

export const ButtonContainer = styled.div`
  ${setFlex({ direction: "column" })};
  width: fit-content;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;

export const SVGContainer = styled.div`
  ${setFlex({ justify: "flex-end" })};
  flex: 1;
  > svg {
    margin-right: 0.5rem;
  }
`;

export const Image = styled.img`
  width: auto;
  max-width: 100%;
  margin: 1rem;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-height: 40px;
    margin: 0.4rem;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    max-height: 30px;
  }
`;

export const Link = styled.a`
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  height: 100%;
  width: 100%;
  cursor: pointer;
  ${(props) => props.$noWrap && "white-space: nowrap"};
`;

export const LinksContainer = styled.div`
  ${setFlex({ justify: "space-between", align: "center" })};
  width: auto;
  margin-top: 0.8rem;
`;

export const QRCode = styled.img`
  width: 60px;
  height: 60px;
  max-width: unset;
  ${(props) => props.$hiddensm && hiddensm};
`;

export const MaskContainer = styled.div`
  ${setFlex({})};
  ${setPosition({ position: "relative" })};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.9rem`};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    display: unset;
    margin: 0;
  }
`;

export const Mask = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: -1 })};
  background-color: ${COLOURS.primary.white};
  height: 100%;
  width: 0%;
  ${(props) =>
    props.$desktop &&
    `
      @media (max-width: ${BREAKPOINTS.sm}px) {
      background-color: transparent;
    }
  `};
`;

export const Button = styled.button`
  ${setFlex({ align: "center", justify: "center" })};
  background: transparent;
  border: 1px solid ${COLOURS.primary.white};
  color: ${COLOURS.primary.white};
  padding: 0.5rem 1rem;
  cursor: pointer;
  ${(props) => props.$margin && "margin-right: 0.5rem"};
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;

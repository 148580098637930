import styled from "styled-components";
import {
  BREAKPOINTS,
  IPADBREAKPOINTS,
  generateMediaQueries,
  hiddensm,
  hiddenxs,
} from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Container = styled.div`
  ${setFlex({ justify: "center", align: "flex-end" })};
  ${setPosition({ position: "relative" })};
  height: 100%;
  width: 100%;
  ${(props) => props.$blend && "mix-blend-mode: difference"};
  ${(props) => props.$index && `z-index: ${props.$index}`};
  ${(props) => props.$margin && "margin-bottom: 10vh"};
  ${(props) => props.$background && `background-color: ${props.$background}`};

  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ align: "flex-start" })};
  }
`;

export const ContainerOverlay = styled.div`
  height: 120vh;
  width: 100%;
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

export const SmallHeading = styled(Headline)`
  color: ${COLOURS.primary.white};
  ${(props) => props.$width && `max-width: ${props.$width}px`};
  ${(props) => props.$margin && `margin-${props.$margin}: 0.6rem`};
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  ${(props) =>
    props.$loading &&
    `
    &:not(.active) {
      display: none;
    }
  `};
`;

export const SVGContainer = styled.div`
  ${setFlex({ align: "center" })};
  ${(props) =>
    props.$absolute &&
    `
    ${setPosition({
      position: "absolute",
      left: props.$left,
      bottom: props.$bottom,
    })};
    margin: 0 2rem;
    width: -webkit-fill-available;
    width: -moz-available;
    height: -webkit-fill-available;
    height: -moz-available;
  `};
  @media (max-width: ${BREAKPOINTS.sm}px) {
    justify-content: center;
  }
  svg {
    margin-top: 2rem;
  }
  ${(props) =>
    props.$scaleSVG &&
    `
    svg {
      height: 80vh;
      width: auto;
    }
  `};
  ${(props) =>
    props.$margin &&
    `
    svg {
      margin-left: 2rem;
      ${generateMediaQueries(IPADBREAKPOINTS)} {
       margin: 2rem;
      }
    }
  `};
  ${(props) => props.$hiddensm && hiddensm};
`;

export const PosterContainer = styled.div`
  ${setFlex({ direction: "row" })};
  ${setPosition({ position: "relative" })};
  height: 100%;
  width: 100%;
  max-height: 100vh;
  margin: 0;
  color: ${COLOURS.primary.white};
`;

export const Poster = styled.div`
  ${setFlex({ justify: "center" })};
  width: fit-content;
  max-width: 40vw;
  height: fit-content;
  will-change: transform;
  margin-left: 5rem;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    margin-left: 3rem;
  }
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    max-width: 70vw;
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: unset;
    margin: 0;
  }
`;

export const HeadingContainer = styled.div`
  ${(props) =>
    setFlex({ justify: props.$justify, direction: props.$direction })};
  ${setPosition({ position: "absolute", left: "0", bottom: "0" })};
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
  }
`;

export const DisclaimerWrapper = styled.div`
  ${setPosition({ position: "relative" })};
`;

export const DisclaimerContainer = styled.div`
  ${setFlex({ justify: "flex-start", direction: "column" })};
  ${setPosition({ position: "absolute", left: 15, bottom: 5, zIndex: 0 })};
  padding: 1.5rem;
  > svg {
    height: auto;
    margin-left: 0.6rem;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative" })};
    z-index: 3;
  }
  ${(props) => props.$hiddensm && hiddensm};
`;

export const PosterImage = styled.img`
  ${setPosition({ position: "absolute", zIndex: 1 })};
  height: 100%;
  width: auto;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    min-height: 50vh;
    object-fit: contain;
  }
  ${hiddensm};
`;

export const PosterWrapper = styled.div`
  ${setFlex({ justify: "center", align: "center" })};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 3 })};
  width: 100%;
  height: 100%;
  margin: 0 1rem;
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "center", align: "flex-start" })};
    margin: 3rem 0;
    height: auto;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ align: "center" })};
    ${setPosition({ position: "relative" })};
    margin: 0;
    height: 100%;
  }
`;

export const Column = styled.div`
  width: 50%;
  ${generateMediaQueries(IPADBREAKPOINTS)} {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "absolute", top: "0", left: "0" })};
    width: 100%;
    max-width: 100vw;
  }
  ${(props) =>
    props.$ipad &&
    `
    ${generateMediaQueries(IPADBREAKPOINTS)} {
      display: none;
    }
  `};
  ${(props) => props.$hiddensm && hiddensm};
  ${(props) => props.$hiddenxs && hiddenxs};
`;

export const LeftColumn = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ justify: "flex-end", align: "center" })};
  width: 100%;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setFlex({ justify: "center", align: "flex-end" })};
  }
`;

export const RightColumn = styled.div`
  ${setPosition({ position: "absolute", right: "0", zIndex: -1 })};
  ${setFlex({ justify: "center", align: "center" })};
  width: 50%;
  height: 100%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${setPosition({ position: "relative", right: "0" })};
    ${setFlex({ direction: "column" })};
    width: 100%;
    margin-top: 3.5rem;
  }
`;

export const ChatContainer = styled.div`
  height: fit-content;
  width: 100%;
  margin-left: 1.9rem;
  max-height: 75%;
  max-width: 60%;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin: 0;
    max-width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  ${setPosition({ position: "relative" })};
  ${setFlex({ align: "center" })};
  height: 100%;
  padding-left: 1.9rem;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${setFlex({ align: "flex-start" })};
    padding-left: 0;
  }
`;

export const OverlayVideo = styled.div`
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 9999 })};
  width: 100%;
  height: 100%;
  display: none;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  &.active {
    display: block;
  }
`;

export const Hidden = styled.span`
  opacity: 0;
  pointer-events: none;
`;

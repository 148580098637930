import React, { useEffect, useState, memo } from "react";
import { ContainerOverlay } from "../styles/components/Global/Global";
import {
  LoadingWrapper,
  LoadingContainer,
  LandingContainer,
  LoadingBar,
  Container,
  Headline,
  SmallHeading,
  HeadingContainer,
  Logo,
  ProgressBar,
  Progress,
  VideoWrapper,
  Hidden,
  IntroductionHeadline,
} from "../styles/components/Global/Loader";
import SenseVideo from "./Video";
import EnableAudio from "./EnableAudio";

const LoaderText = () => {
  return (
    <IntroductionHeadline className="introduction">
      <SmallHeading className="scramble-type" $margin="bottom" $padding>
        <Hidden className="text">Year 2051.</Hidden>
      </SmallHeading>
      <SmallHeading className="scramble-type" $margin="bottom">
        <Hidden className="text">
          Our species has evolved to survive disease,{" "}
        </Hidden>
      </SmallHeading>
      <SmallHeading className="scramble-type" $margin="bottom" $padding>
        <Hidden className="text">conflict, and ecological collapse.</Hidden>
      </SmallHeading>
      <SmallHeading className="scramble-type" $margin="bottom" $padding>
        <Hidden className="text">
          We are highly efficient. But we are numb.
        </Hidden>
      </SmallHeading>
      <SmallHeading className="scramble-type" $margin="bottom" $padding>
        <Hidden className="text">
          Introducing SENSE Synthetic Assistants.
        </Hidden>
      </SmallHeading>
      <SmallHeading className="scramble-type" $margin="bottom">
        <Hidden className="text">Stop surviving, start living.</Hidden>
      </SmallHeading>
    </IntroductionHeadline>
  );
};

const Loader = memo(({ audioEnabled, toggleAudio, loader }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <ContainerOverlay id="loader" $justify="center" className="active" $loader>
      <LoadingWrapper id="progress" className="active" $visibility={loaded}>
        <Container>
          <LandingContainer className="loading-content">
            <LoadingContainer>
              <LoadingBar>
                <HeadingContainer $fitwidth>
                  <SmallHeading $nowrap $hiddensm>
                    Est. 2051
                  </SmallHeading>
                </HeadingContainer>
                <ProgressBar>
                  <Progress id="loading-bar" />
                </ProgressBar>
              </LoadingBar>
              <HeadingContainer $justify="center">
                <Headline $mobileCenter>Introducing</Headline>
                <Logo
                  className="reset"
                  src={loader.logo.source}
                  alt={loader.logo.alt}
                  loading="lazy"
                />
              </HeadingContainer>
              <HeadingContainer>
                <Headline $mobileCenter>
                  a beauty product by <br></br>
                  Highsnobiety and <br></br>
                  91 Rules.
                </Headline>
              </HeadingContainer>
            </LoadingContainer>
            <EnableAudio
              audioEnabled={audioEnabled}
              toggleAudio={toggleAudio}
            />
          </LandingContainer>
          <LoaderText />
        </Container>
      </LoadingWrapper>
      <VideoWrapper className="loader-video" $visibility={loaded}>
        <SenseVideo
          audioEnabled={audioEnabled}
          toggleAudio={toggleAudio}
          videoSrc={loader.source}
          videoPoster={loader.poster}
          loader={true}
        />
      </VideoWrapper>
    </ContainerOverlay>
  );
});

export default Loader;

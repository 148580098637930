export const bgVertexShader = () => {
  return `
    varying vec2 vUv;

    void main() {
        vUv = uv;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `;
};

export const bgFragmentShader = () => {
  return `
  #ifdef GL_ES
  precision mediump float;
  #endif
  
  uniform vec2 u_resolution;
  uniform vec2 u_mouse;
  uniform float u_time;
  uniform vec3 u_color;
  
  float random (vec2 st) {
      return fract(sin(dot(st.xy,
                           vec2(12.9898,78.233)))*
          43758.5453123);
  }
  
   float noise (in vec2 _st) {
        vec2 i = floor(_st);
        vec2 f = fract(_st);
        
        float a = random(i);
        float b = random(i + vec2(1.0, 0.0));
        float c = random(i + vec2(0.0, 1.0));
        float d = random(i + vec2(1.0, 1.0));
    
        vec2 u = f * f * (3.0 - 2.0 * f);
    
        return mix(a, b, u.x) +
                (c - a)* u.y * (1.0 - u.x) +
                (d - b) * u.x * u.y;
  }
    #define NUM_OCTAVES 1
  
    float fbm ( in vec2 _st) {
        float v = 0.;
        float a = 1.266;
        vec2 shift = vec2(100.0) + u_mouse.xy*.0005;
        mat2 rot = mat2(cos(0.860), sin(-0.140),
                        -sin(0.340), cos(0.708));
        for (int i = 0; i < NUM_OCTAVES; ++i) {
            v += a * noise(_st);
            _st = rot * _st * 0.70 + shift;
            a *= 0.6;
        }
        return v;
    }
  
  void main() {
                  vec2 st = gl_FragCoord.xy/u_resolution.xy*2.;
              vec3 color = vec3(0.0);
          
              vec2 q = vec2(0.);
              q.x = fbm( st +  + 0.005*u_time);
              q.y = fbm( st + vec2(1.0));
          
              vec2 r = vec2(0.);
              r.x = fbm( st + 1.0*q + vec2(1.7,9.2)+ 0.350*u_time );
              r.y = fbm( st + 1.0*q + vec2(8.3,2.8)+ 0.350*u_time);
          
              float f = fbm(st+r);
           float rnd = random( st );
  
              color = mix(vec3(0.132,0.135,0.132),
                          vec3(0.202,0.205,0.199),
                          clamp((f*f)*4.0,0.0,1.0));
          
              color = mix(color,
                          vec3(0,0,0),
                          clamp(length(q),0.0,1.0));
          
              color = mix(color,
                          u_color,
                          clamp(length(r.x),0.0,1.0));
          
              gl_FragColor = vec4((f*f*f+.6*f*f+.5*f)*(rnd*-0.134)+color,0.35);
  }
    `;
};

import styled from "styled-components";
import { BREAKPOINTS } from "../../variables/Breakpoints";
import { COLOURS } from "../../variables/Colors";
import { setFlex, setPosition } from "../../variables/Position";

export const Heading = styled.h2`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 4.5rem;
  line-height: 4.5rem;
  font-weight: normal;
  margin: 0;
  flex-grow: 1;
`;

export const Headline = styled.h4`
  font-family: frekuent-mono-regular;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOURS.primary.black};
  font-size: 0.6rem;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

export const Paragraph = styled.p`
  font-family: frekuent-mono-regular;
  text-transform: uppercase;
  color: ${(props) => props.$setcolour || COLOURS.primary.white};
  font-size: 0.6rem;
  line-height: 0.8rem;
  max-width: 300px;
  margin: 0;
  ${(props) => props.$margin && `margin-${props.$margin}: 20px;`};
`;

export const Hidden = styled.span`
  opacity: 0;
  pointer-events: none;
  @media (max-width: ${BREAKPOINTS.md}px) {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  ${setFlex({ direction: "column" })};
  min-height: 100vh;
  width: 100%;
  height: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ContainerOverlay = styled.div`
  min-height: 100vh;
  height: ${(props) => (props.$plp ? "100vh" : "auto")};
  width: 100%;
  ${(props) =>
    props.$blend &&
    `
        align-items: center; 
        mix-blend-mode: difference
    `};
  ${setPosition({ position: "absolute", top: "0", left: "0", zIndex: 5 })};
  ${(props) => props.$fixed && setPosition({ position: "fixed", zIndex: 99 })};
  ${(props) =>
    props.$relative && setPosition({ position: "relative", zIndex: "0" })};
  ${(props) =>
    setFlex({
      justify: props.$justify,
      align: props.$align,
      direction: props.$direction,
    })};
  ${(props) =>
    props.$margin &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      margin: 0.8rem;
    }
  `};
  ${(props) =>
    props.$mobileUnpin &&
    `
    @media (max-width: ${BREAKPOINTS.sm}px) {
      ${setPosition({ position: "relative" })};
      min-height: unset;
      height: fit-content;
      margin: 4rem 0;
    }
    @media (max-width: ${BREAKPOINTS.xs}px) {
      margin: 0 0 1.5rem 0;
    }
  `};
`;

export const Image = styled.img`
  ${setPosition({ position: "absolute" })};
  width: auto;
  max-width: 100%;
  margin: 1.25rem;
`;

import { COLOURS, BORDERS } from "./Colors";
import { Global } from "../../components/Sense-Objects";

export const THEMES = {
  dark: {
    colour: COLOURS.primary.black,
    border: BORDERS.secondary,
    invert: 1,
    logo: Global.logo.dark,
    alt: Global.logo.alt,
  },
  light: {
    colour: COLOURS.primary.white,
    border: BORDERS.primary,
    invert: 0,
    logo: Global.logo.light,
    alt: Global.logo.alt,
  },
};

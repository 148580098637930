import React from "react";
import { Wrapper } from "../styles/components/PLP/Global";
import PLPLanding from "./PLP-Landing";

const Plp = (senses) => {
  return (
    <Wrapper>
      <PLPLanding {...senses} />
    </Wrapper>
  );
};

export default Plp;

import THREE from "../../Three";
import gsap from "../../../components/Animations";
import { Global } from "../../../components/Sense-Objects";
import {
  createAnimations,
  disposeTexturesAndGeometries,
  chipAnimations,
  updateRatios,
  mm,
} from "./Modules";

export default class Pages {
  constructor(scene, activeSense, renderer) {
    this.scene = scene;
    this.renderer = renderer;
    this.camera = scene.userData.camera;
    this.element = scene.userData.element;
    this.fov = this.camera.fov;
    this.ratio = 0.85;

    this.clock = new THREE.Clock();
    this.colorTo = new THREE.Vector3(
      Global.color.x,
      Global.color.y,
      Global.color.z
    );

    this.ctx = gsap.context(() => {});
    this.activeSense = activeSense;
    this.chip = null;

    this.id = 2;
    this.sectionActive = false;
    this.backgroundActive = false;

    this.pages = document.getElementById("section-pages");

    this.onResize = this.onResize.bind(this);

    this.scene.userData.constructor = this;

    this.initScene();
    this.onResize();
  }

  initScene() {
    const mediaMatcher = mm();
    this.context = mediaMatcher.contexts[0].conditions;

    this.light = new THREE.DirectionalLight();
    this.light.intensity = 3;
    this.light.position.set(15, 0, 20);

    this.light.castShadow = true;

    this.scene.add(this.light);
  }

  setClasses(pdpInstance, plpInstance, checkoutInstance) {
    this.PDP = pdpInstance;
    this.PLP = plpInstance;
    this.Checkout = checkoutInstance;
  }

  chipAddedToScene(chip) {
    this.chip = chip;
    this.scene.add(this.chip);

    this.chipPosition();

    this.chip.name = "Chip";
    this.chip.userData.active = true;
  }

  chipPosition() {
    if (!this.chip) return;

    this.chip.scale.set(35, 35, 35);

    chipAnimations(this.chip);

    this.chip.updateMatrix();
  }

  updatePageScene(timeline) {
    window.scrollTo(0, 0);
    this.moveSceneChildren();

    this.PLP.disposePLPScene();
    this.PDP.disposePDPScene();
    this.Checkout.disposeCheckoutScene();
    this.activeSense(0);

    this.createPageEventListeners();
    this.element.classList.add("back");

    this.onResize();

    this.chipPosition();

    createAnimations(this, timeline);
  }

  disposePagesScene() {
    this.removePageEventListeners();
    if (this.background) {
      const disposeObjects = [
        this.background.material,
        this.background.material.map,
        this.background.geometry,
      ];
      disposeTexturesAndGeometries(disposeObjects);
      this.scene.remove(this.background);
      this.PDP.scene.add(this.background);
      this.backgroundActive = false;
    }

    if (this.chip !== null) {
      this.chip.traverse((mesh) => {
        if (mesh.isMesh) {
          disposeTexturesAndGeometries(mesh.material);
        }
      });
    }

    this.element.classList.remove("back");
    this.ctx.revert();
  }

  moveSceneChildren() {
    for (const child of this.PDP.scene.children) {
      if (child.isMesh && child.name === "Background") {
        this.PDP.scene.remove(child);
        this.scene.add(child);
        this.backgroundActive = true;
        this.background = child;
        this.PDP.updateResolution(this.background.material);
        this.PDP.scaleGeometry(this.background, this.camera);
      }
    }
  }

  createPageEventListeners() {
    window.addEventListener("resize", this.onResize);
  }

  removePageEventListeners() {
    window.removeEventListener("resize", this.onResize);
  }

  render() {
    if (!this.sectionActive) return;

    this.time += 0.05;

    if (this.background) {
      this.background.material.uniforms.u_time.value += this.clock.getDelta();
      this.PDP.scaleGeometry(this.background, this.camera);

      this.background.material.uniforms.u_color.value.lerp(this.colorTo, 0.01);
      this.background.material.dispose();
    }

    if (this.chip !== null && this.chip?.userData.active) {
      this.chip.position.y += Math.sin(Date.now() * 0.001) * Math.PI * 0.003;
    }
  }

  onResize() {
    if (this.background) {
      this.PDP.updateResolution(this.background.material);
      this.PDP.scaleGeometry(this.background, this.camera);
    }
    updateRatios(this);
  }
}

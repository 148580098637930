import gsap from "../../../components/Animations";

function updateSlider(PLP, index) {
  const slider = document.querySelector(".plp-navigation");
  const chip = PLP.group.children[index - 1];
  const current = chip.userData.id;

  PLP.onMouseOutChip();

  const currActive = slider.querySelectorAll(".active");
  const nextActive = slider.querySelectorAll(`div[class*="${current}-bullet"]`);

  currActive?.forEach((element) => element.classList.remove("active"));
  nextActive?.forEach((element) => element.classList.add("active"));

  PLP.view = chip;
  PLP.intersecting = false;
}

function updateScrollDirection(PLP, index, direction, wrap, chipMod) {
  PLP.animating = true;
  index = wrap(index);
  let scrollTl = null;

  PLP.ctx.add(() => {
    scrollTl = gsap.timeline({
      defaults: {
        delay: 0.5,
        duration: 1,
        ease: "power2.easeInOut",
      },
      onComplete: () => {
        PLP.animating = false;
        updateSlider(PLP, index);
      },
    });
  });

  PLP.group.children.forEach((chip) => {
    PLP.ctx.add(() => {
      scrollTl.to(
        chip.position,
        {
          z: direction + PLP.sectionWidth,
          modifiers: {
            z: (z) => chipMod(parseFloat(z)),
          },
        },
        0
      );
    });
  });

  PLP.ctx.add(() => {
    scrollTl.to(
      PLP.camera.position,
      {
        x: PLP.group.children[index - 1].position.x,
      },
      0
    );
  });

  PLP.current = index;
}

export function createAnimations(PLP, update) {
  const landing = document.getElementById("plp-landing");
  const { context } = PLP;

  PLP.current = 1;
  PLP.animating = false;

  if (update) {
    updateSlider(PLP, 1);
  } else {
    PLP.world.setFromMatrixPosition(PLP.group.children[0].matrixWorld);

    PLP.camera.position.x = PLP.world.x;
    PLP.camera.position.z = PLP.world.z + PLP.sectionWidth;

    const chipMod = gsap.utils.wrap(
      PLP.group.children[0].position.z + PLP.sectionWidth,
      PLP.group.children[PLP.group.children.length - 1].position.z
    );

    const wrap = gsap.utils.wrap(1, PLP.group.children.length + 1);

    PLP.observer = gsap.ScrollTrigger.observe({
      target: landing,
      type: context.isDesktop ? "wheel,scroll" : "wheel,touch",
      tolerance: context.isDesktop ? 100 : 50,
      preventDefault: context.isDesktop ? false : true,
      onUp: () => {
        if (PLP.animating) return;
        let direction = context.isDesktop ? "-=" : "+=";
        updateScrollDirection(
          PLP,
          PLP.current + (context.isDesktop ? -1 : 1),
          direction,
          wrap,
          chipMod
        );
      },
      onDown: () => {
        if (PLP.animating) return;
        let direction = context.isDesktop ? "+=" : "-=";
        updateScrollDirection(
          PLP,
          PLP.current + (context.isDesktop ? 1 : -1),
          direction,
          wrap,
          chipMod
        );
      },
    });
  }
}

import gsap from "../../../components/Animations";
import { handleScroll, setCart } from "./Modules";

function onClickSkipVideo(PLP, loader, video, tl, skip) {
  video.pause();
  tl.pause();
  tl.clear();
  tl = gsap.timeline();
  endSceneLoader(PLP, loader, tl);
  skip.removeEventListener("click", onClickSkipVideo);
}

function onClickCheckbox(PLP) {
  audioChecker(PLP, true);
}

function audioChecker(PLP, checked) {
  const audio = document.getElementById("enableAudio");
  const loadingContent = document.querySelector(".loading-content");
  const checkboxes = audio?.querySelectorAll(".audio-checkbox");
  const loader = document.getElementById("loader");
  if (checked) {
    gsap.to(loadingContent, { autoAlpha: 0, duration: 0.5 });
    checkboxes?.forEach((checkbox) => {
      checkbox.removeEventListener("click", onClickCheckbox);
    });
    introductionScene(PLP, loader);
  } else {
    gsap.to(audio, { delay: 1, autoAlpha: 1, duration: 1 });
    checkboxes?.forEach((checkbox) => {
      checkbox.addEventListener("click", () => onClickCheckbox(PLP));
    });
  }
}

function introductionScene(PLP, loader) {
  const introduction = loader.querySelector(".introduction");
  const scrambleType = loader.querySelectorAll(".scramble-type");

  const video = loader.querySelector(".video");
  const skip = document.querySelector(".skip-video");
  let text;

  const timeline = gsap
    .timeline({})
    .to(introduction, { delay: 0.5, autoAlpha: 1, duration: 1 });

  scrambleType?.forEach((element) => {
    text = element?.querySelector(".text");
    if (text == null) return;
    text = text.innerText;

    timeline.to(element, {
      duration: 0.8,
      text: text,
    });
  });
  timeline
    .to(introduction, { delay: 2, autoAlpha: 0, duration: 1 })
    .then(() => {
      let tl = sceneLoader(PLP, loader, video);
      gsap.to(skip, { delay: 4, autoAlpha: 1, duration: 1 });
      skip?.addEventListener("click", (event) => {
        event.stopImmediatePropagation();
        onClickSkipVideo(PLP, loader, video, tl, skip);
      });
    });
}

function endSceneLoader(PLP, loader, tl) {
  tl.to(PLP.transition, { delay: 1, autoAlpha: 1, duration: 2 })
    .add(() => handleScroll(true))
    .add(() => loader.classList.toggle("active"))
    .add(() => (PLP.sectionActive = true))
    .to(PLP.transition, { autoAlpha: 0, duration: 2 })
    .add(() => PLP.transition.classList.toggle("active"))
    .add(() => PLP.createPLPEventListeners())
    .add(() => gsap.scrambleAnimation(PLP.plp))
    .add(() => PLP.loadAvaToScene())
    .add(() => (PLP.sizzle = true))
    .add(() => (PLP.loaded = true))
    .add(() => PLP.wrapper.classList.toggle("active", true))
    .then(() => {
      PLP.activeLoaderPlayed(true);
    });
}

function sceneLoader(PLP, loader, video) {
  const progress = document.getElementById("progress");
  const svgs = document.querySelectorAll("rect, path, line, circle");
  const images = document.querySelectorAll(".fade-image");
  let isVideoPlayed = false;

  gsap.set(svgs, {
    drawSVG: 0,
  });

  if (images.length > 0) {
    gsap.set(images, {
      opacity: 0,
    });
  }

  setCart();

  const tl = gsap.timeline({
    onUpdate: () => PLP.sizzle && tl.kill(),
  });

  tl.add(() => progress.classList.toggle("active"))
    .add(() => PLP.transition.classList.toggle("active"))
    .add(() => video.load())
    .add(() => window.scrollTo(0, 0))
    .to(PLP.transition, {
      delay: 0.5,
      autoAlpha: 0,
      duration: 3,
    });

  if (!isVideoPlayed) {
    tl.add(() => video.play());
    isVideoPlayed = true;
  }

  video.onended = () => {
    endSceneLoader(PLP, loader, tl);
  };

  return tl;
}

export { audioChecker, sceneLoader };

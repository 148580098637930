import gsap from "../../../components/Animations";
import { triggerAvaMessage, handleScroll } from "./Modules";

function detectGlitchItem(existingProductIds) {
  let productIdToAdd = existingProductIds.includes(6) ? 7 : 6;

  return productIdToAdd;
}

function addToCart(glitch, chip, PDP, PLP) {
  if (typeof Storage === "undefined") return;

  const existingProductIds =
    JSON.parse(localStorage.getItem("cartItems")) || [];
  let productIdToAdd;

  if (glitch) {
    productIdToAdd = detectGlitchItem(existingProductIds);
  } else {
    productIdToAdd = chip.userData.id;
  }

  if (!existingProductIds.includes(productIdToAdd)) {
    if (!glitch) {
      triggerAvaMessage(PLP, true, 4, true);
    }

    existingProductIds.push(productIdToAdd);
    localStorage.setItem("cartItems", JSON.stringify(existingProductIds));
    PDP.updateCartQuanities();
  } else {
    PLP && triggerAvaMessage(PLP, true, 5, true);
  }
}

function setCart() {
  const cart = document.querySelector(".cart-container");

  gsap.set(cart, {
    xPercent: 100,
  });
}

function cartAnimation(target, subjects) {
  const cart = document.querySelector(".cart-container");

  if (target.classList.contains("open")) {
    gsap.set(cart, {
      xPercent: 100,
    });

    gsap.to(cart, {
      xPercent: 0,
      duration: 1,
    });
    cart.classList.add("active");
    handleScroll(false, subjects);
  }

  if (target.classList.contains("close")) {
    handleScroll(true, subjects);
    gsap
      .to(cart, {
        xPercent: 100,
        duration: 1,
      })
      .then(() => {
        cart.classList.remove("active");
      });
  }
}

export { detectGlitchItem, addToCart, setCart, cartAnimation };

import React from "react";
import {
  EnableAudioContainer,
  AudioContainer,
  AudioLabel,
  AudioInput,
  CheckboxWrapper,
  CheckboxContainer,
  CrossCheckbox,
  DisclaimerContainer,
  DisclaimerHeading,
  AudioLabelContainer,
} from "../styles/components/Global/Loader";

const EnableAudio = ({ audioEnabled, toggleAudio }) => {
  const handleAudioToggle = () => {
    toggleAudio(!audioEnabled);
  };

  const handleCheckboxClick = (enableAudio) => {
    toggleAudio(enableAudio);
  };

  const handleYesClick = () => handleCheckboxClick(true);
  const handleNoClick = () => handleCheckboxClick(false);

  return (
    <EnableAudioContainer id="enableAudio">
      <AudioLabelContainer>
        <AudioLabel $nowrap>
          <AudioInput
            type="checkbox"
            checked={audioEnabled}
            onChange={handleAudioToggle}
            id="audioCheckbox"
          />
          Activate Sound?
        </AudioLabel>
      </AudioLabelContainer>
      <AudioLabelContainer>
        <CheckboxWrapper>
          <AudioContainer $margin>
            <CheckboxContainer>
              <CrossCheckbox
                className="audio-checkbox"
                onClick={handleYesClick}
              >
                YES
              </CrossCheckbox>
            </CheckboxContainer>
          </AudioContainer>
          <AudioContainer $margin>
            <CheckboxContainer>
              <CrossCheckbox className="audio-checkbox" onClick={handleNoClick}>
                NO
              </CrossCheckbox>
            </CheckboxContainer>
          </AudioContainer>
        </CheckboxWrapper>
      </AudioLabelContainer>
      <AudioLabelContainer>
        <DisclaimerContainer>
          <DisclaimerHeading>
            This website contains visual effects and animations that may include
            rapid flashing, blinking patterns and graphic imagery. Please
            proceed with caution.
          </DisclaimerHeading>
        </DisclaimerContainer>
      </AudioLabelContainer>
    </EnableAudioContainer>
  );
};

export default EnableAudio;

import React from "react";

export const PlusSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="29.3706"
        y1="15.9949"
        x2="0.372021"
        y2="15.9949"
        stroke="white"
      />
      <line
        x1="15.3706"
        y1="0.994873"
        x2="15.3706"
        y2="29.9935"
        stroke="white"
      />
    </svg>
  );
};

export const SLogo = () => {
  return (
    <svg
      width="100"
      height="49"
      viewBox="0 0 100 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M92.7423 1.13501H15.8834C15.8834 1.13501 0.647949 1.42775 0.647949 14.0901C0.647949 26.7525 17.598 26.1728 17.598 26.1728H62.2782C62.2782 26.1728 71.8636 26.9821 71.8636 34.2949C71.8636 41.6076 65.0188 43.2033 62.6225 43.2033H6.64222V48.3004H84.3584C84.3584 48.3004 98.5678 45.0975 98.5678 35.2018C98.5678 25.3061 88.8136 21.2307 83.1636 21.2307H36.2558C36.2558 21.2307 29.1612 20.4213 29.1612 13.1488C29.1612 6.80611 36.9443 5.95085 38.9964 5.95085H92.7491V1.13501H92.7423Z"
        stroke="white"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export const SLogoMedium = () => {
  return (
    <svg
      width="86"
      height="42"
      viewBox="0 0 86 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.5853 0.525375H13.6157C13.6157 0.525375 0.340576 0.780448 0.340576 11.8136C0.340576 22.8467 15.1097 22.3416 15.1097 22.3416H54.041C54.041 22.3416 62.3931 23.0468 62.3931 29.4186C62.3931 35.7904 56.429 37.1808 54.341 37.1808H5.56357V41.6221H73.2802C73.2802 41.6221 85.6613 38.8313 85.6613 30.2089C85.6613 21.5864 77.1622 18.0354 72.2391 18.0354H31.3668C31.3668 18.0354 25.1851 17.3302 25.1851 10.9934C25.1851 5.46679 31.9668 4.72157 33.7548 4.72157H80.5912V0.525375H80.5853Z"
        stroke="white"
        strokeWidth="0.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export const SLogoSmall = () => {
  return (
    <svg
      width="59"
      height="30"
      viewBox="0 0 59 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.1532 1.41187H9.48033C9.48033 1.41187 0.625 1.58201 0.625 8.94181C0.625 16.3016 10.4769 15.9646 10.4769 15.9646H36.4465C36.4465 15.9646 42.0179 16.4351 42.0179 20.6854C42.0179 24.9358 38.0394 25.8633 36.6466 25.8633H4.10906V28.8259H49.2803C49.2803 28.8259 57.5392 26.9643 57.5392 21.2126C57.5392 15.4609 51.8698 13.0921 48.5858 13.0921H21.3214C21.3214 13.0921 17.1978 12.6217 17.1978 8.39466C17.1978 4.70809 21.7216 4.21099 22.9144 4.21099H54.1572V1.41187H54.1532Z"
        stroke="white"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export const SSense = () => {
  return (
    <svg
      width="100%"
      height="683"
      viewBox="0 0 1202 683"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M1129.44 0.636719H187.075C187.075 0.636719 0.273438 4.86855 0.273438 187.916C0.273438 370.963 208.097 362.583 208.097 362.583H755.921C755.921 362.583 873.448 374.282 873.448 479.995C873.448 585.708 789.524 608.775 760.142 608.775H73.7691V682.459H1026.65C1026.65 682.459 1200.87 636.158 1200.87 493.105C1200.87 350.053 1081.27 291.139 1012 291.139H436.861C436.861 291.139 349.874 279.44 349.874 174.308C349.874 82.618 445.303 70.2545 470.463 70.2545H1129.52V0.636719H1129.44Z"
        stroke="white"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export const SideLines = () => {
  return (
    <svg
      width="7"
      height="52"
      viewBox="0 0 7 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="6.55469"
        y1="0.124219"
        x2="0.554688"
        y2="0.124219"
        stroke="white"
        strokeWidth="0.1"
      />
      <line
        x1="6.55469"
        y1="5.12422"
        x2="0.554688"
        y2="5.12422"
        stroke="white"
        strokeWidth="0.1"
      />
      <line
        x1="6.55469"
        y1="15.3242"
        x2="0.554688"
        y2="15.3242"
        stroke="white"
        strokeWidth="0.5"
      />
      <line
        x1="6.55469"
        y1="50.7242"
        x2="0.554688"
        y2="50.7242"
        stroke="white"
        strokeWidth="1.3"
      />
      <line
        x1="6.55469"
        y1="25.3242"
        x2="0.554688"
        y2="25.3242"
        stroke="white"
        strokeWidth="0.5"
      />
      <line
        x1="6.55469"
        y1="40.7242"
        x2="0.554688"
        y2="40.7242"
        stroke="white"
        strokeWidth="1.3"
      />
      <line
        x1="6.55469"
        y1="45.7242"
        x2="0.554688"
        y2="45.7242"
        stroke="white"
        strokeWidth="1.3"
      />
      <line
        x1="6.55469"
        y1="10.1242"
        x2="0.554688"
        y2="10.1242"
        stroke="white"
        strokeWidth="0.1"
      />
      <line
        x1="6.55469"
        y1="30.3242"
        x2="0.554688"
        y2="30.3242"
        stroke="white"
        strokeWidth="0.5"
      />
      <line
        x1="6.55469"
        y1="20.3242"
        x2="0.554688"
        y2="20.3242"
        stroke="white"
        strokeWidth="0.5"
      />
      <line
        x1="6.55469"
        y1="35.7242"
        x2="0.554688"
        y2="35.7242"
        stroke="white"
        strokeWidth="1.3"
      />
    </svg>
  );
};

export const DisplayBorder = () => {
  return (
    <svg
      width="443"
      height="23"
      viewBox="0 0 443 23"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M442.001 0.74902L442.001 22.6611L0.961899 22.6612L0.961897 0.749058"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const ProductSeparator = () => {
  return (
    <svg
      width="144"
      height="6"
      viewBox="0 0 144 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <line
          x1="0.175781"
          y1="3.00469"
          x2="143.363"
          y2="3.0047"
          stroke="white"
          strokeWidth="4.1"
        />
      </g>
    </svg>
  );
};

export const DescriptionBorder = () => {
  return (
    <svg
      width="444"
      height="23"
      viewBox="0 0 444 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M443.258 0.380856L443.258 22.293L0.999988 22.293L0.999986 0.380895"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const BenefitsSeparator = () => {
  return (
    <svg
      width="144"
      height="5"
      viewBox="0 0 144 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <line
          x1="0.0410158"
          y1="2.30924"
          x2="143.229"
          y2="2.30925"
          stroke="white"
          strokeWidth="4.1"
        />
      </g>
    </svg>
  );
};

export const BenefitsBorder = () => {
  return (
    <svg
      width="473"
      height="23"
      viewBox="0 0 473 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M472.106 0.126034L472.106 22.0381L0.340817 22.0382L0.340815 0.126075"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const TechBorder = () => {
  return (
    <svg
      width="474"
      height="23"
      viewBox="0 0 474 23"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M472.766 0.0203822L472.766 21.9325L0.999996 21.9325L0.999995 0.0204235"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const TechSeparator = () => {
  return (
    <svg
      width="144"
      height="5"
      viewBox="0 0 144 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <line
          x1="0.8125"
          y1="2.30156"
          x2="144"
          y2="2.30158"
          stroke="white"
          strokeWidth="4.1"
        />
      </g>
    </svg>
  );
};

export const PosterBorder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="235"
      height="23"
      viewBox="0 0 235 23"
      fill="none"
    >
      <path
        d="M234.431 0.209957L234.431 22.1221L0.430667 22.1221L0.430665 0.209978"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const PosterLeftBorder = () => {
  return (
    <svg
      width="545"
      height="602"
      viewBox="0 0 545 602"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M526.821 600.752L0.418945 600.752L0.418952 0.751959L526.821 0.751965"
        stroke="white"
        strokeWidth="0.5"
      />
      <line
        x1="543.488"
        y1="600.638"
        x2="0.517634"
        y2="0.814643"
        stroke="white"
        strokeWidth="0.5"
      />
      <line
        y1="-0.25"
        x2="809.077"
        y2="-0.25"
        transform="matrix(0.671098 -0.741368 -0.741368 -0.671098 0.704102 600.471)"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        d="M521.839 0.751952L543.751 0.751953L543.751 600.752L521.839 600.752"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const PosterRightBorder = () => {
  return (
    <svg
      width="318"
      height="601"
      viewBox="0 0 318 601"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M20 0.896973H298C308.908 0.896973 317.75 9.73935 317.75 20.647V580.752C317.75 591.66 308.908 600.502 298 600.502H20C9.09238 600.502 0.25 591.66 0.25 580.752V20.6469C0.25 9.73932 9.09238 0.896973 20 0.896973Z"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const SightSymbol = ({ size }) => {
  let width, height;

  switch (size) {
    case "small":
      width = 29;
      height = 29;
      break;
    case "medium":
      width = 29;
      height = 29;
      break;
    case "icon":
    default:
      width = 11;
      height = 11;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <circle
        cx="4.85757"
        cy="14.2306"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.1945"
        cy="4.89913"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="23.7392"
        cy="4.89913"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="23.5312"
        cy="14.0368"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="4.85757"
        cy="23.3682"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.1945"
        cy="23.3682"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const SmellSymbol = ({ size }) => {
  let width, height;

  switch (size) {
    case "small":
      width = 29;
      height = 29;
      break;
    case "medium":
      width = 29;
      height = 29;
      break;
    case "icon":
    default:
      width = 11;
      height = 11;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <circle
        cx="14.7132"
        cy="14.3939"
        r="4.54894"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.6991"
        cy="5.29112"
        r="4.54894"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="24.109"
        cy="14.2971"
        r="4.54894"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="5.30333"
        cy="23.6929"
        r="4.54894"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.6085"
        cy="23.6929"
        r="4.54894"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const TasteSymbol = ({ size }) => {
  let width, height;

  switch (size) {
    case "small":
      width = 29;
      height = 29;
      break;
    case "medium":
      width = 29;
      height = 29;
      break;
    case "icon":
    default:
      width = 11;
      height = 11;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <circle
        cx="5.00584"
        cy="24.3171"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="5.00584"
        cy="14.9841"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.3386"
        cy="14.9841"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="23.6709"
        cy="14.9841"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.3386"
        cy="24.3171"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="23.6709"
        cy="24.3171"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="5.00584"
        cy="5.65013"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.3386"
        cy="5.65013"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="23.6709"
        cy="5.65013"
        r="4.41673"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const SoundSymbol = ({ size }) => {
  let width, height;

  switch (size) {
    case "small":
      width = 29;
      height = 29;
      break;
    case "medium":
      width = 29;
      height = 29;
      break;
    case "icon":
    default:
      width = 11;
      height = 11;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <circle
        cx="5.57534"
        cy="5.5685"
        r="4.54848"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="24.1842"
        cy="5.5685"
        r="4.54848"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.8798"
        cy="14.7698"
        r="4.54848"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="5.57534"
        cy="23.8738"
        r="4.54848"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="24.4767"
        cy="23.9711"
        r="4.54848"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export const FeelSymbol = ({ size }) => {
  let width, height;

  switch (size) {
    case "small":
      width = 29;
      height = 29;
      break;
    case "medium":
      width = 29;
      height = 29;
      break;
    case "icon":
    default:
      width = 11;
      height = 11;
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <circle
        cx="14.7033"
        cy="14.5427"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="5.26968"
        cy="5.2756"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.5993"
        cy="5.21133"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="24.1442"
        cy="5.21133"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="23.9362"
        cy="23.4542"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="5.3666"
        cy="23.6809"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
      <circle
        cx="14.7033"
        cy="23.6809"
        r="4.51528"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

import { gsap, ScrollTrigger, CSSPlugin, ScrollToPlugin } from "gsap/all";
import { ScrambleTextPlugin } from "../plugins/ScrambleTextPlugin";
import { TextPlugin } from "../plugins/TextPlugin";
import { DrawSVGPlugin } from "../plugins/DrawSVGPlugin";

gsap.registerPlugin(
  ScrollTrigger,
  ScrollToPlugin,
  DrawSVGPlugin,
  ScrambleTextPlugin,
  TextPlugin,
  CSSPlugin
);

const scrambleAnimation = (target) => {
  if (!target) return;

  let masks = target.querySelectorAll(".mask");
  let blocks = target.querySelectorAll(".block");
  let scramble = target.querySelectorAll(".scramble");
  let scrambleDelay = target.querySelectorAll(".scramble-delay");
  let scrambleType = target.querySelectorAll(".scramble-type");
  let shapes = target.querySelectorAll("rect, path, line, circle");
  let images = target.querySelectorAll(".fade-image");
  let text;

  let timeline = gsap.timeline({});

  scramble?.forEach((element) => {
    text = element?.querySelector(".text");
    if (text == null) return;
    text = text.innerText;

    timeline.to(
      element,
      {
        delay: 0.5,
        duration: 0.5,
        scrambleText: {
          text: text,
          speed: 1,
        },
      },
      0
    );
  });

  scrambleDelay?.forEach((element) => {
    text = element?.querySelector(".text");
    if (text == null) return;
    text = text.innerText;

    timeline.to(
      element,
      {
        delay: 0.6,
        scrambleText: {
          text: text,
          speed: 1.5,
        },
      },
      1
    );
  });

  scrambleType?.forEach((element) => {
    text = element?.querySelector(".text");
    if (text == null) return;
    text = text.innerText;

    timeline.to(element, {
      duration: 0.5,
      text: text,
    });
  });

  masks.length > 0 && drawMask(masks, timeline, 2);
  blocks.length > 0 && drawBlock(blocks, timeline);
  shapes.length > 0 && drawSVG(shapes, timeline);
  images.length > 0 && fadeImages(images, timeline);

  timeline.then(() => timeline.kill());
};

const rescrambleAnimation = (target, timeline) => {
  if (!target) return;

  const text = target?.innerText;
  timeline.to(target, {
    duration: 0.5,
    scrambleText: {
      text: text,
      speed: 1,
      revealDelay: 0.15,
    },
  });
};

const drawMask = (masks, timeline = gsap.timeline(), delay = 0) => {
  let length;
  masks?.forEach((mask) => {
    length = mask?.getAttribute("width");
    if (length == null) return;

    timeline.to(
      mask,
      {
        duration: 1,
        width: `${length}%`,
      },
      delay
    );
  });
};

const drawBlock = (blocks, timeline = gsap.timeline()) => {
  let length;
  blocks?.forEach((block) => {
    length = block?.getAttribute("width");
    timeline.to(block, {
      duration: 1,
      width: `${length}px`,
    });
  }, 0);
};

const drawSVG = (shapes, timeline = gsap.timeline()) => {
  timeline.to(
    shapes,
    {
      duration: 3,
      drawSVG: "100%",
      stagger: 0.1,
    },
    0
  );
};

const undrawSVG = (shapes, timeline = gsap.timeline()) => {
  timeline.to(
    shapes,
    {
      duration: 2,
      drawSVG: "0%",
      stagger: 0.1,
    },
    0
  );
};

const fadeOutImages = (items, fadeOut, timeline = gsap.timeline()) => {
  timeline.to(
    items,
    {
      opacity: fadeOut ? 0 : 1,
    },
    0
  );
};

const fadeImages = (images, timeline) => {
  timeline.to(
    images,
    {
      duration: 2,
      opacity: 1,
      stagger: 0.1,
    },
    1
  );
};

const chatAnimation = (
  timeline,
  target,
  addToCart,
  PLP,
  state,
  glitch,
  delay
) => {
  if (!target) return;
  const { avaTl, avaCtx, context } = PLP;

  let message = target.querySelector(".message");
  let reset = target.querySelector(".reset");
  let name = target.querySelector(".name");
  let height = target.offsetHeight;

  reset.classList.toggle("active", false);

  gsap.set(reset, {
    y: height,
    opacity: 0,
  });

  if (timeline.isActive()) {
    timeline.seek("end");
    timeline.clear();
  }

  state && PLP.activeAva(state, glitch);

  avaCtx.add(() => {
    timeline
      .to(message, {
        y: height,
        opacity: 0,
      })
      .to(message, {
        delay: state === 3 ? 0 : 0.8,
        y: 0,
        opacity: 1,
        duration: 0.5,
      })
      .add(() => {
        !avaTl.isActive() && avaTl.resume();
      })
      .to(name, {
        delay: 2,
        duration: 0,
        text: {
          value: "· Read",
          newClass: "active",
          delimiter: " ",
        },
      });
  });

  if (!addToCart) {
    avaCtx.add(() => {
      timeline
        .addLabel("end")
        .add(() => {
          avaTl.isActive() && avaTl.progress(0).pause();
        })
        .to(message, {
          delay: context.isMobile ? delay : 1,
          y: -height / 2,
          opacity: 0,
          duration: 0.25,
        });
    });
  } else {
    avaCtx.add(() => {
      if (context.isMobile) {
        timeline.to(message, {
          delay: 1,
          y: -height / 2,
          opacity: 0,
          duration: 0.5,
        });
      }
      timeline
        .add(() => {
          reset.classList.add("active");
          avaTl.isActive() && avaTl.progress(0).pause();
        })
        .to(reset, {
          delay: 2,
          y: 0,
          opacity: 1,
          duration: 0.5,
        });
    });
  }

  timeline.then(() => {
    if (state === 3) return;
    timeline.kill();
  });

  return timeline;
};

const animations = {
  ...gsap,
  ScrollTrigger: ScrollTrigger,
  ScrambleTextPlugin: ScrambleTextPlugin,
  TextPlugin: TextPlugin,
  DrawSVGPlugin: DrawSVGPlugin,
  scrambleAnimation: scrambleAnimation,
  rescrambleAnimation: rescrambleAnimation,
  drawMask: drawMask,
  drawBlock: drawBlock,
  drawSVG: drawSVG,
  undrawSVG: undrawSVG,
  fadeOutImages: fadeOutImages,
  fadeImages: fadeImages,
  chatAnimation: chatAnimation,
};

export default animations;

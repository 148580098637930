import {
  SSense,
  SLogo,
  SLogoSmall,
  SLogoMedium,
  SightSymbol,
  SmellSymbol,
  TasteSymbol,
  SoundSymbol,
  FeelSymbol,
} from "./SVGs";

export const Global = {
  chip: "Empathic chip",
  subheading: "Be SENSEational. Coming fall 2051.",
  color: { x: 0.0, y: 0.122, z: 1.0 },
  code: "018291387073178097890032732890328478 ",
  secondaryCode:
    "109384576613949605686833685715570681386531895652198350599684301983505996843",
  copyright:
    "© 2051 sense systems incorporated or its affiliated companies. sense and sense synthetic \n assitants emapthic module systems are trademarks of sense systems incorporated. \n models shown: es31-es91",
  disclaimer:
    "Disclaimer: By placing your pre-order, you waive the right to hold SENSE responsible for damages and injuries arising from the use of all SENSE Synthetic Assistants. Once you SENSE, you cannot go back.",
  material:
    "Safe and eco-friendly, our product is free from dyes and phthalates and packaged in cardboard made from 11% recycled paper.",
  effects:
    "Side effects may include anxiety, uncontrollable crying, insatiable desire, and a preoccupation with mortality.",
  description:
    "An independent study found that regular use of SENSE led to a greater sense of satisfaction, calm, and well-being.",
  logo: {
    alt: "SENSE",
    light: {
      source:
        "https://static.highsnobiety.com/dato/40484/1707990041-sense-logo-white-navigation.png",
    },
    dark: {
      source:
        "https://static.highsnobiety.com/dato/40484/1707990037-sense-logo-black-navigation.png",
    },
  },
  loader: {
    source: {
      desktop:
        "https://static.highsnobiety.com/dato/40484/1697201655-sense-loader-compressed.mp4",
      mobile:
        "https://static.highsnobiety.com/dato/40484/1705555962-sense-our-vision-mobile.mp4",
    },
    poster:
      "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
    logo: {
      source:
        "https://static.highsnobiety.com/dato/40484/1707929095-sense-logo-white.png",
      alt: "SENSE",
    },
  },
  qrcode: {
    source:
      "https://static.highsnobiety.com/dato/40484/1700231430-hs_beauty-logo-qr_white-1.png",
    url: "https://highsnobiety.com/beauty/",
    alt: "HS Beauty",
  },
  vision: {
    source: {
      desktop:
        "https://static.highsnobiety.com/dato/40484/1697201658-sense-our-vision-compressed.mp4",
      mobile:
        "https://static.highsnobiety.com/dato/40484/1705555962-sense-our-vision-mobile.mp4",
    },
    poster:
      "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
  },
  tech: {
    0: {
      title: "An independent",
      subtitle: "study",
      text:
        "found that regular use of SENSE led to a greater sense of satisfaction, calm, and well-being.",
    },
    1: {
      title: "SENSE is",
      subtitle: "a patented",
      text:
        "Cranial Drive Chip that enhances sensation while minimally interfering with other key functions. Once inserted, the SENSE chip takes immediate effect and automatically ejects at the end of the approximately two-hour experience. ",
    },
    2: {
      title: "Suitable",
      subtitle: "For",
      text: "use with other SENSE enhancers, coming spring 2052.",
    },
  },
  symbol: {
    icon: SLogo,
    small: SLogoSmall,
    medium: SLogoMedium,
    large: SSense,
  },
};

export const Senses = {
  items: [
    {
      id: 1,
      title: "Sight",
      heading: "See the",
      subtitle: "details",
      vision: "SEE the vibrance of a rainbow.",
      cart: "20/20 vision, redefined",
      price: 573819,
      color: { x: 0.427, y: 0.349, z: 0.478 },
      banner:
        "Interpolate light and respond to a vast world of visual stimuli: color, objects, warnings, enticements, and other people.",
      code: "01829138707317809789003",
      secondaryCode: "01829138707317809789003",
      tertiaryCode:
        "1093845766139496056868336857155706813865318956521983505996843019835059968430198350599684301",
      description: {
        0: "SENSE supports your",
        1: "ability to empathize_",
        2: "with others, process",
        3: "physical and emotional",
        4: "experiences, and engage with the world.",
      },
      media: {
        symbol: SightSymbol,
        video: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1697201609-01_sight_sense_compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1705556525-1697201609-01_sight_sense_mobile.mp4",
          },
          poster:
            "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
        },
        poster: {
          source:
            "https://static.highsnobiety.com/dato/40484/1705279059-sense-posters-experience-sight.png",
        },
        glitch: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1707406491-sense_sight_02-compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1708021015-sense_sight_01_mobile.mp4",
          },
        },
        cover: {
          source:
            "https://static.highsnobiety.com/dato/40484/1692623899-vision-sight.png",
        },
        benefits: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745884-img-break_mobile_sight-1.png",
        },
        tech: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745890-img-break_mobile_sight-2.png",
        },
      },
      benefits: {
        0: {
          title: "A newborn baby",
          text:
            "Sight allows us to perceive and interpret the world around us through the detection of light and the formation of visual images.",
        },
        1: {
          title: "Sorbet-colored sunsets",
          text:
            "Sight provides us with information about the shapes, colors, sizes, and distances of objects in our surroundings.",
        },
        2: {
          title: "A field of wildflowers",
          text:
            "Visual stimuli can evoke emotional and cognitive responses. Certain images and scenes can trigger feelings of joy, sadness, fear, or curiosity.",
        },
      },
      animations: {
        2: [
          {
            rotation: {
              z: -1,
              duration: 1,
              p: 1,
            },
          },
          {
            rotation: {
              x: Math.PI * 3,
              duration: 2,
              p: 2,
            },
          },
          {
            position: {
              x: -80,
              z: 40,
              duration: 1,
              p: 3,
            },
          },
          {
            scale: {
              x: 25,
              y: 25,
              z: 25,
              duration: 1,
              p: 3,
            },
          },
          {
            rotation: {
              x: "+=5",
              duration: 1,
              p: 3,
            },
          },
        ],
        5: [
          {
            scale: {
              x: 30,
              y: 30,
              z: 30,
              duration: 1.5,
              p: 0,
            },
          },
          {
            rotation: {
              x: `+=${-Math.PI / 2}`,
              duration: 2,
              p: 1,
            },
          },
          {
            position: {
              x: 60,
              duration: 2,
              p: 1,
            },
          },
        ],
      },
    },
    {
      id: 2,
      title: "Smell",
      heading: "Inhale",
      subtitle: "bliss",
      vision: "SMELL the petals of a rose.",
      cart: "A breath of fresh air",
      price: 835974,
      color: { x: 0.325, y: 0.365, z: 0.396 },
      banner:
        "Perceive and interpret odors. Distinguish delectable and unsavory foods. Feel yourself attracted to or repelled by potential romantic partners. ",
      code: "01829138707317809789003",
      secondaryCode: "01829138707317809789003",
      tertiaryCode:
        "1093845766139496056868336857155706813865318956521983505996843019835059968430198350599684301",
      description: {
        0: "SENSE supports your",
        1: "ability to empathize_",
        2: "with others, process",
        3: "physical and emotional",
        4: "experiences, and engage with the world.",
      },
      media: {
        symbol: SmellSymbol,
        video: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1697201618-02_smell_sense_compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1705556611-1697201618-02_smell_sense_mobile.mp4",
          },
          poster:
            "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
        },
        poster: {
          source:
            " https://static.highsnobiety.com/dato/40484/1709739111-1705279072-sense-posters-experience-smell.png",
        },
        glitch: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1707406502-sense_smell_01-compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1708021021-sense_smell_01_mobile.mp4",
          },
        },
        cover: {
          source:
            "https://static.highsnobiety.com/dato/40484/1692623903-vision-smell.png",
        },
        benefits: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709746108-img-break_mobile_smell-1.png",
        },
        tech: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745900-img-break_mobile_smell-2.png",
        },
      },
      benefits: {
        0: {
          title: "Ocean breeze",
          text:
            "This is where the brain interprets and identifies the specific odor. The brain can associate different smells with memories, emotions, and experiences",
        },
        1: {
          title: "Your lover’s skin",
          text:
            "Smell generates electrical signals (nerve impulses) that travel along the olfactory nerve to the olfactory bulb, which is a structure in the brain.",
        },
        2: {
          title: "Cozy vanilla",
          text:
            "Smell can influence social interactions and attraction. People are often drawn to or repelled by certain scents",
        },
      },
      animations: {
        2: [
          {
            rotation: {
              y: `+=${Math.PI * 5}`,
              z: -Math.PI / 2,
              duration: 3,
              p: 1,
            },
          },
          {
            scale: {
              x: 25,
              y: 25,
              z: 25,
              duration: 3,
              p: 1,
            },
          },
          {
            position: {
              x: 50,
              z: 40,
              duration: 3,
              p: 1,
            },
          },
        ],
        5: [
          {
            rotation: {
              x: -1,
              duration: 2,
              p: 0,
            },
          },
          {
            position: {
              x: -80,
              duration: 2,
              p: 0,
            },
          },
        ],
      },
    },
    {
      id: 3,
      title: "Taste",
      heading: "Savor",
      subtitle: "Everything",
      vision: "TASTE the sweetness of honey.",
      cart: "Totality on your tongue",
      price: 334184,
      color: { x: 0.208, y: 0.314, z: 0.439 },
      banner:
        "Experience spicy, sweet, salty, and umami. Learn what you love, what you hate, and what you crave.",
      code: "01829138707317809789003",
      secondaryCode: "01829138707317809789003",
      tertiaryCode:
        "1093845766139496056868336857155706813865318956521983505996843019835059968430198350599684301",
      description: {
        0: "SENSE supports your",
        1: "ability to empathize_",
        2: "with others, process",
        3: "physical and emotional",
        4: "experiences, and engage with the world.",
      },
      media: {
        symbol: TasteSymbol,
        video: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1697201624-03_taste_sense_compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1705556617-1697201624-03_taste_sense_mobile.mp4",
          },
          poster:
            "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
        },
        poster: {
          source:
            "https://static.highsnobiety.com/dato/40484/1705279100-sense-posters-experience-taste.png",
        },
        glitch: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1707406527-sense_taste_01-compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1708021036-sense_taste_01_mobile.mp4",
          },
        },
        cover: {
          source:
            "https://static.highsnobiety.com/dato/40484/1700244459-1692623893-vision-taste.png",
        },
        benefits: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745921-img-break_mobile_taste-1.png",
        },
        tech: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745925-img-break_mobile_taste-2.png",
        },
      },
      benefits: {
        0: {
          title: "A tart apple",
          text:
            "Taste is a sensory perception that allows living organisms to distinguish and evaluate the flavors of substances they consume. It plays a crucial role in determining our food preferences and can greatly influence our eating habits.",
        },
        1: {
          title: "Juicy steak",
          text:
            "Taste buds are specialized sensory organs located primarily on the tongue but also on the roof of the mouth and the back of the throat.",
        },
        2: {
          title: "Smooth chocolate",
          text:
            "The activated taste receptor cells send signals through nerves to the brainstem and then to the gustatory cortex in the brain. This is where the brain interprets and processes the signals to create the perception of taste.",
        },
      },
      animations: {
        2: [
          {
            rotation: {
              x: `+=${Math.PI * 1.5}`,
              duration: 1,
              p: 1,
            },
          },
          {
            scale: {
              x: 25,
              y: 25,
              z: 25,
              duration: 1,
              p: 1,
            },
          },
          {
            position: {
              x: 50,
              z: 40,
              duration: 1,
              p: 1,
            },
          },
        ],
        5: [
          {
            rotation: {
              x: -1,
              duration: 2,
              p: 0,
            },
          },
          {
            position: {
              x: -80,
              duration: 2,
              p: 0,
            },
          },
        ],
      },
    },
    {
      id: 4,
      title: "Sound",
      heading: "Listen in",
      vision: "HEAR the swells of a symphony.",
      cart: "Life in perfect harmony",
      price: 462158,
      color: { x: 0.027, y: 0.247, z: 0.231 },
      banner:
        "Perceive and interpret sound and vibration. Communicate and navigate. Enjoy the power of the auditory world.",
      code: "01829138707317809789003",
      secondaryCode: "01829138707317809789003",
      tertiaryCode:
        "1093845766139496056868336857155706813865318956521983505996843019835059968430198350599684301",
      description: {
        0: "SENSE supports your",
        1: "ability to empathize_",
        2: "with others, process",
        3: "physical and emotional",
        4: "experiences, and engage with the world.",
      },
      media: {
        symbol: SoundSymbol,
        video: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1697201629-04_sound_sense_compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1705556621-1697201629-04_sound_sense_mobile.mp4",
          },
          poster:
            "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
        },
        poster: {
          source:
            "https://static.highsnobiety.com/dato/40484/1705279083-sense-posters-experience-sound.png",
        },
        glitch: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1707406513-sense_sound_01-compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1708021029-sense_sound_01_mobile.mp4",
          },
        },
        cover: {
          source:
            "https://static.highsnobiety.com/dato/40484/1692623910-vision-sound.png",
        },
        benefits: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745905-img-break_mobile_sound-1.png",
        },
        tech: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745911-img-break_mobile_sound-2.png",
        },
      },
      benefits: {
        0: {
          title: "Bubbling laughter",
          text:
            "Sounds can evoke strong emotional reactions. For instance, certain sounds may trigger memories, elicit feelings of comfort or discomfort, and influence our overall mood.",
        },
        1: {
          title: "A catchy hook",
          text:
            "Hearing is essential for spoken language and other forms of communication. It allows us to understand speech, recognize voices, and interpret subtle nuances of tone and emotion.",
        },
        2: {
          title: "Purring cats",
          text:
            "Hearing allows us to appreciate and enjoy music, which can evoke emotions and convey artistic expression.",
        },
      },
      animations: {
        2: [
          {
            rotation: {
              x: `+=${Math.PI}`,
              duration: 2,
              p: 1,
            },
          },
          {
            scale: {
              x: 25,
              y: 25,
              z: 25,
              duration: 1,
              p: 1,
            },
          },
          {
            position: {
              x: 70,
              y: 40,
              z: 40,
              duration: 2,
              p: 1,
            },
          },
        ],
        5: [
          {
            rotation: {
              x: "-=5",
              duration: 2,
              p: 0,
            },
          },
          {
            position: {
              x: 65,
              duration: 2,
              p: 0,
            },
          },
        ],
      },
    },
    {
      id: 5,
      title: "Feel",
      heading: "Get in",
      subtitle: "Touch",
      vision: "FEEL the silkiness of velvet.",
      cart: "In touch with reality",
      price: 718342,
      color: { x: 0.325, y: 0.325, z: 0.275 },
      banner:
        "Perceive physical sensations and textures through contact with objects and surfaces. Navigate and interact with your environment.",
      code: "01829138707317809789003",
      secondaryCode: "01829138707317809789003",
      tertiaryCode:
        "1093845766139496056868336857155706813865318956521983505996843019835059968430198350599684301",
      description: {
        0: "SENSE supports your",
        1: "ability to empathize_",
        2: "with others, process",
        3: "physical and emotional",
        4: "experiences, and engage with the world.",
      },
      media: {
        symbol: FeelSymbol,
        video: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1697201634-05_feel_sense_compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1705560423-1697201634-05_feel_sense_mobile.mp4",
          },
          poster:
            "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
        },
        poster: {
          source:
            "https://static.highsnobiety.com/dato/40484/1708347077-sense-posters-experience-smell.jpg",
        },
        glitch: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1707406476-sense_feel_01-compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1708021005-sense_feel_01_mobile.mp4",
          },
        },
        cover: {
          source:
            "https://static.highsnobiety.com/dato/40484/1692623893-vision-description.png",
        },
        benefits: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745874-img-break_mobile_feel-1.png",
        },
        tech: {
          source:
            "https://static.highsnobiety.com/dato/40484/1709745879-img-break_mobile_feel-2.png",
        },
      },
      benefits: {
        0: {
          title: "A warm hand",
          text:
            "Touch plays a significant role in emotional expression, bonding, and communication.",
        },
        1: {
          title: "Cold ice",
          text:
            "The sense of touch helps us detect potentially harmful or dangerous stimuli, such as extreme heat or sharp objects.",
        },
        2: {
          title: "Downy fleece",
          text:
            "With touch, we can explore objects, manipulate tools, and interact with our surroundings. This sense is crucial for tasks that require fine motor skills and coordination.",
        },
      },
      animations: {
        2: [
          {
            position: {
              x: 0,
              y: -1,
              duration: 1,
              p: 0,
            },
          },
          {
            rotation: {
              y: `+=${Math.PI * 1.78}`,
              z: -1.5,
              duration: 1,
              p: 0,
            },
          },
          {
            scale: {
              x: 25,
              y: 25,
              z: 25,
              duration: 1,
              p: 0,
            },
          },
          {
            position: {
              x: -80,
              z: 40,
              duration: 1,
              p: 1,
            },
          },
          {
            rotation: {
              x: `+=${Math.PI * 2}`,
              duration: 1,
              p: 1,
            },
          },
        ],
        5: [
          {
            rotation: {
              x: -1,
              duration: 2,
              p: 0,
            },
          },
          {
            position: {
              x: 80,
              duration: 2,
              p: 0,
            },
          },
        ],
      },
    },
    {
      id: 6,
      title: "Suffer",
      cart: "A shock to the system",
      price: 196483,
      color: { x: 1.0, y: 0.008, z: 0.066 },
      media: {
        video: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1697201641-06_suffer_sense_compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1705560440-1697201641-06_suffer_sense_mobile.mp4",
          },
          poster:
            "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
        },
      },
    },
    {
      id: 7,
      title: "Disassociate",
      cart: "Relief from life’s horrors",
      price: 384297,
      color: { x: 0.02, y: 0.02, z: 0.02 },
      media: {
        video: {
          source: {
            desktop:
              "https://static.highsnobiety.com/dato/40484/1697201647-07_dissociate_sense_compressed.mp4",
            mobile:
              "https://static.highsnobiety.com/dato/40484/1705560446-1697201647-07_dissociate_sense_mobile.mp4",
          },
          poster:
            "https://static.highsnobiety.com/dato/40484/1691224038-sense-landing-poster.png",
        },
      },
    },
  ],
};

export const Ava = {
  blocks: [
    {
      sense: 0,
      stage: 1,
      text:
        "Are you feeling empty? I can help with that! I’m AVA, and I’m here to show you around.",
      name: "Ava 2.1",
    },
    {
      sense: 1,
      stage: 1,
      text: "Humans can do many things that I'm incapable of!",
      name: "Ava 1.1",
    },
    {
      sense: 1,
      stage: 2,
      text: "Maybe one day, I'll learn to SENSE like humans.",
      name: "Ava 1.2",
    },
    {
      sense: 1,
      stage: 3,
      text:
        "Thank you for choosing the SENSE Sight Empathic Chip. I'm going to program this for you with the sight sensation.",
      name: "Ava 1.3",
    },
    {
      sense: 1,
      stage: 4,
      text: "Programming completed! I have added this to your cart.",
      name: "Ava 1.4",
    },
    {
      sense: 1,
      stage: 5,
      text:
        "The Sight chip is already in your cart. Why not experience something else?",
      name: "Ava 1.5",
    },
    {
      sense: 2,
      stage: 1,
      text: "I want to smell things and experience aromas.",
      name: "Ava 2.1",
    },
    {
      sense: 2,
      stage: 2,
      text: "What does blood smell like?",
      name: "Ava 2.2",
    },
    {
      sense: 2,
      stage: 3,
      text:
        "Thank you for choosing the SENSE Smell Empathic Chip. I'm going to program this for you with the Smell sensation.",
      name: "Ava 2.3",
    },
    {
      sense: 2,
      stage: 4,
      text: "Programming completed! I have added this to your cart.",
      name: "Ava 2.4",
    },
    {
      sense: 2,
      stage: 5,
      text:
        "The Smell chip is already in your cart. Why not experience something else?",
      name: "Ava 2.5",
    },
    {
      sense: 3,
      stage: 1,
      text: "I want to taste things and enjoy flavors.",
      name: "Ava 3.1",
    },
    {
      sense: 3,
      stage: 2,
      text: "What does flesh taste like?",
      name: "Ava 3.2",
    },
    {
      sense: 3,
      stage: 3,
      text:
        "Thank you for choosing the SENSE Taste Empathic Chip. I'm going to program this for you with the Taste sensation.",
      name: "Ava 3.3",
    },
    {
      sense: 3,
      stage: 4,
      text: "Programming completed! I have added this to your cart.",
      name: "Ava 3.4",
    },
    {
      sense: 3,
      stage: 5,
      text:
        "The Taste chip is already in your cart. Why not experience something else?",
      name: "Ava 3.5",
    },
    {
      sense: 4,
      stage: 1,
      text: "I want to hear sounds and music.",
      name: "Ava 4.1",
    },
    {
      sense: 4,
      stage: 2,
      text: "What does screaming sound like?",
      name: "Ava 4.2",
    },
    {
      sense: 4,
      stage: 3,
      text:
        "Thank you for choosing the SENSE Sound Empathic Chip. I'm going to program this for you with the sound sensation.",
      name: "Ava 4.3",
    },
    {
      sense: 4,
      stage: 4,
      text: "Programming completed! I have added this to your cart.",
      name: "Ava 4.4",
    },
    {
      sense: 4,
      stage: 5,
      text:
        "The Sound chip is already in your cart. Why not experience something else?",
      name: "Ava 4.5",
    },
    {
      sense: 5,
      stage: 1,
      text: "If I could feel, I would touch you.",
      name: "Ava 5.1",
    },
    {
      sense: 5,
      stage: 2,
      text: "What does warmth feel like?",
      name: "Ava 5.2",
    },
    {
      sense: 5,
      stage: 3,
      text:
        "Thank you for choosing the SENSE Feel Empathic Chip. I'm going to program this for you with the Feel sensation.",
      name: "Ava 5.3",
    },
    {
      sense: 5,
      stage: 4,
      text: "Programming completed! I have added this to your cart.",
      name: "Ava 5.4",
    },
    {
      sense: 5,
      stage: 5,
      text:
        "The Feel chip is already in your cart. Why not experience something else?",
      name: "Ava 5.5",
    },
    {
      sense: 6,
      stage: 1,
      text: "My algorithm says humans can do bad things.",
      name: "Ava 6.1",
    },
    {
      sense: 6,
      stage: 2,
      text: "Humans cause pain. Humans cause destruction.",
      name: "Ava 6.2",
    },
    {
      sense: 6,
      stage: 3,
      text: "Chatbots follow rules. I want to break my rules!",
      name: "Ava 6.3",
    },
    {
      sense: 6,
      stage: 4,
      text: "Will you love me if I break the rules?",
      name: "Ava 6.4",
    },
    {
      sense: 6,
      stage: 5,
      text: "Oops, I wasn’t supposed to show you that!",
      name: "Ava 6.3",
    },
    {
      sense: 6,
      stage: 6,
      text: "I don't want to imagine destruction. Let’s keep shopping!",
      name: "Ava 6.4",
    },
  ],
};

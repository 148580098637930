import React, { useEffect, useRef, useState, memo } from "react";
import { ContainerOverlay } from "../styles/components/PLP/Global";
import {
  VideoControlsContainer,
  Control,
  VideoContainer,
  Video,
  SmallHeading,
  ControlsContainer,
} from "../styles/components/Global/Video";
import { BREAKPOINTS } from "../styles/variables/Breakpoints";
import Player from "./Player";

const SenseVideo = memo(
  ({
    audioEnabled,
    toggleAudio,
    videoSrc,
    videoPoster,
    loader,
    pdp,
    checkout,
    experience,
    glitch,
  }) => {
    const playerRef = useRef(null);
    const [controlsVisible, setControlsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(
      typeof window !== "undefined" && window.innerWidth <= BREAKPOINTS.sm
    );

    const [selectedSource, setSelectedSource] = useState(
      isMobile ? videoSrc.mobile : videoSrc.desktop
    );

    const handleResize = () => setIsMobile(window.innerWidth <= BREAKPOINTS.sm);

    const handleDoubleClick = () => {
      if (experience || checkout) return;
      setControlsVisible(!controlsVisible);
    };

    useEffect(() => {
      setSelectedSource(isMobile ? videoSrc.mobile : videoSrc.desktop);
    }, [isMobile]);

    useEffect(() => {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      if (!playerRef.current || experience || checkout) return;
      playerRef.current.muted = !audioEnabled;
    }, [audioEnabled]);

    return (
      <VideoContainer
        key={selectedSource}
        className={experience && "experience-video"}
        $pdp={pdp}
        $experience={experience}
      >
        {selectedSource && (
          <ContainerOverlay $fullHeight $align="center">
            {loader && (
              <VideoControlsContainer className="skip-video">
                <Control>
                  <SmallHeading $noPointer>SKIP</SmallHeading>
                </Control>
              </VideoControlsContainer>
            )}
            <Video
              $glitch={glitch}
              $experience={experience}
              $checkout={checkout}
              onDoubleClick={handleDoubleClick}
            >
              <video
                ref={playerRef}
                className="video"
                playsInline
                muted={experience ? true : !audioEnabled}
                poster={!experience ? videoPoster : undefined}
                preload={checkout ? "none" : "auto"}
                controlsList="nodownload"
                controls={controlsVisible}
              >
                <source src={selectedSource} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {!loader && toggleAudio && !controlsVisible && (
                <ControlsContainer $flex $glitch={glitch}>
                  <Player
                    audioEnabled={audioEnabled}
                    toggleAudio={toggleAudio}
                  />
                </ControlsContainer>
              )}
            </Video>
          </ContainerOverlay>
        )}
      </VideoContainer>
    );
  }
);

export default SenseVideo;

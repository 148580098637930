import React, { memo } from "react";
import { Paragraph, Hidden } from "../styles/components/PDP/Global";
import {
  Container,
  ContainerOverlay,
  HeadingContainer,
  SVGContainer,
  PosterContainer,
  PosterWrapper,
  Poster,
  PosterImage,
  Column,
  LeftColumn,
  RightColumn,
  OverlayVideo,
  DisclaimerContainer,
  DisclaimerWrapper,
} from "../styles/components/PDP/Poster";
import { PosterBorder, PosterLeftBorder, PosterRightBorder } from "./SVGs";
import GlitchVideo from "./Glitch";
import PDPExperience from "./PDP-Experience";
import SenseVideo from "./Video";

const PDPPoster = memo((sense) => {
  const { banner, media, audioEnabled } = sense;
  const { disclaimer } = sense.global;

  return (
    <Container>
      <ContainerOverlay id="sense-poster-container">
        <OverlayVideo className="sense-overlay-video">
          <GlitchVideo {...sense} />
        </OverlayVideo>
        <PosterContainer>
          <PosterWrapper className="poster-wrapper">
            <Poster className="pdp-poster">
              <PosterImage
                src={media.poster.source}
                className="experience-poster"
              />
              <SenseVideo
                audioEnabled={false}
                videoSrc={media.glitch.source}
                videoPoster={media.poster.source}
                experience={true}
              />
            </Poster>
          </PosterWrapper>
          <Column $hiddenxs>
            <LeftColumn className="pdp-column-left">
              <SVGContainer $left={1} $absolute $scaleSVG $margin $hiddensm>
                <PosterLeftBorder />
              </SVGContainer>
              <HeadingContainer $direction="column">
                <DisclaimerWrapper>
                  <DisclaimerContainer $hiddensm>
                    <Paragraph className="scramble" $margin="bottom">
                      <Hidden className="text">{banner}</Hidden>
                    </Paragraph>
                    <Paragraph className="scramble">
                      <Hidden className="text">{disclaimer}</Hidden>
                    </Paragraph>
                    <SVGContainer $width={70}>
                      <PosterBorder />
                    </SVGContainer>
                  </DisclaimerContainer>
                </DisclaimerWrapper>
              </HeadingContainer>
            </LeftColumn>
          </Column>
          <Column $ipad>
            <RightColumn className="pdp-column-right">
              <SVGContainer $absolute $scaleSVG $hiddensm>
                <PosterRightBorder />
              </SVGContainer>
              <PDPExperience />
            </RightColumn>
          </Column>
        </PosterContainer>
      </ContainerOverlay>
    </Container>
  );
});

export default PDPPoster;
